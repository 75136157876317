import { Nullable } from '@/shared/utils/typing.ts';

type Route<
  P extends Record<string, any> | undefined = undefined,
  S extends Record<string, any> | undefined = undefined,
  SR extends Record<string, Route<any>> | undefined = undefined,
> = S extends undefined
  ? {
      path: string;
      url: (params: P) => string;
      subRoutes?: SR;
    }
  : {
      path: string;
      url: (params: P & S) => string;
      subRoutes?: SR;
      searchParams: (searchParams: URLSearchParams) => Nullable<S>;
    };

const Start = {
  path: '/start',
  url: () => '/start',
} satisfies Route;

const ConnectWallet = {
  path: '/connect-wallet',
  url: () => '/connect-wallet',
} satisfies Route;

const Home = {
  path: '/',
  url: () => '/',
  subRoutes: {
    HomeTokens: {
      path: '',
      url: () => '',
    },
    HomeCollectibles: {
      path: 'collectibles',
      url: () => 'collectibles',
    },
  },
} satisfies Route<
  undefined,
  undefined,
  {
    HomeTokens: Route;
    HomeCollectibles: Route;
  }
>;

const Settings = {
  path: '/settings',
  url: () => '/settings',
} satisfies Route;

export interface SwapRouteParams {
  payingTokenMintAddress?: string;
  receivingTokenMintAddress?: string;
  payingAmount?: string;
}

const Buy = {
  path: '/buy',
  url: (params) => {
    const urlSearchParams = new URLSearchParams();
    for (const [key, value] of Object.entries(params ?? {})) {
      urlSearchParams.append(key, value);
    }
    return `/buy?${urlSearchParams.toString()}`;
  },
} satisfies Route<SwapRouteParams>;

const Sell = {
  path: '/sell',
  url: (params) => {
    const urlSearchParams = new URLSearchParams();
    for (const [key, value] of Object.entries(params ?? {})) {
      urlSearchParams.append(key, value);
    }
    return `/sell?${urlSearchParams.toString()}`;
  },
} satisfies Route<SwapRouteParams>;

const SwapConfirm = {
  path: '/swap-confirm',
  url: () => '/swap-confirm',
} satisfies Route;

export interface TransactionConfirmationRouteParams {
  referenceAccount: string;
}

const TokenSwapTransactionConfirmation = {
  path: '/token-swap-transaction-confirmation',
  url: () => '/token-swap-transaction-confirmation',
} satisfies Route;

const NftTransactionConfirmation = {
  path: '/nft-transaction-confirmation',
  url: () => '/nft-transaction-confirmation',
} satisfies Route;

const ConnectPhantomWallet = {
  path: '/connect-phantom-wallet',
  url: () => '/connect-phantom-wallet',
} satisfies Route;

const SignPhantomTx = {
  path: '/sign-phantom-tx',
  url: () => '/sign-phantom-tx',
} satisfies Route;

const TrendingCollectibles = {
  path: '/collectibles/trending',
  url: () => '/collectibles/trending',
} satisfies Route;

export interface SmartMessageParams {
  smartMessageId: string;
}

const SmartMessage = {
  path: '/smart-messages/:smartMessageId',
  url: ({ smartMessageId }) => `/smart-messages/${smartMessageId}`,
} satisfies Route<SmartMessageParams>;

export interface MintAddressResourceId {
  mintAddress: string;
}

export interface CollectionResourceId {
  collectionId: string;
}

const TokenDetails = {
  path: '/tokens/:mintAddress',
  url: ({ mintAddress }) => `/tokens/${mintAddress}`,
} satisfies Route<MintAddressResourceId>;

const CollectibleDetails = {
  path: '/collections/:collectionId/collectibles/:mintAddress',
  url: ({ collectionId, mintAddress }) =>
    `/collections/${collectionId}/collectibles/${mintAddress}`,
} satisfies Route<MintAddressResourceId & CollectionResourceId>;

//region Generate Routes
const Generate = {
  path: '/generate',
  url: () => '/generate',
  subRoutes: {
    TokenInfo: {
      path: 'tokens/:mintAddress',
      url: ({ mintAddress }) => `/tokens/${mintAddress}`,
    },
  },
} satisfies Route<
  undefined,
  undefined,
  { TokenInfo: Route<MintAddressResourceId> }
>;
//endregion

export const AppRoute = {
  Start,
  Home,
  Settings,
  ConnectWallet,
  Buy,
  Sell,
  SwapConfirm,
  TokenSwapTransactionConfirmation,
  NftTransactionConfirmation,
  // start phantom redirect callbacks
  ConnectPhantomWallet,
  SignPhantomTx,
  // end phantom redirect callbacks
  TrendingCollectibles,
  TokenDetails,
  CollectibleDetails,
  Generate,
  SmartMessage,
};
