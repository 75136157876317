import useTelegramWebAppInitData from '@/hooks/telegram-web-app/useTelegramWebAppInitData.ts';
import useWalletTokensRaw from '@/hooks/useWalletTokensRaw.ts';
import miniAppApi from '@/shared/api/dialect/mini-app-api.ts';
import { useMemo } from 'react';
import useSWR, { KeyedMutator } from 'swr';

export interface WalletTokenAlert {
  mintAddress: string;
  enabled: boolean;
}

interface UseWalletTokenAlertsValue {
  walletTokensAlerts?: Record<string, WalletTokenAlert>;
  isWalletTokensAlertsLoading: boolean;
  refreshWalletTokensAlerts: KeyedMutator<
    Record<string, WalletTokenAlert> | undefined
  >;
}

const useWalletTokenAlerts = (): UseWalletTokenAlertsValue => {
  const [, initData] = useTelegramWebAppInitData();

  const { walletTokens, isWalletTokensLoading } = useWalletTokensRaw();

  const mintAddresses = useMemo(
    () => walletTokens.map((token) => token.mintAddress),
    [walletTokens],
  );

  const { data, isLoading, mutate } = useSWR(
    ['WALLET_TOKENS_ALERTS', ...mintAddresses.sort()],
    async () => {
      if (mintAddresses.length === 0 || !initData) {
        return;
      }

      const walletAlerts =
        await miniAppApi.alerts.getWalletTokensAlerts(initData);

      const data: Record<string, WalletTokenAlert> = {};
      walletAlerts.items.forEach((it) => {
        data[it.mintAddress] = it;
      });

      return data;
    },
    { revalidateOnMount: true },
  );

  return {
    walletTokensAlerts: data,
    isWalletTokensAlertsLoading: isLoading || isWalletTokensLoading,
    refreshWalletTokensAlerts: mutate,
  };
};

export default useWalletTokenAlerts;
