import { dialectApiTrpcClient } from '@/shared/api/dialect/dialect-api-trpc-client.ts';
import { GetCoinsMarketDataResponse } from '@/shared/api/dialect/types.ts';

export interface ConnectPhantomWalletCallbackParams {
  readonly phantomEncryptionPublicKey: string;
  readonly nonce: string;
  readonly data: string;
}

export interface ConnectPhantomWalletCallbackResponse {
  readonly userId: string;
  readonly walletAddress: string;
}

export interface GetWalletTokensAlertsItem {
  mintAddress: string;
  enabled: boolean;
}

export interface GetWalletTokensAlertsResponse {
  items: GetWalletTokensAlertsItem[];
}

const createMiniAppApi = () => {
  const login = async (
    initData: string,
  ): Promise<{
    walletAddress?: string | null;
    walletConnectedAt?: Date | null;
  } | null> => {
    try {
      const result = await dialectApiTrpcClient.user.login.mutate({
        initData,
      });
      return {
        walletAddress: result.walletAddress,
        walletConnectedAt: result.walletConnectedAt
          ? new Date(result.walletConnectedAt)
          : null,
      };
    } catch (e) {
      console.error(e);
      return null;
    }
  };
  const prepareConnectWallet = async (initData: string) => {
    const { deeplink } =
      await dialectApiTrpcClient.phantom.prepareConnectWallet.mutate({
        initData,
      });
    return { deeplink };
  };

  const connectWallet = async (
    params: ConnectPhantomWalletCallbackParams,
  ): Promise<ConnectPhantomWalletCallbackResponse> => {
    return dialectApiTrpcClient.phantom.connectWallet.mutate({
      ...params,
    });
  };

  const disconnectWallet = async (initData: string) => {
    return dialectApiTrpcClient.phantom.disconnectWallet.mutate({
      initData,
    });
  };

  const getTokensMarketData = async (
    mintAddresses: string[],
  ): Promise<GetCoinsMarketDataResponse> => {
    const query = await dialectApiTrpcClient.market.getCoinsMarketData.mutate({
      mintAddresses,
    });
    return query;
  };

  const getWalletTokensAlerts = async (
    initData: string,
  ): Promise<GetWalletTokensAlertsResponse> => {
    return dialectApiTrpcClient.alerts.getWalletTokensAlerts.mutate({
      initData,
    });
  };

  const updateWalletTokenAlert = async (
    initData: string,
    tokenAddress: string,
    enabled: boolean,
  ): Promise<void> => {
    return dialectApiTrpcClient.alerts.updateTokenAlertSubscription.mutate({
      initData,
      tokenAddress,
      enabled,
    });
  };

  const getTrendingNfts = async (initData: string) => {
    return dialectApiTrpcClient.nft.getTopVolumeCollectionsNfts.mutate({
      initData,
    });
  };

  const getNftDetails = async (
    initData: string,
    collectionId: string,
    mintAddress: string,
  ) => {
    return dialectApiTrpcClient.nft.getNftInfo.mutate({
      initData,
      collId: collectionId,
      mintAddress,
    });
  };

  const getBuyNftTx = async ({
    initData,
    mintAddress,
    ownerAddress,
    buyerAddress,
    price,
  }: {
    initData: string;
    mintAddress: string;
    ownerAddress: string;
    buyerAddress: string;
    price: number;
  }) => {
    return dialectApiTrpcClient.nft.getBuyNftTx.mutate({
      initData,
      mintAddress,
      ownerAddress,
      buyerAddress,
      price,
    });
  };

  return {
    login,
    alerts: {
      getWalletTokensAlerts: getWalletTokensAlerts,
      updateWalletTokenAlert: updateWalletTokenAlert,
    },
    markets: {
      getTokensMarketData,
    },
    phantom: {
      prepareConnectWallet,
      connectWallet,
      disconnectWallet,
      prepareSignTx: dialectApiTrpcClient.phantom.prepareSignTx,
    },
    nfts: {
      getTrendingNfts,
      getNftDetails,
      getBuyNftTx,
    },
    smartMessage: dialectApiTrpcClient.smartMessage,
  };
};

const miniAppApi = createMiniAppApi();

export default miniAppApi;
