import useWallet from '@/hooks/useWallet.ts';
import ConnectWalletPage from '@/pages/ConnectWalletPage.tsx';
import { FC, ReactNode } from 'react';

const ProtectedRoute: FC<{ children: ReactNode }> = ({ children }) => {
  const { walletAddress, isWalletLoading } = useWallet();
  if (!walletAddress && !isWalletLoading) {
    return <ConnectWalletPage />;
  }
  return children;
};
export default ProtectedRoute;
