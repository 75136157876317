import useWallet from '@/hooks/useWallet.ts';
import {
  getSolWalletToken,
  WalletToken,
} from '@/shared/api/solana/solana-api.ts';
import useSWR from 'swr';

interface UseWalletTokensValue {
  token: WalletToken | null;
  isWalletTokensLoading: boolean;
}

const useWalletSol = (): UseWalletTokensValue => {
  const { walletAddress } = useWallet();

  const { data, isLoading } = useSWR(
    walletAddress ? ['WALLET_SOL_BALANCE', walletAddress] : null,
    async () => {
      if (!walletAddress) {
        return;
      }
      return getSolWalletToken(walletAddress);
    },
    {
      revalidateOnMount: true,
    },
  );

  return {
    token: data || null,
    isWalletTokensLoading: isLoading,
  };
};

export default useWalletSol;
