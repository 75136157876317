import { clsx } from 'clsx';
import React from 'react';

export const TokenOperationButton: React.FC<{
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  icon: React.ReactNode;
}> = ({ icon, children, onClick, disabled }) => {
  return (
    <div className="flex flex-col items-center">
      <button
        className="flex items-center justify-center rounded-full text-sm disabled:text-primary/60 font-bold bg-secondary disabled:bg-secondary/60 w-[48px] h-[48px]"
        onClick={onClick}
        disabled={disabled}
      >
        {icon}
      </button>
      <span
        className={clsx('font-semibold text-sm', {
          'text-primary/60': disabled,
        })}
      >
        {children}
      </span>
    </div>
  );
};
