import { AppRoute } from '@/appRoute.ts';
import { ViewportFlex } from '@/components/ViewportFlex.tsx';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TokenDetailsContext } from './model';
import { TokenDetailsHeader, TokenMarketInfo, TokenPositionChart } from './ui';

const TokenDetailsPage = () => {
  const { mintAddress } = useParams<{ mintAddress: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!mintAddress) {
      navigate(AppRoute.Home.subRoutes.HomeTokens.url(), { replace: true });
    }
  }, [navigate, mintAddress]);

  if (!mintAddress) {
    return null;
  }

  return (
    <TokenDetailsContext.Provider value={{ mintAddress }}>
      <div className="relative">
        <ViewportFlex>
          <main className="px-3 w-full">
            <div className="my-4">
              <TokenDetailsHeader />
            </div>
            <div>
              <TokenPositionChart />
            </div>
            <div className="mt-2">
              <TokenMarketInfo />
            </div>
          </main>
        </ViewportFlex>
      </div>
    </TokenDetailsContext.Provider>
  );
};

export default TokenDetailsPage;
