import useTokenPrices, {
  UseTokenPricesOptions,
} from '@/hooks/useTokenPrices.ts';
import useWalletTokensRaw from '@/hooks/useWalletTokensRaw.ts';
import { useMemo } from 'react';

export interface WalletTokenPrice {
  mintAddress: string;
  balance: number;
  price: number;
  totalPrice: number;
}

interface UseTokenPricesValue {
  tokenPrices?: Record<string, WalletTokenPrice>;
  isTokenPricesLoading: boolean;
  totalUsdValue: number;
}

const useWalletTokenPrices = (
  options?: UseTokenPricesOptions,
): UseTokenPricesValue => {
  const { walletTokens } = useWalletTokensRaw();
  const mintAddresses = useMemo(
    () => walletTokens.map((token) => token.mintAddress),
    [walletTokens],
  );

  const { tokenPrices, isTokenPricesLoading } = useTokenPrices(
    mintAddresses,
    options,
  );

  const walletTokenPrices = useMemo(() => {
    const extendedPrices = { ...tokenPrices } as Record<
      string,
      WalletTokenPrice
    >;
    for (const mintAddress in tokenPrices) {
      const tokenPrice = tokenPrices[mintAddress]!;
      const walletToken = walletTokens.find(
        (token) => token.mintAddress === mintAddress,
      )!;

      const tokenBalance = Number(walletToken.uiAmountBalance);
      const tokenDollarBalance = tokenBalance * tokenPrice.price;

      extendedPrices[mintAddress] = {
        ...tokenPrice,
        balance: tokenBalance,
        totalPrice: tokenDollarBalance,
      };
    }

    return extendedPrices;
  }, [tokenPrices, walletTokens]);

  const totalUsdValue = useMemo(() => {
    return Object.values(walletTokenPrices).reduce((total, price) => {
      return total + price.totalPrice;
    }, 0);
  }, [walletTokenPrices]);

  return {
    tokenPrices: walletTokenPrices,
    isTokenPricesLoading,
    totalUsdValue,
  };
};

export default useWalletTokenPrices;
