import useTokenMetadataCatalog from '@/hooks/useTokenMetadataCatalog.ts';
import useTokenMetadataFallback from '@/hooks/useTokenMetadataFallback.ts';
import { useMemo } from 'react';

export const useTokenDetails = (mintAddress: string) => {
  const { tokenMetadata, isTokensLoading } = useTokenMetadataCatalog();

  const {
    tokenMetadata: fallbackTokenMetadata,
    isTokensLoading: isTokensLoadingFallback,
  } = useTokenMetadataFallback(
    !tokenMetadata[mintAddress] ? [mintAddress] : [],
  );

  const token = useMemo(() => {
    return tokenMetadata[mintAddress] || fallbackTokenMetadata[mintAddress];
  }, [tokenMetadata, fallbackTokenMetadata, mintAddress]);

  return {
    token,
    isTokenLoading: isTokensLoading || isTokensLoadingFallback,
  };
};
