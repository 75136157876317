import { HeliusParsedTransaction } from '@/shared/api/helius/types.ts';

const BASE_URL = 'https://api.helius.xyz';

interface GetParsedTransactionsOptions {
  type?: string;
}

const createHeliusApi = (apiKey: string) => {
  const getParsedTransactions = async (
    walletAddress: string,
    { type = 'SWAP' }: GetParsedTransactionsOptions = {},
  ): Promise<HeliusParsedTransaction[]> => {
    const params = new URLSearchParams({
      type,
      'api-key': apiKey,
    });

    const response = await fetch(
      `${BASE_URL}/v0/addresses/${walletAddress}/transactions?${params.toString()}`,
      {
        headers: {
          accept: 'application/json',
        },
      },
    );

    if (!response.ok) {
      throw new Error(
        `[helius] Failed to fetch parsed transactions for wallet address: ${walletAddress}`,
      );
    }

    return (await response.json()) as HeliusParsedTransaction[];
  };

  return { getParsedTransactions };
};

const heliusApi = createHeliusApi(
  import.meta.env.VITE_HELIUS_API_KEY as string,
);
export default heliusApi;
