import { ImageWithBlurHash } from '@/shared/components/index.ts';

interface Props {
  img: string;
  title: string;
  subtitle?: string;
  blurhash?: string;
}

const Card = ({ img, blurhash, subtitle, title }: Props) => {
  return (
    <section className="rounded-lg overflow-hidden p-1 bg-secondary">
      <div className="aspect-square w-full flex-1 bg-white rounded-md overflow-hidden">
        <ImageWithBlurHash
          blurhash={blurhash}
          url={img}
          alt={title}
          imageClassName="w-full"
        />
      </div>
      <div className="p-2 flex flex-col">
        <span className="font-semibold text-sm text-primary">{title}</span>
        <span className="text-xs text-secondary font-medium">{subtitle}</span>
      </div>
    </section>
  );
};

export default Card;
