import { isRunningInTelegramWebview } from '@/hooks/telegram-web-app/useTelegramWebApp.ts';
import { useShowPopup } from '@vkruglikov/react-telegram-web-app';

const useTelegramWebAppShowPopup = () => {
  const showPopup = useShowPopup();

  if (!isRunningInTelegramWebview) {
    return (data: { message: string }) => {
      console.log(data);
      alert(JSON.stringify(data, null, 2));
    };
  }
  return showPopup;
};

export default useTelegramWebAppShowPopup;
