import { logEvent } from '@/analytics.ts';
import { AppRoute } from '@/appRoute.ts';
import { Nft } from '@/hooks/nfts/types.ts';
import { getTotalPrice } from '@/hooks/nfts/utils.ts';
import useTelegramWebApp from '@/hooks/telegram-web-app/useTelegramWebApp.ts';
import useTelegramWebAppInitData from '@/hooks/telegram-web-app/useTelegramWebAppInitData.ts';
import useTelegramWebAppShowPopup from '@/hooks/telegram-web-app/useTelegramWebAppShowPopup.ts';
import { SolanaTokenMetadata } from '@/hooks/useTokenMetadataCatalog.ts';
import useWallet from '@/hooks/useWallet.ts';
import { NftTransactionConfirmationPageNavigationState } from '@/pages/NftTransactionConfirmationPage.tsx';
import { dialectApiTrpcClient } from '@/shared/api/dialect/dialect-api-trpc-client.ts';
import miniAppApi from '@/shared/api/dialect/mini-app-api.ts';
import { isDeviceSupported } from '@/shared/utils/device-support.ts';
import { ArrayElement } from '@/shared/utils/typing.ts';
import { captureException } from '@sentry/react';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export interface UseNftBuyValue {
  buyNft: (nft: Nft, listing: ArrayElement<Nft['prices']>) => Promise<void>;
  isNftBuying: boolean;
  isNftBuySucceeded: boolean;
  error: Error | null;
}

// TODO: cleanup states
export const useNftBuy = (): UseNftBuyValue => {
  const [initDataUnsafe, initData] = useTelegramWebAppInitData();

  const navigate = useNavigate();
  const { openLink } = useTelegramWebApp();
  const webAppShowPopup = useTelegramWebAppShowPopup();

  const { walletAddress } = useWallet();
  const [isNftBuying, setIsNftBuying] = useState(false);
  const [isNftBuySucceeded, setIsNftBuySucceeded] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const showPopup = useTelegramWebAppShowPopup();

  const executeBuy = useCallback(
    async (nft: Nft, listing: ArrayElement<Nft['prices']>) => {
      if (!isDeviceSupported) {
        showPopup({
          title: 'Desktop coming soon',
          message: 'Please try again on mobile.',
          buttons: [{ id: 'ok', type: 'ok', text: 'Ok' }],
        });
        return;
      }
      if (
        !walletAddress ||
        !initData ||
        !initDataUnsafe ||
        !listing.isActionable
      ) {
        return;
      }
      setIsNftBuying(true);

      const { address, name, metadata, img } = nft;
      const { value, seller } = listing;

      try {
        const { serializedTx } = await miniAppApi.nfts.getBuyNftTx({
          initData,
          mintAddress: address,
          ownerAddress: seller,
          buyerAddress: walletAddress,
          price: value,
        });

        const { deeplink, referenceAccount } =
          await dialectApiTrpcClient.phantom.prepareSignAndSendTx.mutate({
            serializedTx,
            initData,
            metadata: {
              type: 'nft_buy',
              nftBuy: {
                nftName: name,
                mint: address,
                rank: metadata.rarityRank,
                imageUri: img.url,
              },
            },
          });

        const totalPrice = getTotalPrice(value, metadata.royaltyBps ?? 0);
        logEvent('nft_buy', {
          status: 'initiated',
          reference_account: referenceAccount,
          marketplace: listing.marketplaceId,
          nft_address: address,
          nft_name: name,
          nft_rank: metadata.rarityRank,
          collection_address: nft.collectionAddress,
          price: value,
          price_net: totalPrice,
          price_ui: totalPrice / 10 ** SolanaTokenMetadata.decimals,
          token_symbol: SolanaTokenMetadata.symbol,
          token_mint_address: SolanaTokenMetadata.mintAddress,
          token_decimals: SolanaTokenMetadata.decimals,
          owner_address: seller,
          buyer_address: walletAddress,
        });

        openLink(deeplink);
        const navState: NftTransactionConfirmationPageNavigationState = {
          referenceAccount,
          nft,
        };
        setTimeout(() => {
          navigate(AppRoute.NftTransactionConfirmation.url(), {
            state: navState,
          });
        }, 1000);
      } catch (e) {
        const err = e as Error;
        await webAppShowPopup({
          message: err.message || 'Something went wrong',
        });
        captureException(e);
      } finally {
        setIsNftBuying(false);
      }
    },
    [
      initData,
      initDataUnsafe,
      navigate,
      openLink,
      showPopup,
      walletAddress,
      webAppShowPopup,
    ],
  );

  const buyNft = useCallback(
    async (nft: Nft, listing: ArrayElement<Nft['prices']>) => {
      if (!walletAddress || !initData) {
        return;
      }
      try {
        setIsNftBuying(true);
        await executeBuy(nft, listing);
        setError(null);
        setIsNftBuySucceeded(true);
      } catch (e) {
        const err = e as Error;
        console.error(`Transaction error: ${err.message}`);
        setError(err);
      } finally {
        setIsNftBuying(false);
      }
    },
    [walletAddress, initData, executeBuy],
  );

  return {
    isNftBuying: isNftBuying,
    isNftBuySucceeded: isNftBuySucceeded,
    buyNft: buyNft,
    error,
  };
};
