import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    {...props}
  >
    <path d="M15.25 8.563l-5.5 5.25c-.313.28-.781.28-1.063-.032-.28-.312-.28-.781.032-1.062l4.156-3.969H2.25A.722.722 0 011.5 8a.74.74 0 01.75-.75h10.625L8.719 3.312a.746.746 0 01-.031-1.062.746.746 0 011.062-.031l5.5 5.25c.156.156.25.343.25.531a.783.783 0 01-.25.563z"></path>
  </svg>
);
export default SvgComponent;
