import { countLeadingDecimalZeros } from '@/shared/utils/math.ts';

const quantityFormat = new Intl.NumberFormat('en-US');

export function formatTokenAmount(num: number): string {
  if (num >= 1 && num < 1e3) {
    return removeTrailingZeros(num.toFixed(2));
  }
  if (num >= 1e3 && num < 1e6) {
    return removeTrailingZeros((num / 1e3).toFixed(1)) + 'K';
  }
  if (num >= 1e6 && num < 1e9) {
    return removeTrailingZeros((num / 1e6).toFixed(1)) + 'M';
  }
  if (num >= 1e9 && num < 1e12) {
    return removeTrailingZeros((num / 1e9).toFixed(1)) + 'B';
  }
  if (num >= 1e12) {
    return removeTrailingZeros((num / 1e12).toFixed(1)) + 'T';
  }
  return removeTrailingZeros(num.toPrecision(3));
}

const toPrecise5 = new Intl.NumberFormat('en-US', {
  maximumSignificantDigits: 5,
});

const toFixed2 = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
});

const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const toFixed5 = new Intl.NumberFormat('en-US', { maximumFractionDigits: 5 });
export function formatTokenAmountPrecise(num: number): string {
  if (num === 0) return '0';
  if (num < 1e-6) {
    return '< 0.000001';
  }
  if (num >= 1 && num < 1e6) {
    return toFixed5.format(num);
  }
  if (num >= 1e6 && num < 1e9) {
    return removeTrailingZeros((num / 1e6).toFixed(2)) + 'M';
  }
  if (num >= 1e9 && num < 1e12) {
    return removeTrailingZeros((num / 1e9).toFixed(2)) + 'B';
  }
  if (num >= 1e12) {
    return removeTrailingZeros((num / 1e12).toFixed(2)) + 'T';
  }
  return removeTrailingZeros(toPrecise5.format(num));
}

export function formatDollarAmount(num: number): string {
  if (num > 0 && num < 0.01) {
    return '<$0.01';
  }
  if (num >= 1e6 && num < 1e9) {
    return `$${(num / 1e6).toFixed(1)}M`;
  }
  if (num >= 1e9 && num < 1e12) {
    return `$${(num / 1e9).toFixed(1)}B`;
  }
  if (num >= 1e12) {
    return `$${(num / 1e12).toFixed(1)}T`;
  }
  return USDollar.format(num);
}

function removeTrailingZeros(value: string): string {
  return value.replace(/\.?0+$/, '');
}

export function formatQuantity(quantity?: number | null) {
  if (quantity === null || quantity === undefined) {
    return '-';
  }
  return quantityFormat.format(quantity);
}

export function formatPercentage(value: string) {
  return toFixed2.format(Number(value) * 100) + '%';
}

const subscripts = {
  0: '₀',
  1: '₁',
  2: '₂',
  3: '₃',
  4: '₄',
  5: '₅',
  6: '₆',
  7: '₇',
  8: '₈',
  9: '₉',
  10: '₁₀',
  11: '₁₁',
  12: '₁₂',
  13: '₁₃',
  14: '₁₄',
  15: '₁₅',
  16: '₁₆',
  17: '₁₇',
  18: '₁₈',
  19: '₁₉',
  20: '₂₀',
};

export function formatTokenPriceWithSubscripts(price: number) {
  const strPrice = price
    .toLocaleString('fullwide', {
      useGrouping: false,
      maximumSignificantDigits: 20,
    })
    .replace(',', '.');

  const [major, minor = ''] = strPrice.split('.');

  const significantZeros = countLeadingDecimalZeros(price);

  if (significantZeros >= 3 && significantZeros < 20) {
    const afterZerosNum = Number(
      removeTrailingZeros(minor.slice(significantZeros, significantZeros + 3)),
    );
    // @ts-expect-error - TS doesn't know that significantZeros is a valid key of subscripts
    return `$${major}.0${subscripts[significantZeros]}${afterZerosNum}`;
  }

  return strPrice;
}

export const getFormattedTokenPrice = (price?: number) => {
  if (typeof price !== 'number') {
    return 'N/A';
  }

  if (price < 0.001) {
    return formatTokenPriceWithSubscripts(price);
  }
  return '$' + formatTokenAmount(price);
};

export function toSentenceCase(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
