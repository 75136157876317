import { track } from '@amplitude/analytics-browser';

export const IS_ANALYTICS_ENABLED =
  import.meta.env.VITE_AMPLITUDE_API_KEY !== undefined &&
  import.meta.env.VITE_ENVIRONMENT !== 'local-development';

export const logEvent = (name: string, params?: Record<string, any>) => {
  if (!IS_ANALYTICS_ENABLED) {
    console.log('logEvent', name, params);
    return;
  }

  track(name, params);
};
