import { ViewportFlex } from '@/components/ViewportFlex.tsx';
import { useTrendingCollectibles } from '@/hooks/nfts/useTrendingCollectibles.ts';
import { NftRowCard } from '@/pages/BuyTrendingCollectiblesPage/ui/NftRowCard.tsx';
import { PulseLoader } from '@/shared/components';
import { Header } from './ui';

const BuyTrendingCollectiblesPage = () => {
  const { collectibles, isCollectiblesLoading } = useTrendingCollectibles();

  return (
    <ViewportFlex>
      <div className="flex flex-col w-full">
        <section className="px-3">
          <Header />
        </section>
        {collectibles.length === 0 && !isCollectiblesLoading && (
          <div className="flex-1 flex items-center justify-center px-6">
            <h3 className="text-h3 text-caption text-secondary text-center">
              Oops, seems like the list is empty or something went wrong. Please
              try again later.
            </h3>
          </div>
        )}
        {isCollectiblesLoading && (
          <section className="flex flex-col gap-1 px-3 mt-3">
            {Array.from({ length: 8 }).map((_, index) => (
              <PulseLoader
                key={index}
                className="bg-secondary rounded-xl h-14"
              />
            ))}
          </section>
        )}
        {collectibles.length > 0 && !isCollectiblesLoading && (
          <section className="flex flex-col gap-1 px-3 mt-3 pb-3">
            {collectibles.map((nft) => (
              <NftRowCard key={nft.address} nft={nft} />
            ))}
          </section>
        )}
      </div>
    </ViewportFlex>
  );
};

export default BuyTrendingCollectiblesPage;
