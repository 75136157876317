import {
  jupiterQuoteProvider,
  raydiumQuoteProvider,
} from '@/hooks/token-swap/quote-providers.ts';
import { Quote } from '@/hooks/token-swap/types.ts';
import { TokenMetadata } from '@/hooks/useTokenMetadataCatalog.ts';
import { InputType } from '@/shared/utils/typing.ts';
import useSWR from 'swr';

interface UseTokenSwapQuoteError {
  error: 'NO_POOL_FOUND';
}

interface UseTokenSwapData {
  quote: Quote;
  quoteOutAmountUi: number;
}

interface UseTokenSwapQuoteArgs {
  inputType: InputType;
  payingToken?: TokenMetadata | null;
  payingAmount?: string | null;
  receivingToken?: TokenMetadata | null;
  receivingAmount?: string | null;
  slippageBps?: number;
}

export interface UseTokenSwapQuoteValue {
  quote?: Quote;
  quoteOutAmountUi?: number;
  isQuoteLoading: boolean;
  isQuoteValidating: boolean;
  refreshQuote: () => Promise<UseTokenSwapData | undefined>;
  error?: UseTokenSwapQuoteError;
}

export const DEFAULT_SLIPPAGE_BPS = 50;

const useTokenSwapQuote = ({
  inputType,
  payingToken,
  payingAmount,
  receivingToken,
  receivingAmount,
  slippageBps = DEFAULT_SLIPPAGE_BPS,
}: UseTokenSwapQuoteArgs): UseTokenSwapQuoteValue => {
  const { data, isLoading, isValidating, mutate, error } = useSWR<
    UseTokenSwapData | undefined,
    UseTokenSwapQuoteError
  >(
    ['TOKEN_SWAP_QUOTE', payingToken?.mintAddress, receivingToken?.mintAddress],
    async () => {
      const missingPayingData =
        inputType === InputType.PAYING &&
        (!payingToken ||
          !payingAmount ||
          !receivingToken ||
          payingToken.mintAddress === receivingToken.mintAddress);
      const missingReceivingData =
        inputType === InputType.RECEIVING &&
        (!receivingToken ||
          !receivingAmount ||
          !payingToken ||
          receivingToken.mintAddress === payingToken.mintAddress);
      const missingData = missingPayingData || missingReceivingData;
      if (missingData) {
        return;
      }

      const token = payingToken;
      const otherToken = receivingToken;
      const amount =
        inputType === InputType.PAYING ? payingAmount : receivingAmount;

      // TODO: Consolidate this with the logic above, rm here.
      if (!token || !otherToken) {
        return;
      }

      const inputDecimals =
        inputType === InputType.PAYING ? token.decimals : otherToken.decimals;
      const inputFractionalAmount = Math.round(
        Number(amount) * 10 ** inputDecimals,
      ); // TODO: use bignum

      if (inputFractionalAmount === 0) {
        return;
      }

      const args = [
        token,
        otherToken,
        inputFractionalAmount,
        slippageBps,
        inputType === InputType.PAYING ? 'ExactIn' : 'ExactOut',
      ] as const;

      try {
        const { quote, quoteOutAmountUi } = await jupiterQuoteProvider(
          ...args,
        ).catch(() => raydiumQuoteProvider(...args));

        return {
          quote,
          quoteOutAmountUi,
        };
      } catch (e) {
        console.error('Failed to calculate quote', e); // TODO: add sentry

        throw { error: 'NO_POOL_FOUND' }; // TODO: iterate on how to throw errors
      }
    },
    {
      refreshInterval: 15000,
      dedupingInterval: 15000,
    },
  );

  return {
    quote: data?.quote,
    quoteOutAmountUi: data?.quoteOutAmountUi,
    isQuoteLoading: isLoading,
    isQuoteValidating: isValidating,
    refreshQuote: mutate,
    error,
  };
};

export default useTokenSwapQuote;
