import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={42}
    height={42}
    viewBox="0 0 42 42"
    fill="none"
    {...props}
  >
    <rect width={40} height={40} x={0.5} fill="#007AFF" rx={20} />
    <path
      fill="#fff"
      d="M27.188 15.313a.964.964 0 0 1 0 1.406l-8 8a.964.964 0 0 1-1.407 0l-4-4a.964.964 0 0 1 0-1.407.964.964 0 0 1 1.406 0l3.313 3.282 7.281-7.282a.964.964 0 0 1 1.407 0Z"
    />
  </svg>
);
export default SvgComponent;
