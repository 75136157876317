import { useTokenDetails } from '@/hooks/token-swap/useTokenDetails.ts';
import { useTokenMarketData } from '@/hooks/useTokenMarketData.ts';
import { useTokenPriceHistory } from '@/hooks/useTokenPriceHistory.ts';
import useWallet from '@/hooks/useWallet.ts';
import useWalletTokenPrices from '@/hooks/useWalletTokenPrices.ts';
import { useWalletTokenSwapHistory } from '@/hooks/useWalletTokenSwapHistory.ts';
import { TokenDetailsContext } from '@/pages/TokenDetailsPage/model.ts';
import { BirdeyePriceHistoryType } from '@/shared/api/birdseye/types.ts';
import { Chart, PulseLoader } from '@/shared/components';
import {
  formatDollarAmount,
  formatTokenAmount,
} from '@/shared/utils/formatting.ts';
import { countLeadingDecimalZeros } from '@/shared/utils/math.ts';
import { clsx } from 'clsx';
import { UTCTimestamp } from 'lightweight-charts';
import { useContext, useMemo } from 'react';

const BUY_COLOR = '#007AFF';
const SELL_COLOR = '#ff3b30';

export const TokenPositionChart = () => {
  const { walletAddress } = useWallet();
  const { mintAddress } = useContext(TokenDetailsContext);

  const { marketData, isLoading: isMarketDataLoading } = useTokenMarketData(
    mintAddress,
    {
      refreshInterval: 10000,
    },
  );

  const { tokenPrices = {}, isTokenPricesLoading } = useWalletTokenPrices();
  const { token, isTokenLoading } = useTokenDetails(mintAddress);
  const tokenData = useMemo(
    () => tokenPrices[mintAddress],
    [tokenPrices, mintAddress],
  );

  const change = useMemo(() => {
    const price = tokenData?.price;
    const historyPrice = marketData?.history24hPrice;
    const tokenAmount = tokenData?.balance;
    const totalPrice = tokenData?.totalPrice;

    if (!price || !historyPrice || !totalPrice || !tokenAmount) {
      return null;
    }

    return totalPrice - historyPrice * tokenAmount;
  }, [
    tokenData?.price,
    marketData?.history24hPrice,
    tokenData?.balance,
    tokenData?.totalPrice,
  ]);

  const {
    priceHistory,
    from,
    to,
    isLoading: isPriceHistoryLoading,
  } = useTokenPriceHistory(mintAddress, {
    timeFrom: 'day-before',
    timeTo: 'now',
    type: BirdeyePriceHistoryType.FIFTEEN_MINUTES,
  });

  const { tokenSwapHistory } = useWalletTokenSwapHistory(
    walletAddress,
    mintAddress,
    {
      from,
      to,
      skipLoading: isPriceHistoryLoading,
    },
  );

  const chartData = useMemo(() => {
    return priceHistory.map((price) => {
      return {
        time: price.unixTime as UTCTimestamp,
        value: price.value,
      };
    });
  }, [priceHistory]);

  const markers = useMemo(() => {
    return tokenSwapHistory.map((tx) => {
      return {
        time: tx.timestamp as UTCTimestamp,
        position: 'inBar' as const,
        shape: 'circle' as const,
        color: tx.action === 'IN' ? BUY_COLOR : SELL_COLOR,
      };
    });
  }, [tokenSwapHistory]);

  const [integer, decimal] = useMemo(
    () =>
      tokenData?.totalPrice
        ? formatDollarAmount(tokenData.totalPrice).split('.')
        : [],
    [tokenData?.totalPrice],
  );

  const minMove = useMemo(() => {
    if (!tokenData?.price) {
      return;
    }

    const zeros = countLeadingDecimalZeros(tokenData.price);

    if (zeros > 0) {
      return 1 / 10 ** (countLeadingDecimalZeros(tokenData.price) + 3); // add 2 decimal points
    }

    return 0.01;
  }, [tokenData?.price]);

  return (
    <section className="rounded-lg shadow-box overflow-hidden pt-3 pb-2 px-3">
      <div
        className={clsx('flex flex-row justify-between', {
          hidden: !walletAddress,
        })}
      >
        <div className="flex flex-col">
          <span className="text-caption text-secondary">My Position</span>
          {!isTokenPricesLoading && !isTokenLoading && !isMarketDataLoading ? (
            <>
              <div className="flex items-center gap-3">
                <h3 className="text-highlight">
                  {tokenData ? (
                    <>
                      <span>{integer}</span>
                      <span className="text-secondary">.{decimal}</span>
                    </>
                  ) : (
                    <>&mdash;</>
                  )}
                </h3>
                <div
                  className={clsx(
                    'px-1.5 py-0.5 rounded-full text-subtext font-semibold',
                    {
                      ['bg-error/10 text-error']: change && change < 0,
                      ['bg-success/10 text-success']: change && change > 0,
                      ['bg-secondary text-primary']: !change || change === 0,
                    },
                  )}
                >
                  {formatDollarAmount(change ?? 0)}
                  <span className="text-secondary ml-1">24h</span>
                </div>
              </div>
              <span className="text-caption text-secondary font-semibold">
                {tokenData ? (
                  formatTokenAmount(tokenData.balance)
                ) : (
                  <>&mdash;</>
                )}{' '}
                {token?.symbol}
              </span>
            </>
          ) : (
            <>
              {/* mt = line-height - font-size */}
              <PulseLoader className="h-[1.5rem] w-32 bg-secondary rounded-lg mt-[0.5rem]" />
              <PulseLoader className="h-[0.6875rem] w-14 bg-secondary rounded-lg mt-[0.1875rem]" />
            </>
          )}
        </div>
        <div className="pr-3.5">
          <div className="flex items-center gap-2.5">
            <div className="flex items-center">
              <div className="w-2 h-2 bg-brand rounded-full" />
              <span className="text-caption text-secondary ml-1">Buying</span>
            </div>
            <div className="flex items-center">
              <div className="w-2 h-2 bg-error rounded-full" />
              <span className="text-caption text-secondary ml-1">Selling</span>
            </div>
          </div>
        </div>
      </div>
      <Chart data={chartData} markers={markers} minStep={minMove} />
    </section>
  );
};
