import { Nft } from '@/hooks/nfts/types.ts';
import { PulseLoader } from '@/shared/components';
import { formatPercentage } from '@/shared/utils/formatting.ts';

interface Props {
  nft?: Nft | null;
  isLoading: boolean;
}

const calculateAttributeProportion = (
  totalAttributeCount?: number,
  totalMints?: number,
) => {
  if (!totalAttributeCount || !totalMints) {
    return;
  }

  return totalAttributeCount / totalMints;
};

export const TraitsSection = ({ nft, isLoading }: Props) => {
  return nft && !isLoading ? (
    <div className="grid grid-cols-2 gap-2">
      {nft?.metadata.attributes.map((attr) => (
        <Trait
          key={attr.traitType}
          name={attr.traitType}
          value={attr.value}
          percentage={calculateAttributeProportion(
            attr.totalCount,
            nft.metadata.collectionTotalSupply,
          )}
        />
      ))}
    </div>
  ) : (
    <PulseTraitsSection />
  );
};

const PulseTraitsSection = () => {
  return (
    <div className="grid grid-cols-2 gap-2">
      <PulseLoader className="bg-secondary rounded-lg h-12" />
      <PulseLoader className="bg-secondary rounded-lg h-12" />
      <PulseLoader className="bg-secondary rounded-lg h-12" />
      <PulseLoader className="bg-secondary rounded-lg h-12" />
    </div>
  );
};

const Trait = ({
  name,
  value,
  percentage,
}: {
  name: string;
  value: string;
  percentage?: number;
}) => {
  return (
    <div className="bg-secondary rounded-lg px-3 py-2 flex items-center">
      <div className="flex flex-col flex-1">
        <span className="text-caption text-secondary font-medium mb-0.5">
          {name}
        </span>
        <span className="text-subtext font-semibold text-ellipsis">
          {value}
        </span>
      </div>
      {percentage && (
        <div className="flex items-center">
          <span className="bg-brand/10 text-brand py-0.5 px-1 text-subtext font-semibold rounded-md">
            {formatPercentage(percentage.toString())}
          </span>
        </div>
      )}
    </div>
  );
};
