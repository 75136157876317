import { AppRoute } from '@/appRoute.ts';
import ProtectedRoute from '@/components/ProtectedRoute.tsx';
import { SwapFormPersistenceProvider } from '@/components/TokenSwapFormPersistenceContext.tsx';
import TelegramWebAppStartLocation from '@/components/telegram-web-app/TelegramWebAppDeeplinkNavigation.tsx';
import TelegramWebAppNavigation from '@/components/telegram-web-app/TelegramWebAppNavigation.tsx';
import useTelegramWebAppInitData from '@/hooks/telegram-web-app/useTelegramWebAppInitData.ts';
import useTelegramWebAppShowPopup from '@/hooks/telegram-web-app/useTelegramWebAppShowPopup.ts';
import useWallet from '@/hooks/useWallet.ts';
import BuyPage from '@/pages/BuyPage.tsx';
import BuyTrendingCollectiblesPage from '@/pages/BuyTrendingCollectiblesPage';
import CollectibleDetailsPage from '@/pages/CollectibleDetailsPage';
import ConnectWalletPhantomRedirectPage from '@/pages/ConnectWalletPhantomRedirectPage.tsx';
import LoadingPage from '@/pages/LoadingPage.tsx';
import NftTransactionConfirmationPage from '@/pages/NftTransactionConfirmationPage.tsx';
import SellPage from '@/pages/SellPage.tsx';
import SettingsPage from '@/pages/SettingsPage.tsx';
import SwapConfirmationPage from '@/pages/SwapConfirmationPage.tsx';
import TokenDetailsPage from '@/pages/TokenDetailsPage';
import TokenSwapTransactionConfirmationPage from '@/pages/TokenSwapTransactionConfirmationPage.tsx';
import WalletPage, { WalletCollectiblesPage, WalletTokensPage, } from '@/pages/WalletPage';
import { Identify, identify, setUserId } from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/react';
import { WebAppProvider } from '@vkruglikov/react-telegram-web-app';
import { useEffect, useState } from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import './App.css';
import { GeneratePage } from './generate-pages';
import SmartMessagePage from '@/pages/SmartMessagePage.tsx';
import SignTxPhantomRedirectPage from '@/pages/SignTxPhantomRedirectPage.tsx';

const WebApp = () => {
  const [initDataUnsafe] = useTelegramWebAppInitData();
  const { walletAddress, isWalletLoading } = useWallet();
  const showPopup = useTelegramWebAppShowPopup();

  // useEffect(() => {
  //   if (!isMobileOrTablet) {
  //     showPopup({
  //       title: 'Desktop coming soon',
  //       message:
  //         'Some features might be unavailable. Please try opening on mobile',
  //       buttons: [{ id: 'ok', type: 'ok', text: 'Ok' }],
  //     });
  //     return;
  //   }
  // }, [showPopup]);

  useEffect(() => {
    if (initDataUnsafe?.user?.id) {
      setUserId(initDataUnsafe.user.id.toString());
      Sentry.setUser({
        id: initDataUnsafe.user.id,
        username: initDataUnsafe.user.username,
      });

      if (initDataUnsafe.user.username) {
        const usernameIdentify = new Identify();
        usernameIdentify.set('username', initDataUnsafe.user.username!);

        identify(usernameIdentify);
      }
    }
  }, [initDataUnsafe?.user?.id, initDataUnsafe?.user?.username]);

  if (!walletAddress && isWalletLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <TelegramWebAppNavigation />
      <TelegramWebAppStartLocation />
      <Outlet />
    </>
  );
};

const GenerateWebApp = () => {
  return <Outlet />;
};

const App = () => {
  const [smoothButtonsTransition, setSmoothButtonsTransition] = useState(true);

  return (
    <SwapFormPersistenceProvider>
      <WebAppProvider options={{ smoothButtonsTransition }}>
        <BrowserRouter>
          <Routes>
            <Route path={AppRoute.Generate.path} element={<GenerateWebApp />}>
              <Route
                path={AppRoute.Generate.subRoutes.TokenInfo.path}
                element={<GeneratePage.TokenInfo />}
              />
            </Route>
            <Route element={<WebApp />}>
              <Route
                path={AppRoute.Home.path}
                element={
                  <ProtectedRoute>
                    <WalletPage />
                  </ProtectedRoute>
                }
              >
                <Route
                  path={AppRoute.Home.subRoutes.HomeTokens.path}
                  element={<WalletTokensPage />}
                />
                <Route
                  path={AppRoute.Home.subRoutes.HomeCollectibles.path}
                  element={<WalletCollectiblesPage />}
                />
              </Route>
              <Route
                path={AppRoute.TokenDetails.path}
                element={<TokenDetailsPage />}
              />
              <Route path={AppRoute.Settings.path} element={<SettingsPage />} />
              <Route
                path={AppRoute.TrendingCollectibles.path}
                element={<BuyTrendingCollectiblesPage />}
              />
              <Route
                path={AppRoute.CollectibleDetails.path}
                element={<CollectibleDetailsPage />}
              />
              <Route
                path={AppRoute.Buy.path}
                element={
                  <ProtectedRoute>
                    <BuyPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path={AppRoute.Sell.path}
                element={
                  <ProtectedRoute>
                    <SellPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path={AppRoute.SwapConfirm.path}
                element={<SwapConfirmationPage />}
              />
              <Route
                path={AppRoute.TokenSwapTransactionConfirmation.path}
                element={<TokenSwapTransactionConfirmationPage />}
              />
              <Route
                path={AppRoute.NftTransactionConfirmation.path}
                element={<NftTransactionConfirmationPage />}
              />
              <Route
                path={AppRoute.ConnectPhantomWallet.path}
                element={<ConnectWalletPhantomRedirectPage />}
              />
              <Route
                path={AppRoute.SignPhantomTx.path}
                element={<SignTxPhantomRedirectPage />}
              />
              <Route
                path={AppRoute.SmartMessage.path}
                element={<SmartMessagePage />}
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </WebAppProvider>
    </SwapFormPersistenceProvider>
  );
};
export default App;
