import { logEvent } from '@/analytics.ts';
import { AppRoute } from '@/appRoute.ts';
import { ViewportFlex } from '@/components/ViewportFlex.tsx';
import useConnectWallet from '@/hooks/useConnectWallet.ts';
import useWallet from '@/hooks/useWallet.ts';
import PhantomIcon from '@/icons/PhantomIcon.tsx';
import SpinnerIcon from '@/icons/SpinnerIcon.tsx';
import Button from '@/shared/components/Button.tsx';
import PulseLoader from '@/shared/components/PulseLoader.tsx';
import { shortenAddress } from '@/shared/utils/wallet.ts';
import { reset } from '@amplitude/analytics-browser';
import { FC, PropsWithChildren, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const SettingsPage: FC = () => {
  const { walletAddress, isWalletLoading } = useWallet();

  const navigate = useNavigate();

  const {
    connectWallet,
    disconnectWallet,
    isSwitchingWallet,
    isConnectingWallet,
    isDisconnectingWallet,
  } = useConnectWallet();

  const changeOrConnectWalletCTA = useMemo(() => {
    if (isWalletLoading) {
      return (
        <PulseLoader className="bg-secondary w-full h-[50px] rounded-xl" />
      );
    }
    if (!isWalletLoading && !walletAddress) {
      return (
        <Button
          disabled={isSwitchingWallet}
          isLoading={isConnectingWallet}
          onClick={() => !isSwitchingWallet && connectWallet()}
        >
          Connect Wallet
        </Button>
      );
    }
    return (
      <div className="flex flex-row justify-between rounded-xl px-4 py-2 border border-primary items-center">
        <div className="flex flex-row gap-2 items-center justify-center py-2">
          <PhantomIcon />
          <span className="text-subtext">{shortenAddress(walletAddress!)}</span>
        </div>
        <div className="flex flex-row gap-2">
          <button
            className="bg-secondary text-subtext py-2 px-3 items-center justify-center rounded-lg"
            disabled={isSwitchingWallet}
            onClick={() => !isSwitchingWallet && connectWallet()}
          >
            {isConnectingWallet ? (
              <SpinnerIcon className="h-4" />
            ) : (
              'Change Wallet'
            )}
          </button>
        </div>
      </div>
    );
  }, [
    isWalletLoading,
    walletAddress,
    isSwitchingWallet,
    connectWallet,
    isConnectingWallet,
  ]);

  const changeWalletSection = (
    <section className="flex flex-col mt-2">
      <div className="flex flex-col">
        <h2 className="text-h2 mb-2">Wallet</h2>
        {changeOrConnectWalletCTA}
      </div>
    </section>
  );

  const aboutDialectSection = (
    <>
      <section className="border-b border-primary pb-4 mb-2 mt-2 leading-tight">
        <h1 className="text-h2 py-2">About Dialect</h1>
        <p className="text-secondary font-medium mb-4 leading-tight">
          Dialect Operator is the fastest, safest and most intuitive way to
          trade tokens over chat. Dialect brings you all the security of Phantom
          with the convenience, context, and fun of chat.
        </p>
      </section>
      <section className="border-b border-primary pb-4 mb-2 mt-2 leading-tight">
        <h1 className="text-h2 py-2">Using Dialect</h1>
        <p className="text-secondary font-medium mb-4 leading-tight">
          With Dialect Operator you can buy, sell and get info about tokens
          using natural language, commands, or Dialect's innovative, multimodal
          interface built right into Telegram. Say what you want to do and
          Dialect will prepare the transaction for you to securely sign.
        </p>
        <p className="text-secondary font-medium mb-4 leading-tight">
          You can trade tokens with the /buy, /sell, and /swap commands, as well
          as a variety of flexible, natural language alternatives. These are
          described below.
        </p>
        <section className="pb-4 mb-4 mt-2">
          <Command>/buy</Command>
          <p className="font-medium text-secondary mt-2 mb-2 leading-tight">
            You buy tokens with a variety of commands and natural language.
          </p>
          <ul>
            <li className="ml-4 italic font-medium text-secondary mt-2 mb-2 leading-tight">
              buy
            </li>
            <li className="ml-4 italic font-medium text-secondary mt-2 mb-2 leading-tight">
              buy sol
            </li>
            <li className="ml-4 italic font-medium text-secondary mt-2 mb-2 leading-tight">
              buy 1 sol with usdc
            </li>
            <li className="ml-4 italic font-medium text-secondary mt-2 mb-2 leading-tight">
              buy 10 usdc of sol
            </li>
            <li className="ml-4 italic font-medium text-secondary mt-2 mb-2 leading-tight">
              buy 1 sol using usdc
            </li>
            <li className="ml-4 italic font-medium text-secondary mt-2 mb-2 leading-tight">
              get me some sol please
            </li>
          </ul>
        </section>
        <section className="pb-4 mb-4 mt-2 leading-tight">
          <Command>/sell</Command>
          <p className="font-medium text-secondary mt-2">
            You can sell tokens with a variety of commands and natural language.
          </p>
          <ul className="border-none">
            <li className="ml-4 italic font-medium text-secondary mt-2 mb-2 leading-tight">
              sell
            </li>
            <li className="ml-4 italic font-medium text-secondary mt-2 mb-2 leading-tight">
              sell sol
            </li>
            <li className="ml-4 italic font-medium text-secondary mt-2 mb-2 leading-tight">
              sell 1 sol for usdc
            </li>
            <li className="ml-4 italic font-medium text-secondary mt-2 mb-2 leading-tight">
              sell 10 usdc of sol
            </li>
            <li className="ml-4 italic font-medium text-secondary mt-2 mb-2 leading-tight">
              sell 1 sol to usdc
            </li>
            <li className="ml-4 italic font-medium text-secondary mt-2 mb-2 leading-tight">
              i'd like to sell some sol please and thank you
            </li>
          </ul>
        </section>
        <section className="pb-4 mb-4 mt-2 leading-tight">
          <Command>/swap</Command>
          <p className="font-medium text-secondary mt-2">
            Swap tokens, a convenient alternative to /buy and /sell, with the
            following commands and natural language.
          </p>
          <ul>
            <li className="ml-4 italic font-medium text-secondary mt-2 mb-2 leading-tight">
              swap
            </li>
            <li className="ml-4 italic font-medium text-secondary mt-2 mb-2 leading-tight">
              swap 1 sol to usdc
            </li>
            <li className="ml-4 italic font-medium text-secondary mt-2 mb-2 leading-tight">
              swap 10 usdc for sol
            </li>
            <li className="ml-4 italic font-medium text-secondary mt-2 mb-2 leading-tight">
              swap 1 sol to usdc
            </li>
          </ul>
        </section>
        <section className="pb-4 leading-tight">
          <Command>/info</Command>
          <p className="font-medium text-secondary mt-2">
            Get at-a-glance info about tokens. You can use token symbols, CAs or
            Birdeye links.
          </p>
          <ul>
            <li className="ml-4 italic font-medium text-secondary mt-2 mb-2 leading-tight">
              sol
            </li>
            <li className="ml-4 italic font-medium text-secondary mt-2 mb-2 leading-tight">
              /info sol
            </li>
            <li className="ml-4 italic font-medium text-secondary mt-2 mb-2 leading-tight break-words">
              jtojtomepa8beP8AuQc6eXt5FriJwfFMwQx2v2f9mCL
            </li>
            <li className="ml-4 italic font-medium text-secondary mt-2 mb-2 leading-tight break-words">
              https://birdeye.so/token/jtojtomepa8beP8AuQc6eXt5FriJwfFMwQx2v2f9mCL?chain=solana
            </li>
            <li className="ml-4 italic font-medium text-secondary mt-2 mb-2 leading-tight">
              what's the latest with wif
            </li>
          </ul>
        </section>
      </section>
      <section className="border-b border-primary pb-4 mb-2 mt-2 leading-tight">
        <h1 className="text-h2 py-2">Security & Wallet Custody</h1>
        <p className="text-secondary font-medium mb-4 leading-tight">
          Connecting to Dialect with your Phantom wallet is similar to
          connecting to a web dApp. Dialect never has access to your private
          keys and cannot execute transactions without your approval. All
          transactions must be approved by you from inside the Phantom app on
          your phone.
        </p>
        <p className="text-secondary font-medium mb-4 leading-tight">
          To learn more about our practices around security and wallet custody,
          see our{' '}
          <a className="text-brand" href="https://docs.dialect.to">
            docs
          </a>
          .
        </p>
      </section>
      <section className="border-b border-primary pb-4 mb-2 leading-tight">
        <h1 className="text-h2 py-2">Fees</h1>
        <p className="text-secondary font-medium mb-4 leading-tight">
          Dialect takes NO FEES on trades. Protocols that provide trade
          liquidity (Raydium, Orca, Tensor, etc.) may have their own fees. These
          will be included in the price you see before executing the
          transaction.
        </p>
        <p className="text-secondary font-medium mb-4 leading-tight">
          To learn more about fees, see our{' '}
          <a className="text-brand" href="https://docs.dialect.to">
            docs
          </a>
          .
        </p>
      </section>
      <section>
        <h1 className="text-h2 py-2">Stay Tuned</h1>
        <p className="text-secondary font-medium mb-4 leading-tight">
          Soon, you'll be able to set alerts for specific tokens, share trades,
          info, and your PnL in group chats, and trade NFTs, all while staying
          inside Telegram, and with the safety of Phantom built in.
        </p>
      </section>
      <section className="border-b border-primary pb-4 mb-2 mt-2 leading-tight">
        <h2 className="text-h2 py-2">Feedback, Bugs & Feature Requests</h2>
        <p className="text-secondary font-medium mb-4 leading-tight">
          Have feedback, feature requests or bugs to report? Join the{' '}
          <a
            className="text-brand"
            href="https://discord.com/invite/saydialect"
          >
            Dialect Discord
          </a>
          , or reach out to a member of our team directly on{' '}
          <a className="text-brand" href="https://t.me/selinamz">
            Telegram
          </a>
          .
        </p>
      </section>
      <section className="pb-6">
        <h1 className="text-h2 py-2">Legal</h1>
        <p className="text-secondary leading-tight mt-2 font-medium">
          Review our{' '}
          <a className="text-brand" href="https://dialect.to/tos">
            Terms of Service
          </a>{' '}
          and{' '}
          <a className="text-brand" href="https://dialect.to/privacy">
            Privacy Policy
          </a>{' '}
          for detailed usage and data handling.
        </p>
      </section>
    </>
  );

  const disconnectWalletSection = walletAddress && (
    <section className="pb-4">
      <Button
        className="bg-error"
        disabled={isSwitchingWallet}
        isLoading={isDisconnectingWallet}
        onClick={async () => {
          logEvent('disconnect_wallet', {
            wallet_address: walletAddress,
          });
          !isSwitchingWallet && (await disconnectWallet());
          reset();
          navigate(AppRoute.Home.path, { replace: true });
        }}
      >
        Disconnect Wallet
      </Button>
    </section>
  );

  return (
    <ViewportFlex>
      <main className="px-4 w-full flex flex-col gap-4">
        {changeWalletSection}
        {aboutDialectSection}
        {disconnectWalletSection}
      </main>
    </ViewportFlex>
  );
};

const Command = ({ children }: PropsWithChildren) => {
  return (
    <span className="bg-brand/10 text-brand font-mono p-1 rounded-lg font-semibold tracking-wide">
      {children}
    </span>
  );
};

export default SettingsPage;
