import useWalletSol from '@/hooks/useWalletSol.ts';
import { formatTokenAmount } from '@/shared/utils/formatting.ts';

export const Header = () => {
  const { token } = useWalletSol();

  return (
    <header className="flex flex-col items-center justify-center py-2 w-full">
      <h2 className="text-h2 font-bold mb-1">Buy the floor price</h2>
      <h5 className="text-subtext font-medium text-secondary">
        Balance: {token ? formatTokenAmount(token.uiAmountBalance) : '-'} SOL
      </h5>
    </header>
  );
};
