export interface BirdeyeTokenOverview {
  address: string;
  symbol: string;
  name: string;
  price: number;
  decimals: number;
  logoURI?: string;
  // market cap
  mc?: number; // sometimes this is undefined, but usually exists
  priceChange30mPercent: number;
  priceChange1hPercent: number;
  priceChange2hPercent: number;
  priceChange4hPercent: number;
  priceChange6hPercent: number;
  priceChange8hPercent: number;
  priceChange12hPercent: number;
  priceChange24hPercent: number;
  history24hPrice: number;
}

export interface BirdeyeTokenPrice {
  value: number;
  updateUnixTime: number;
  updateHumanTime: string;
}

export enum BirdeyePriceHistoryType {
  ONE_MINUTE = '1m',
  THREE_MINUTES = '3m',
  FIVE_MINUTES = '5m',
  FIFTEEN_MINUTES = '15m',
  THIRTY_MINUTES = '30m',
  ONE_HOUR = '1H',
  TWO_HOURS = '2H',
  FOUR_HOURS = '4H',
  SIX_HOURS = '6H',
  EIGHT_HOURS = '8H',
  TWELVE_HOURS = '12H',
  ONE_DAY = '1D',
  THREE_DAYS = '3D',
  ONE_WEEK = '1W',
  ONE_MONTH = '1M',
}

export interface GetBirdeyeTokenPriceHistoryResponse {
  data: {
    items: BirdeyePriceHistoryItem[];
  };
  success: boolean;
}

export interface BirdeyePriceHistoryItem {
  unixTime: number;
  value: number;
}


export interface GetBirdeyeTokenOverviewResponse {
  data: BirdeyeTokenOverview;
  success: boolean;
}

export interface GetBirdeyeTokenPriceResponse {
  data: BirdeyeTokenPrice;
  success: boolean;
}

