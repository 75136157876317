import { TokenMetadata } from '@/hooks/useTokenMetadataCatalog.ts';
import { InputType } from '@/shared/utils/typing.ts';
import React, { createContext, PropsWithChildren, useState } from 'react';

// Define the context shape
interface SwapContextProps {
  inputType: InputType | null;
  setInputType: (inputType: InputType | null) => void;
  userChangedAmount: boolean;
  setUserChangedAmount: (changed: boolean) => void;
  payingToken: TokenMetadata | null;
  setPayingToken: (token: TokenMetadata | null) => void;
  payingAmount: string;
  setPayingAmount: (amount: string) => void;
  receivingToken: TokenMetadata | null;
  setReceivingToken: (token: TokenMetadata | null) => void;
  receivingAmount: string;
  setReceivingAmount: (amount: string) => void;
}
const defaultContextValue: SwapContextProps = {
  inputType: null,
  setInputType: () => {},
  userChangedAmount: false,
  setUserChangedAmount: () => {},
  payingToken: null,
  setPayingToken: () => {},
  payingAmount: '',
  setPayingAmount: () => {},
  receivingToken: null,
  setReceivingToken: () => {},
  receivingAmount: '',
  setReceivingAmount: () => {},
};
// Create the context
const SwapFormPersistenceContext =
  createContext<SwapContextProps>(defaultContextValue);

// Create a provider component
const SwapFormPersistenceProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [inputType, setInputType] = useState<InputType | null>(null);
  const [userChangedAmount, setUserChangedAmount] = useState<boolean>(false);
  const [payingToken, setPayingToken] = useState<TokenMetadata | null>(null);
  const [payingAmount, setPayingAmount] = useState('');
  const [receivingToken, setReceivingToken] = useState<TokenMetadata | null>(
    null,
  );
  const [receivingAmount, setReceivingAmount] = useState('');

  return (
    <SwapFormPersistenceContext.Provider
      value={{
        inputType,
        setInputType,
        userChangedAmount,
        setUserChangedAmount,
        payingToken,
        setPayingToken,
        payingAmount,
        setPayingAmount,
        receivingToken,
        setReceivingToken,
        receivingAmount,
        setReceivingAmount,
      }}
    >
      {children}
    </SwapFormPersistenceContext.Provider>
  );
};

export { SwapFormPersistenceContext, SwapFormPersistenceProvider };
