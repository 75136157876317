import { Validator } from 'checkeasy';
import { useEffect, useState } from 'react';

const returnInitialValue = <S>(initialValueIfNone: S | (() => S)) => {
  if (typeof initialValueIfNone === 'function') {
    // @ts-expect-error - initialValueIfNone is a function
    return initialValueIfNone?.();
  }
  return initialValueIfNone;
};

export const usePersistedState = <S>(
  initialValueIfNone: S | (() => S),
  key: string,
  validate?: Validator<S>,
) => {
  const [value, setValue] = useState<S>(() => {
    const item = localStorage.getItem(key);
    if (item) {
      try {
        if (validate) {
          return validate(JSON.parse(item), key);
        }

        return JSON.parse(item);
      } catch {
        return returnInitialValue(initialValueIfNone);
      }
    }

    return returnInitialValue(initialValueIfNone);
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue] as const;
};
