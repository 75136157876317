import { telegramInitData } from '@/hooks/telegram-web-app/useTelegramWebAppInitData.ts';
import { createTRPCProxyClient, httpBatchLink } from '@trpc/client';
import { AppRouter } from '../../../../types/dialect-api';

const apiUrl = import.meta.env.VITE_API_URL;

export const dialectApiTrpcClient = createTRPCProxyClient<AppRouter>({
  links: [
    httpBatchLink({
      url: `${apiUrl}/wallet-telegram-bot`,
      headers: () => {
        return {
          Authorization: telegramInitData,
        };
      },
    }),
  ],
});
