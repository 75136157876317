import { Nft } from '@/hooks/nfts/types.ts';
import { useNftBuy } from '@/hooks/nfts/useNftBuy.ts';
import { getTotalPrice } from '@/hooks/nfts/utils.ts';
import useWalletSol from '@/hooks/useWalletSol.ts';
import { Button } from '@/shared/components';
import { formatTokenAmount } from '@/shared/utils/formatting.ts';
import { useCallback, useMemo } from 'react';

interface Props {
  nft?: Nft | null;
  isLoading: boolean;
}

export const BuySection = ({ nft, isLoading }: Props) => {
  const listing = nft?.prices[0] ?? null;
  const { token, isWalletTokensLoading } = useWalletSol();
  const { buyNft, isNftBuying } = useNftBuy();

  const userCanPurchase =
    token && listing
      ? token.fractionalBalance >
        getTotalPrice(listing.value, nft?.metadata.royaltyBps ?? 0)
      : false;

  const buttonLabel = useMemo(() => {
    if (!listing) {
      return 'No Listings Available';
    }

    const total = getTotalPrice(listing.value, nft?.metadata.royaltyBps ?? 0);

    return `Buy Now ${formatTokenAmount(total / 10 ** listing.decimals)} ${listing.token.symbol}`;
  }, [listing, nft?.metadata.royaltyBps]);

  const buyOnClick = useCallback(() => {
    if (!userCanPurchase || !listing || !nft) {
      return;
    }

    buyNft(nft, listing);
  }, [buyNft, listing, nft, userCanPurchase]);

  return (
    <div className="rounded-xl border border-primary p-3 flex flex-col items-center gap-2.5">
      <span className="text-caption text-secondary font-medium">
        Zero Dialect fees, Powered by Tensor.
      </span>
      <Button
        variant={
          !listing || !userCanPurchase || isNftBuying ? 'disabled' : 'brand'
        }
        isLoading={isLoading || isWalletTokensLoading || isNftBuying}
        disabled={isLoading || isWalletTokensLoading || !listing || isNftBuying}
        onClick={buyOnClick}
      >
        {buttonLabel}
      </Button>
      {listing && !userCanPurchase && !isWalletTokensLoading && (
        <span className="text-caption text-error">
          Insufficient Balance: {formatTokenAmount(token?.uiAmountBalance ?? 0)}{' '}
          SOL
        </span>
      )}
    </div>
  );
};
