import { TokenSelectModal } from '@/components/TokenSelectModal.tsx';
import { TokenMetadata } from '@/hooks/useTokenMetadataCatalog.ts';
import useTokenPrices from '@/hooks/useTokenPrices.ts';
import useWalletTokens from '@/hooks/useWalletTokens.ts';
import { calculateMaxPossibleWithdrawal } from '@/shared/api/solana/solana-utils.ts';
import PulseLoader from '@/shared/components/PulseLoader.tsx';
import { cn } from '@/shared/utils/cn.ts';
import {
  formatDollarAmount,
  formatTokenAmountPrecise,
} from '@/shared/utils/formatting.ts';
import { InputType } from '@/shared/utils/typing';
import { clsx } from 'clsx';
import { FC, useMemo } from 'react';

interface Props {
  title: string;
  type: InputType;
  options: TokenMetadata[];
  isOptionsLoading?: boolean;
  selectorValue?: TokenMetadata | null;
  onSelectToken: (token: TokenMetadata) => void;
  inputValue: string;
  isInputValueLoading?: boolean;
  inputEnabled: boolean;
  onInputChange?: (amount: string) => void;
  insufficientBalance?: boolean;
  onAmountClick: () => void;
  className?: string;
}

const TokenSwapPairMember: FC<Props> = ({
  className,
  title,
  type,
  options,
  isOptionsLoading,
  selectorValue,
  onSelectToken,
  inputValue,
  isInputValueLoading,
  inputEnabled,
  onInputChange,
  insufficientBalance,
  onAmountClick,
}) => {
  const { walletTokens, isWalletTokensLoading } = useWalletTokens();

  const { tokenPrices } = useTokenPrices(
    selectorValue?.mintAddress ? [selectorValue.mintAddress] : [],
    { includeUnknown: true },
  );
  const balance =
    (selectorValue &&
      walletTokens[selectorValue.mintAddress]?.uiAmountBalance) ??
    0;

  const isInputAllowed = inputEnabled && selectorValue;

  const input = isInputValueLoading ? (
    <PulseLoader className="h-4 w-[100px] rounded-md bg-secondary" />
  ) : inputEnabled ? (
    <input
      autoFocus
      className={clsx(
        'flex w-[130px] min-w-full truncate bg-transparent text-right text-h2 font-bold focus:outline-none focus:ring-0 disabled:bg-transparent',
        {},
      )}
      placeholder="0"
      disabled={!isInputAllowed}
      type="number"
      value={inputValue}
      onChange={(e) => {
        if (!onInputChange) {
          return;
        }
        const val = e.target.value;
        // Allow only positive floats or empty string
        if (val === '' || /^[0-9]*\.?[0-9]*$/.test(val)) {
          onInputChange(val);
        }
      }}
    />
  ) : (
    <span
      onClick={() => {
        onAmountClick();
      }}
      className="flex w-[130px] min-w-full justify-end text-right text-h2 font-bold"
    >
      {formatTokenAmountPrecise(Number(inputValue || '0'))}
    </span>
  );

  const tokenBalance = isWalletTokensLoading ? (
    <PulseLoader className="h-5 w-20 rounded-md text-surface-primary" />
  ) : (
    <div className="flex h-5 flex-row items-center gap-2.5">
      <span
        className={clsx('text-subtext', { 'text-error': insufficientBalance })}
      >
        Balance: {formatTokenAmountPrecise(balance)}
      </span>
      {type === InputType.PAYING && (
        <button
          onClick={() => {
            if (!selectorValue || !onInputChange) {
              return;
            }
            const maxWithdrawal = calculateMaxPossibleWithdrawal(
              balance,
              selectorValue.mintAddress,
            );
            onInputChange(maxWithdrawal.toString());
          }}
          className={clsx(
            'rounded-full border px-1.5 text-[9px] font-bold leading-4 border-b-grey-60',
          )}
        >
          MAX
        </button>
      )}
    </div>
  );

  const inputInUsd = useMemo(() => {
    if (!selectorValue || !tokenPrices) {
      return 0;
    }
    const tokenPrice = tokenPrices[selectorValue.mintAddress];
    if (!tokenPrice) {
      return 0;
    }
    return tokenPrice.price * Number(inputValue);
  }, [selectorValue, tokenPrices, inputValue]);

  const inputHintInUsd = isInputValueLoading ? (
    <PulseLoader className="mt-2 h-3 w-12 rounded-md bg-secondary" />
  ) : (
    <span className="text-caption text-secondary">
      {formatDollarAmount(inputInUsd)}
    </span>
  );

  const selector = isOptionsLoading ? (
    <PulseLoader className="h-10 w-32 rounded-lg bg-secondary" />
  ) : (
    <TokenSelectModal
      options={options}
      selectorValue={selectorValue}
      onSelectToken={onSelectToken}
    />
  );

  return (
    <div className={cn('flex flex-col', className)}>
      <div className="flex flex-row items-center justify-between text-xs text-secondary mb-2">
        <span className="font-semibold text-subtext text-primary">{title}</span>
        {tokenBalance}
      </div>
      <div
        className={clsx(
          'flex flex-row items-center justify-between gap-3 rounded-xl p-2 pr-3 bg-primary border border-primary border-opacity-50 focus-within:border-opacity-100 focus-within:border-brand',
          {
            'border-error': insufficientBalance,
          },
        )}
      >
        {selector}
        <div className="flex flex-col items-end gap-0.5 text-right">
          {input}
          {inputHintInUsd}
        </div>
      </div>
      {selectorValue?.isUnknown && (
        <span className="text-caption text-right text-secondary mt-1 pr-3">
          Prices may be inaccurate due to low liquidity. Trade at your own risk.
        </span>
      )}
    </div>
  );
};

export default TokenSwapPairMember;
