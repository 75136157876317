import useTelegramWebAppUIConfiguration from '@/hooks/useTelegramWebAppUIConfiguration.ts';
import SpinnerIcon from '@/icons/SpinnerIcon.tsx';
import { FC } from 'react';
const LoadingPage: FC = () => {
  useTelegramWebAppUIConfiguration();
  return (
    <div className="flex h-screen w-full flex-col">
      <div className="flex flex-1 items-center justify-center">
        <SpinnerIcon />
      </div>
      <div className="flex flex-1" />
    </div>
  );
};

export default LoadingPage;
