import { isRunningInTelegramWebview } from '@/hooks/telegram-web-app/useTelegramWebApp.ts';
import {
  InitDataUnsafe,
  useInitData,
} from '@vkruglikov/react-telegram-web-app';

export let telegramInitData: string | undefined;

const useTelegramWebAppInitData = () => {
  const [initDataUnsafe, initData] = useInitData();
  telegramInitData = initData;
  if (!isRunningInTelegramWebview) {
    const initDataLocalDev = import.meta.env.VITE_LOCAL_DEV_INIT_DATA as
      | string
      | undefined;
    telegramInitData = initDataLocalDev;
    const initDataUnsafeLocalDev = import.meta.env
      .VITE_LOCAL_DEV_INIT_DATA_UNSAFE as string | undefined;
    return [
      initDataUnsafeLocalDev
        ? (JSON.parse(initDataUnsafeLocalDev) as InitDataUnsafe)
        : undefined,
      initDataLocalDev,
    ] as const;
  }
  return [initDataUnsafe, initData] as const;
};

export default useTelegramWebAppInitData;
