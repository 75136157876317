import useTelegramWebAppStartAppParam from '@/hooks/telegram-web-app/useTelegramWebAppStartAppParam.ts';
import { BackButton } from '@vkruglikov/react-telegram-web-app';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const TelegramWebAppNavigation: FC = () => {
  const location = useLocation();
  const { startAppParam } = useTelegramWebAppStartAppParam();

  const navigate = useNavigate();

  if (
    location.key === 'default' ||
    (startAppParam?.location &&
      location.pathname + location.search === startAppParam?.location)
  ) {
    return null;
  }
  return (
    <BackButton
      onClick={() => {
        console.log('navigate');
        navigate(-1);
      }}
    />
  );
};

export default TelegramWebAppNavigation;
