import birdeyeApi from '@/shared/api/birdseye/api.ts';
import useSWR from 'swr';

export interface TokenMarketData {
  address: string;
  symbol: string;
  name: string;
  marketCap?: number;
  price?: number;
  priceChange30mPercent?: number;
  priceChange1hPercent?: number;
  priceChange2hPercent?: number;
  priceChange4hPercent?: number;
  priceChange6hPercent?: number;
  priceChange8hPercent?: number;
  priceChange12hPercent?: number;
  priceChange24hPercent?: number;
  history24hPrice?: number;
}

interface TokenMarketDataOptions {
  refreshInterval?: number;
}

export const useTokenMarketData = (
  mintAddress: string | undefined,
  { refreshInterval }: TokenMarketDataOptions = {},
) => {
  const { data, isLoading } = useSWR<TokenMarketData | null>(
    mintAddress ? ['TOKEN_MARKET_DATA', mintAddress, refreshInterval] : null,
    async () => {
      if (!mintAddress) {
        return null;
      }

      try {
        const tokenMarketData =
          await birdeyeApi.getTokenMarketData(mintAddress);

        return {
          address: tokenMarketData.address,
          symbol: tokenMarketData.symbol,
          name:
            tokenMarketData.symbol === 'SOL' ? 'Solana' : tokenMarketData.name,
          marketCap: tokenMarketData.mc,
          price: tokenMarketData.price,
          priceChange30mPercent: tokenMarketData.priceChange30mPercent,
          priceChange1hPercent: tokenMarketData.priceChange1hPercent,
          priceChange2hPercent: tokenMarketData.priceChange2hPercent,
          priceChange4hPercent: tokenMarketData.priceChange4hPercent,
          priceChange6hPercent: tokenMarketData.priceChange6hPercent,
          priceChange8hPercent: tokenMarketData.priceChange8hPercent,
          priceChange12hPercent: tokenMarketData.priceChange12hPercent,
          priceChange24hPercent: tokenMarketData.priceChange24hPercent,
          history24hPrice: tokenMarketData.history24hPrice,
        };
      } catch (e) {
        return null;
      }
    },
    { refreshInterval },
  );

  return {
    marketData: data,
    isLoading,
  };
};
