import { Nft } from '@/hooks/nfts/types.ts';
import { getMarketplacesLowestPrice } from '@/hooks/nfts/utils.ts';
import { Card } from '@/shared/components';

interface Props {
  nft: Nft;
}

export const NftCard = ({ nft }: Props) => {
  return (
    <Card
      blurhash={nft.previews?.blurhash}
      img={nft.previews?.medium ?? nft.img.url}
      title={nft.name}
      subtitle={getMarketplacesLowestPrice(nft.prices)}
    />
  );
};
