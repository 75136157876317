import { ViewportFlex } from '@/components/ViewportFlex.tsx';
import { useTokenDetails } from '@/hooks/token-swap/useTokenDetails.ts';
import { useTokenMarketData } from '@/hooks/useTokenMarketData.ts';
import { useTokenPriceHistory } from '@/hooks/useTokenPriceHistory.ts';
import { BirdeyePriceHistoryType } from '@/shared/api/birdseye/types.ts';
import { Chart, TokenLogo } from '@/shared/components';
import {
  formatPercentage,
  getFormattedTokenPrice,
} from '@/shared/utils/formatting.ts';
import { countLeadingDecimalZeros } from '@/shared/utils/math.ts';
import { shortenAddress } from '@/shared/utils/wallet.ts';
import { clsx } from 'clsx';
import { UTCTimestamp } from 'lightweight-charts';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const TokenInfo = () => {
  const { mintAddress } = useParams<{ mintAddress: string }>();

  if (!mintAddress) {
    return null;
  }

  return <InnerTokenInfo mintAddress={mintAddress} />;
};

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  timeZone: 'UTC',
  hour: 'numeric',
  minute: 'numeric',
  day: 'numeric',
  month: 'numeric',
});

const InnerTokenInfo = ({ mintAddress }: { mintAddress: string }) => {
  const { token, isTokenLoading } = useTokenDetails(mintAddress);
  const { marketData } = useTokenMarketData(mintAddress);
  const { priceHistory, from, to } = useTokenPriceHistory(mintAddress, {
    timeFrom: 'day-before',
    timeTo: 'now',
    type: BirdeyePriceHistoryType.FIFTEEN_MINUTES,
    refreshInterval: 0,
  });

  const change = marketData?.priceChange24hPercent;

  const chartData = useMemo(() => {
    return priceHistory.map((price) => {
      return {
        time: price.unixTime as UTCTimestamp,
        value: price.value,
      };
    });
  }, [priceHistory]);

  const minMove = useMemo(() => {
    if (!marketData?.price) {
      return 0.01;
    }

    const zeros = countLeadingDecimalZeros(marketData.price);

    if (zeros > 0) {
      return 1 / 10 ** (countLeadingDecimalZeros(marketData.price) + 3); // add 2 decimal points
    }

    return 0.01;
  }, [marketData?.price]);

  return (
    <ViewportFlex>
      <main className="p-3 w-full">
        <header className="flex flex-row gap-2 w-full items-center">
          <div className="h-8 w-8">
            <TokenLogo imageUrl={token?.imageUrl} isLoading={isTokenLoading} />
          </div>
          <div className="flex flex-col flex-1">
            <span className="text-subtext font-semibold">
              {token?.name ?? shortenAddress(mintAddress)}
            </span>
            <span className="text-caption font-medium text-secondary">
              {token?.symbol ?? 'UNKNOWN'}
            </span>
          </div>
          <div className="flex flex-col items-end">
            <span className="text-text font-semibold">
              {getFormattedTokenPrice(marketData?.price)}
            </span>
            <span
              className={clsx('text-caption font-semibold', {
                ['text-error']: change && change < 0,
                ['text-success']: change && change > 0,
                ['text-primary']: !change || change === 0,
              })}
            >
              {typeof change === 'number'
                ? `${change > 0 ? '+' : ''}${formatPercentage((change / 100).toString())}`
                : 'N/A'}
            </span>
          </div>
        </header>
        <section className="mt-4">
          <Chart
            data={chartData}
            minStep={minMove}
            type="minimalistic"
            height={90}
          />
          {from && to && (
            <div className="flex flex-row justify-between">
              <span className="text-[8px] text-tertiary">
                {dateFormatter.format(from * 1000)} UTC
              </span>
              <span className="text-[8px] text-grey-30">
                &mdash; <span className="font-semibold text-tertiary">24h</span>{' '}
                &mdash;
              </span>
              <span className="text-[8px] text-tertiary">
                {dateFormatter.format(to * 1000)} UTC
              </span>
            </div>
          )}
        </section>
      </main>
    </ViewportFlex>
  );
};
