import { clsx } from 'clsx';
import { memo, useState } from 'react';
import { Blurhash } from 'react-blurhash';

const ImageWithBlurHash = memo(function ImageWithBlurHash({
  blurhash,
  url,
  alt,
  imageClassName,
}: {
  blurhash?: string;
  url: string;
  alt?: string;
  imageClassName?: string;
}) {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <>
      {blurhash && !isLoaded && (
        <Blurhash hash={blurhash} width="100%" height="100%" />
      )}
      <img
        src={url}
        alt={alt}
        className={clsx(
          imageClassName,
          blurhash
            ? {
                hidden: !isLoaded,
              }
            : {},
        )}
        onLoad={() => setIsLoaded(true)}
      />
    </>
  );
});

export default ImageWithBlurHash;
