import { logEvent } from '@/analytics.ts';
import DialectLogo from '@/icons/DialectLogo.tsx';
import { SpinnerDots } from '@/icons/SpinnerDots.tsx';
import miniAppApi from '@/shared/api/dialect/mini-app-api.ts';
import DialectOperatorLogo from '@/shared/images/dialect-operator-logo.png';
import { setUserId } from '@amplitude/analytics-browser';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { captureException } from '@sentry/react';
import { FC, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const TelegramButton = () => {
  return (
    <button
      className="border px-4 py-3 flex flex-row items-center gap-1.5 text-primary rounded-xl"
      onClick={() => {
        window.location.replace(`tg://resolve`);
        setTimeout(() => {
          window.close();
        }, 2000);
      }}
    >
      <FontAwesomeIcon icon={faTelegram} />
      <span className="text-text/5">Take me back to Telegram</span>
      <FontAwesomeIcon icon={faArrowRight} />
    </button>
  );
};
const ConnectWalletPhantomRedirectPage: FC = () => {
  const [queryParams] = useSearchParams();

  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const connectPhantomWallet = async () => {
      setIsConnecting(true);
      try {
        // Handle an error response from Phantom
        if (queryParams.get('errorCode')) {
          const error = Object.fromEntries([...queryParams]);
          const message =
            error?.errorMessage ??
            JSON.stringify(Object.fromEntries([...queryParams]), null, 2);
          setErrorMessage(message);
          return;
        }

        const phantomEncryptionPublicKey = queryParams.get(
          'phantom_encryption_public_key',
        );
        const nonce = queryParams.get('nonce');
        const data = queryParams.get('data');

        if (!phantomEncryptionPublicKey || !nonce || !data) {
          return;
        }

        const response = await miniAppApi.phantom.connectWallet({
          phantomEncryptionPublicKey,
          nonce,
          data,
        });

        setUserId(response.userId);
        logEvent('connect_wallet', {
          status: 'success',
          wallet_provider: 'phantom',
          wallet_address: response.walletAddress,
        });

        setIsWalletConnected(true);
      } catch (e) {
        captureException(e);
      } finally {
        setIsConnecting(false);
      }
    };

    connectPhantomWallet();

    // query params
  }, [queryParams]);

  const header = (
    <div className="absolute top-16 w-full flex justify-center text-primary">
      <DialectLogo />
    </div>
  );

  const content = useCallback(() => {
    if (isConnecting)
      return (
        <div className="flex flex-col items-center justify-center text-primary">
          <SpinnerDots />
          <span className="mt-2 text-text">Loading...</span>
        </div>
      );

    return (
      <div className="flex flex-col items-center justify-center gap-8">
        <div className="flex flex-col items-center">
          {isWalletConnected ? (
            <>
              <div className="w-24 h-24 flex items-center justify-center mb-2">
                <img src={DialectOperatorLogo} alt="logo" />
              </div>
              <span className="text-[28px] font-bold text-primary">
                You&rsquo;re all set!
              </span>
            </>
          ) : (
            <>
              <div className="w-24 h-24 flex items-center justify-center mb-2">
                <img src={DialectOperatorLogo} alt="logo" />
              </div>
              <span className="text-[28px] font-bold text-primary">
                Wallet connection failed
              </span>
              <span className="text-subtext text-primary">
                Please go to Telegram and try again.
              </span>
              {errorMessage && (
                <span className="text-subtext text-primary/60">
                  Error: {errorMessage}
                </span>
              )}
            </>
          )}
        </div>
        <TelegramButton />
      </div>
    );
  }, [errorMessage, isConnecting, isWalletConnected]);

  return (
    <div className="flex flex-col justify-center h-screen relative">
      {content()}
      {header}
    </div>
  );
};

export default ConnectWalletPhantomRedirectPage;
