import { Collection, Nft } from '@/hooks/nfts/types.ts';
import { PulseLoader } from '@/shared/components';

interface Props {
  nft?: Nft | null;
  collection?: Collection | null;
  isLoading: boolean;
}

export const Header = ({ nft, collection, isLoading }: Props) => {
  return isLoading || !nft || !collection ? (
    <PulseHeader />
  ) : (
    <header className="flex">
      <div className="flex flex-col flex-1">
        <span className="text-caption text-secondary">{collection.name}</span>
        <span className="font-semibold">{nft.name}</span>
      </div>
    </header>
  );
};

export const PulseHeader = () => {
  return (
    <header className="flex">
      <div className="flex flex-col flex-1">
        <PulseLoader className="bg-secondary h-[0.6875rem] w-20 rounded-md my-[0.1875rem]" />
        <PulseLoader className="bg-secondary h-[0.9375rem] w-32 rounded-md my-[0.1875rem]" />
      </div>
    </header>
  );
};
