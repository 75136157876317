import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M8 0c.531 0 1.031.063 1.531.156.25.063.688.188.938.625.062.125.094.25.125.375l.312 1.219c.031.156.344.344.531.281l1.188-.344c.125-.03.25-.062.375-.062.5 0 .844.313 1 .5a7.988 7.988 0 0 1 1.563 2.688c.093.25.187.687-.094 1.093-.063.125-.156.219-.25.313l-.875.875c-.156.125-.156.468 0 .593l.875.876c.094.093.187.187.25.312.25.406.156.844.094 1.094A7.987 7.987 0 0 1 14 13.28c-.156.188-.5.5-1 .5-.125 0-.25-.031-.375-.062l-1.188-.375c-.187-.031-.5.125-.53.312l-.313 1.219c-.031.125-.063.25-.125.375-.25.438-.688.563-.938.625-.5.094-1 .125-1.531.125a8.549 8.549 0 0 1-1.563-.125c-.25-.063-.687-.188-.937-.625-.063-.125-.094-.25-.125-.375l-.313-1.219c-.03-.187-.343-.344-.5-.312l-1.187.375c-.125.031-.281.031-.406.062-.5 0-.844-.312-1-.5a7.988 7.988 0 0 1-1.563-2.687C.344 10.344.25 9.906.5 9.469a2.19 2.19 0 0 1 .25-.313l.906-.844c.125-.124.125-.468 0-.593L.75 6.844C.656 6.75.562 6.656.5 6.53.25 6.125.344 5.688.406 5.438c.344-1 .875-1.907 1.563-2.688.156-.188.5-.5 1-.5.125 0 .281.031.406.063l1.188.343c.156.063.468-.125.5-.281l.312-1.219c.031-.125.063-.25.125-.375.25-.437.688-.562.938-.625A8.544 8.544 0 0 1 8 0ZM6.812 1.625l-.28 1.094c-.25 1-1.407 1.687-2.407 1.406l-1.094-.344a7.541 7.541 0 0 0-1.187 2.063l.843.781c.75.719.75 2.063 0 2.781l-.843.781c.281.75.687 1.438 1.187 2.063l1.094-.344c1-.281 2.156.406 2.406 1.406l.282 1.094c.75.156 1.593.156 2.375 0l.25-1.094c.25-1 1.406-1.687 2.406-1.406l1.094.344a7.54 7.54 0 0 0 1.187-2.063l-.813-.78c-.75-.72-.75-2.063 0-2.782l.813-.781a7.54 7.54 0 0 0-1.188-2.063l-1.093.344c-.969.281-2.156-.406-2.406-1.406l-.25-1.094a6.075 6.075 0 0 0-2.376 0ZM6.5 8c0 .563.281 1.031.75 1.313.438.28 1.031.28 1.5 0 .438-.282.75-.75.75-1.313 0-.531-.313-1-.75-1.281-.469-.282-1.063-.282-1.5 0C6.781 7 6.5 7.469 6.5 8ZM8 11a3.03 3.03 0 0 1-2.625-1.5c-.531-.906-.531-2.063 0-3C5.938 5.594 6.906 5 8 5c1.063 0 2.031.594 2.594 1.5.531.938.531 2.094 0 3C10.03 10.438 9.063 11 8 11Z"
    />
  </svg>
);
export default SvgComponent;
