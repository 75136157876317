import { logEvent } from '@/analytics.ts';
import useTelegramWebApp from '@/hooks/telegram-web-app/useTelegramWebApp.ts';
import useTelegramWebAppInitData from '@/hooks/telegram-web-app/useTelegramWebAppInitData.ts';
import miniAppApi from '@/shared/api/dialect/mini-app-api.ts';
import { useState } from 'react';
import useSWR from 'swr';
import {
  CreateTransactionResponse,
  SmartMessageResponse,
} from '../../types/smart-message-service-api/lib/types';

interface UseSmartMessageArgs {
  id?: string;
}

interface UseSmartMessageValue {
  smartMessage?: SmartMessageResponse;
  handleSmartMessageAction: (actionHumanReadableId: string) => Promise<void>;
  isExecutingSmartMessageAction: boolean;
}

const useSmartMessage = ({ id }: UseSmartMessageArgs): UseSmartMessageValue => {
  const [_, initData] = useTelegramWebAppInitData();

  const [isExecutingAction, setIsExecutingAction] = useState(false);
  const [actionError, setActionError] = useState<string | null>(null);

  const { openLink } = useTelegramWebApp();

  const {
    data: smartMessage,
    isLoading,
    mutate,
  } = useSWR(
    id ? ['SMART_MESSAGE', id] : null,
    async () => {
      if (!initData || !id) {
        console.log('No initData or id', initData, id);
        return;
      }

      const smartMessage: SmartMessageResponse =
        await miniAppApi.smartMessage.getSmartMessage.mutate({
          smartMessageId: id,
          initData,
        });

      return smartMessage;
    },
    {
      refreshInterval: 3000,
      refreshWhenHidden: true,
      refreshWhenOffline: true,
    },
  );

  const handleSmartMessageAction = async (actionHumanReadableId: string) => {
    if (!smartMessage || !initData) {
      return;
    }
    setIsExecutingAction(true);
    setActionError(null);
    try {
      logEvent('smart_message', {
        status: 'action_click',
        smart_message_id: smartMessage.id,
        action_id: actionHumanReadableId,
      });
      const tx: CreateTransactionResponse =
        await miniAppApi.smartMessage.createTransaction.mutate({
          smartMessageId: smartMessage.id,
          actionHumanReadableId: actionHumanReadableId,
          initData,
        });
      logEvent('smart_message', {
        status: 'transaction_create',
        smart_message_id: smartMessage.id,
        action_id: actionHumanReadableId,
      });
      const { deeplink } = await miniAppApi.phantom.prepareSignTx.mutate({
        initData,
        serializedTx: tx.transaction,
        metadata: {
          type: 'smart_message',
          smartMessage: {
            id: smartMessage.id,
            action: {
              humanReadableId: actionHumanReadableId,
              transaction: {
                message: tx.message,
              },
            },
          },
        },
      });
      logEvent('smart_message', {
        status: 'sign_tx_deeplink_create',
        smart_message_id: smartMessage.id,
        action_id: actionHumanReadableId,
        wallet_provider: 'phantom',
      });

      openLink(deeplink);
      // mutate();
      // optimisticallySetExecuting();
    } catch (e) {
      setActionError('Failed to handle action');
    } finally {
      setIsExecutingAction(false);
    }
  };

  return {
    smartMessage,
    handleSmartMessageAction: handleSmartMessageAction,
    isExecutingSmartMessageAction: isExecutingAction,
  };
};

export default useSmartMessage;
