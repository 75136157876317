import useTelegramWebApp from '@/hooks/telegram-web-app/useTelegramWebApp.ts';
import { useEffect } from 'react';

const useTelegramWebAppUIConfiguration = () => {
  const { setHeaderColor } = useTelegramWebApp();

  useEffect(() => {
    try {
      setHeaderColor('#fff'); // this technically needs to be a key
    } catch (e) {
      console.warn('failed to set telegram web app header', e);
    }
  }, []);
};

export default useTelegramWebAppUIConfiguration;
