import { SVGProps } from 'react';

function SvgComponent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      {...props}
    >
      <path
        opacity={0.4}
        d="M12.5 24.5a12 12 0 100-24 12 12 0 000 24zm0-18c.623 0 1.125.502 1.125 1.125v5.25c0 .623-.502 1.125-1.125 1.125a1.122 1.122 0 01-1.125-1.125v-5.25c0-.623.502-1.125 1.125-1.125zM11 17a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"
        fill="#737780"
      />
    </svg>
  );
}

export default SvgComponent;
