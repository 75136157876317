import useTelegramWebAppInitData from '@/hooks/telegram-web-app/useTelegramWebAppInitData.ts';
import { Buffer } from 'buffer';

export interface UseTelegramStartAppParamValue {
  startAppParamRaw?: string;
  startAppParam?: StartAppParam | null;
}

export interface StartAppParam {
  chatId: string;
  location?: string;
}

const useTelegramWebAppStartAppParam = (): UseTelegramStartAppParamValue => {
  const [initDataUnsafe, initData] = useTelegramWebAppInitData();

  return {
    startAppParamRaw: initDataUnsafe?.start_param && initDataUnsafe.start_param,
    startAppParam: initDataUnsafe?.start_param
      ? decodeWebAppStartParam(initDataUnsafe.start_param)
      : null,
  };
};

function decodeWebAppStartParam(
  base64urlEncodedParam: string,
): StartAppParam | null {
  try {
    const base64 = base64urlEncodedParam.replace(/-/g, '+').replace(/_/g, '/');
    const jsonString = Buffer.from(base64, 'base64').toString('utf-8');
    return JSON.parse(jsonString);
  } catch (error) {
    console.error('Error decoding start parameter:', error);
    return null;
  }
}

export default useTelegramWebAppStartAppParam;
