import useTelegramWebApp from '@/hooks/telegram-web-app/useTelegramWebApp.ts';
import useTelegramWebAppShowPopup from '@/hooks/telegram-web-app/useTelegramWebAppShowPopup.ts';
import { useTokenDetails } from '@/hooks/token-swap/useTokenDetails.ts';
import { useTokenMarketData } from '@/hooks/useTokenMarketData.ts';
import { TokenDetailsContext } from '@/pages/TokenDetailsPage/model.ts';
import { PulseLoader, TokenLogo } from '@/shared/components';
import BirdeyeLogo from '@/shared/images/birdeye-logo.png';
import {
  formatDollarAmount,
  formatPercentage,
  getFormattedTokenPrice,
} from '@/shared/utils/formatting.ts';
import { shortenAddress } from '@/shared/utils/wallet.ts';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { faClone } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsx } from 'clsx';
import { useCallback, useContext, useMemo } from 'react';

export const TokenMarketInfo = () => {
  const { mintAddress } = useContext(TokenDetailsContext);
  const showPopup = useTelegramWebAppShowPopup();
  const { openLink } = useTelegramWebApp();
  const { marketData, isLoading: isMarketDataLoading } = useTokenMarketData(
    mintAddress,
    {
      refreshInterval: 10000,
    },
  );

  const { token, isTokenLoading } = useTokenDetails(mintAddress);

  const percentageChanges = useMemo(() => {
    return [
      { label: '30m', value: marketData?.priceChange30mPercent },
      { label: '1h', value: marketData?.priceChange1hPercent },
      { label: '8h', value: marketData?.priceChange8hPercent },
      { label: '24h', value: marketData?.priceChange24hPercent },
    ];
  }, [marketData]);

  const copyContractAddress = useCallback(async () => {
    await navigator.clipboard.writeText(mintAddress);
    await showPopup({
      message: 'Contract address copied to clipboard',
    });
  }, [mintAddress, showPopup]);

  const learnMore = useCallback(() => {
    if (!token) {
      return;
    }

    openLink(token.infoLinks.birdeye);
  }, [openLink, token]);

  return (
    <section className="w-full">
      {/* Header */}
      <div className="flex flex-row items-center gap-3 py-4">
        <div className="w-5 h-5">
          <TokenLogo imageUrl={token?.imageUrl} isLoading={isTokenLoading} />
        </div>
        <div className="flex-1 flex items-center">
          {isTokenLoading ? (
            <PulseLoader className="h-[0.9375rem] w-32 bg-secondary rounded-lg" />
          ) : (
            <h5 className="text-text font-semibold">
              {token?.symbol ?? 'UNKNOWN'} Market Info
            </h5>
          )}
        </div>
        <div>
          <span className="text-text font-semibold flex">
            {isMarketDataLoading ? (
              <PulseLoader className="h-[0.9375rem] w-10 bg-secondary rounded-md" />
            ) : (
              getFormattedTokenPrice(marketData?.price)
            )}
          </span>
        </div>
      </div>
      {/* Price changes */}
      <div className="overflow-hidden rounded-lg w-full mb-3">
        <div className="flex flex-row gap-0.5 h-[30px]">
          {isMarketDataLoading
            ? percentageChanges.map((change) => (
                <PulseLoader
                  key={change.label}
                  className="bg-secondary flex-1"
                />
              ))
            : percentageChanges.map((change) => (
                <div
                  key={change.label}
                  className={clsx(
                    'flex flex-row flex-1 px-2 items-center justify-between',
                    {
                      ['bg-error/10 text-error']:
                        change.value && change.value < 0,
                      ['bg-success/10 text-success']:
                        change.value && change.value > 0,
                      ['bg-secondary text-primary']:
                        !change.value || change.value === 0,
                    },
                  )}
                >
                  <span className="text-caption text-secondary font-medium">
                    {change.label}
                  </span>
                  <span className="text-caption font-semibold">
                    {typeof change.value === 'number'
                      ? formatPercentage((change.value / 100).toString())
                      : 'N/A'}
                  </span>
                </div>
              ))}
        </div>
      </div>
      {/* Rest of info */}
      <div className="flex flex-col gap-3">
        <div className="flex flex-row items-center justify-between">
          <span className="text-caption text-secondary font-medium flex">
            FDMC
          </span>
          {isMarketDataLoading ? (
            <PulseLoader className="w-8 h-[0.6875rem] rounded-md bg-secondary" />
          ) : (
            <span className="text-caption font-semibold flex">
              {typeof marketData?.marketCap === 'number'
                ? formatDollarAmount(marketData.marketCap)
                : 'N/A'}
            </span>
          )}
        </div>
        <div className="flex flex-row items-center justify-between">
          <span className="text-caption text-secondary font-medium flex">
            Contract
          </span>
          <button
            className="flex flex-row items-center text-caption font-semibold gap-1"
            onClick={copyContractAddress}
          >
            {shortenAddress(mintAddress)}
            <FontAwesomeIcon icon={faClone} />
          </button>
        </div>
        {token && (
          <div className="flex flex-row items-center justify-end">
            <button
              className="flex flex-row items-center text-caption text-secondary font-medium gap-1"
              onClick={learnMore}
            >
              <img
                src={BirdeyeLogo}
                alt="Birdeye Logo"
                className="w-4 h-4 -mb-0.5"
              />
              Learn More
              <FontAwesomeIcon icon={faChevronRight} size="sm" />
            </button>
          </div>
        )}
      </div>
    </section>
  );
};
