import useTokenMetadataCatalog, {
  TokenMetadata,
} from '@/hooks/useTokenMetadataCatalog.ts';
import useWalletTokens from '@/hooks/useWalletTokens.ts';
import { useMemo } from 'react';

export interface UseTokenSwapOptionsValue {
  ownedTokenOptions: TokenMetadata[];
  allTokenOptions: TokenMetadata[];
  isOwnedTokenOptionsLoading: boolean;
  isTokensLoading: boolean;
}

const byPriority = (a: { showPriority: number }, b: { showPriority: number }) =>
  b.showPriority - a.showPriority;

const useTokenSwapOptions = (): UseTokenSwapOptionsValue => {
  const { tokenMetadata, isTokensLoading } = useTokenMetadataCatalog();
  const { walletTokens, isWalletTokensLoading } = useWalletTokens();

  const payingOptions = useMemo(() => {
    return Object.values(walletTokens)
      .map((it) => ({ ...it.metadata, balance: it.uiAmountBalance }))
      .sort(byPriority);
  }, [walletTokens]);

  const allOptions = useMemo(() => {
    return [
      ...payingOptions,
      ...Object.values(tokenMetadata)
        .filter((it) => !walletTokens[it.mintAddress])
        .sort(byPriority),
    ];
  }, [payingOptions, tokenMetadata, walletTokens]);

  return {
    isTokensLoading,
    ownedTokenOptions: payingOptions,
    isOwnedTokenOptionsLoading: isWalletTokensLoading,
    allTokenOptions: allOptions,
  };
};

export default useTokenSwapOptions;
