import {
  NormalizedJupiterQuote,
  NormalizedRaydiumQuote,
  Quote,
  QuoteProvider,
} from '@/hooks/token-swap/types.ts';
import { TokenMetadata } from '@/hooks/useTokenMetadataCatalog.ts';
import jupiterApi from '@/shared/api/jupiter-api.ts';
import raydiumApi, { RaydiumQuote } from '@/shared/api/solana/raydium-api.ts';
import { QuoteResponse } from '@jup-ag/api';

type QuoteProviderFn = (
  token: TokenMetadata,
  otherToken: TokenMetadata,
  amount: number,
  slippageBps: number,
  swapMode: 'ExactIn' | 'ExactOut',
) => Promise<{ quote: Quote; quoteOutAmountUi: number }>;

export const jupiterQuoteProvider: QuoteProviderFn = async (
  token,
  otherToken,
  amount,
  slippageBps,
  swapMode,
) => {
  const quote = await jupiterApi
    .quoteGet({
      inputMint: token.mintAddress,
      outputMint: otherToken.mintAddress,
      amount: amount,
      slippageBps,
      swapMode,
    })
    .then(normalizeJupiterQuote);

  // TODO: cleanup, since this is no longer only OutAmount
  const finalOutAmount =
    swapMode === 'ExactIn' ? quote.outAmount : quote.inAmount;

  const outputDecimals =
    swapMode === 'ExactIn' ? otherToken.decimals : token.decimals;
  const quoteOutAmountUi = Number(finalOutAmount) / 10 ** outputDecimals; // TODO: use bignum

  return { quote, quoteOutAmountUi };
};

export const raydiumQuoteProvider: QuoteProviderFn = async (
  token,
  otherToken,
  amount,
  slippageBps,
  swapMode,
) => {
  const quote = await raydiumApi
    .composeQuote(
      token.mintAddress,
      otherToken.mintAddress,
      amount,
      slippageBps,
      swapMode,
    )
    .then(normalizeRaydiumQuote);

  return {
    quote,
    quoteOutAmountUi: Number(
      swapMode === 'ExactIn' ? quote.outAmount : quote.inAmount,
    ),
  };
};

const normalizeJupiterQuote = (
  quote: QuoteResponse,
): NormalizedJupiterQuote => ({
  provider: QuoteProvider.JUPITER,
  inMint: quote.inputMint,
  inAmount: quote.inAmount,
  outMint: quote.outputMint,
  outAmount: quote.outAmount,
  priceImpactPct: quote.priceImpactPct,
  originalQuote: quote,
});

const normalizeRaydiumQuote = (
  quote: RaydiumQuote,
): NormalizedRaydiumQuote => ({
  provider: QuoteProvider.RAYDIUM,
  inMint: quote.tokenInMintPk,
  inAmount: quote.tokenAmountIn.toSignificant(),
  outMint: quote.tokenOutMintPk,
  outAmount: quote.tokenAmountOut.toSignificant(),
  priceImpactPct: quote.quote.priceImpact.mul(1).toSignificant(5),
  originalQuote: quote,
});
