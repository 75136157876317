import {
  formatDollarAmount,
  formatTokenPriceWithSubscripts,
} from '@/shared/utils/formatting.ts';
import {
  AreaData,
  AreaSeriesPartialOptions,
  ChartOptions,
  ColorType,
  DeepPartial,
  IChartApi,
  ISeriesApi,
  SeriesMarker,
  TickMarkType,
  UTCTimestamp,
  createChart,
} from 'lightweight-charts';
import { useEffect, useMemo, useRef } from 'react';

interface Props {
  data: AreaData<UTCTimestamp>[];
  markers?: SeriesMarker<UTCTimestamp>[];
  colors?: {
    backgroundColor?: string;
    lineColor?: string;
    textColor?: string;
    areaTopColor?: string;
    areaBottomColor?: string;
  };
  aspectRatio?: number;
  height?: number;
  minStep?: number;
  type?: 'regular' | 'minimalistic';
}

const Chart = (props: Props) => {
  const {
    type = 'regular',
    data,
    markers,
    colors: {
      backgroundColor = 'white',
      lineColor = '#007AFF',
      textColor = '#737780',
      areaTopColor = '#007AFF4D',
      areaBottomColor = '#007AFF00',
    } = {},
    aspectRatio = 16 / 9,
    height,
    minStep,
  } = props;

  const chartContainerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<IChartApi>();
  const seriesRef = useRef<ISeriesApi<'Area', UTCTimestamp>>();

  const chartOptions: DeepPartial<ChartOptions> = useMemo(
    () => ({
      localization: {
        locale: navigator.language,
        timeFormatter: (time: UTCTimestamp) => {
          const date = new Date(time * 1000);
          return date.toLocaleTimeString(navigator.language, {
            day: 'numeric',
            month: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          });
        },
      },
      layout: {
        background: { type: ColorType.Solid, color: backgroundColor },
        textColor,
        fontSize: 11, // caption fontsize
      },
      handleScale: false, // TODO: improve to enable scaling and scrolling
      handleScroll: false,
      rightPriceScale: {
        borderVisible: false,
        visible: type === 'regular',
        scaleMargins: {
          top: 0.1,
          bottom: 0,
        },
      },
      timeScale: {
        visible: type === 'regular',
        timeVisible: true,
        borderVisible: false,
        allowBoldLabels: false,
        tickMarkFormatter: (
          time: UTCTimestamp,
          tickMarkType: TickMarkType,
          locale: string,
        ) => {
          if (tickMarkType === TickMarkType.DayOfMonth) {
            const date = new Date(time * 1000);
            return date.toLocaleDateString(locale, {
              day: 'numeric',
              month: 'short',
            });
          }
          if (tickMarkType === TickMarkType.Time) {
            const date = new Date(time * 1000);
            return date.toLocaleTimeString(locale, {
              hour: 'numeric',
              minute: 'numeric',
            });
          }
          return null;
        },
      },
      grid: {
        vertLines: {
          visible: false,
        },
        horzLines: {
          visible: false,
        },
      },
    }),
    [backgroundColor, textColor, type],
  );

  const seriesOptions: AreaSeriesPartialOptions = useMemo(
    (): AreaSeriesPartialOptions => ({
      lineColor,
      topColor: areaTopColor,
      bottomColor: areaBottomColor,
      lineWidth: 2,
      crosshairMarkerBackgroundColor: '#000',
      priceFormat: {
        type: 'custom',
        minMove: minStep,
        formatter: (price: number) => {
          if (price < 0.001) {
            return formatTokenPriceWithSubscripts(price);
          }

          return formatDollarAmount(price);
        },
      },
    }),
    [areaBottomColor, areaTopColor, lineColor, minStep],
  );

  useEffect(function initChart() {
    if (!chartContainerRef.current) {
      return;
    }

    chartRef.current = createChart(chartContainerRef.current);
    chartRef.current.timeScale().fitContent();

    const newSeries = chartRef.current.addAreaSeries();

    seriesRef.current = newSeries as ISeriesApi<'Area', UTCTimestamp>;

    return () => {
      chartRef.current?.remove();
    };
  }, []);

  useEffect(
    function configChart() {
      chartRef.current?.applyOptions(chartOptions);
      seriesRef.current?.applyOptions(seriesOptions);
    },
    [chartOptions, seriesOptions],
  );

  useEffect(
    function updateData() {
      if (data.length > 0) {
        seriesRef.current?.setData(data);
        chartRef.current?.timeScale().fitContent();
      }

      if (markers?.length) {
        // this is to prevent the chart from jumping when adding markers
        seriesRef.current?.priceScale().applyOptions({
          autoScale: false,
        });
        seriesRef.current?.setMarkers(markers);
      }
    },
    [data, markers],
  );

  return (
    <div
      ref={chartContainerRef}
      style={{ width: '100%', height: height, aspectRatio: aspectRatio }}
    />
  );
};

Chart.displayName = 'Chart';

export default Chart;
