import { Connection, PublicKey, TokenAccountsFilter } from '@solana/web3.js';

const rpcUrl = import.meta.env.VITE_SOLANA_RPC_URL;
export const solanaConnection = new Connection(rpcUrl, 'confirmed');

export interface WalletToken {
  mintAddress: string;
  uiAmountBalance: number;
  fractionalBalance: number;
  decimals: number;
  isSOL: boolean;
}

export async function getSolWalletToken(userWalletAddress: string) {
  const solFractionalBalance = await solanaConnection.getBalance(
    new PublicKey(userWalletAddress),
  );
  const solDecimals = 1e9;
  const solUiAmountBalance = solFractionalBalance / solDecimals;
  return {
    mintAddress: 'So11111111111111111111111111111111111111112', // wrapped sol mint address
    uiAmountBalance: solUiAmountBalance,
    fractionalBalance: solFractionalBalance,
    decimals: solDecimals,
    isSOL: true,
  } as WalletToken;
}

export async function listWalletFungibleTokens(
  userWalletAddress: string,
): Promise<WalletToken[]> {
  const walletPublicKey = new PublicKey(userWalletAddress);

  // Fetch SOL balance
  const solToken = await getSolWalletToken(userWalletAddress);

  // Filter for SPL Token accounts
  const tokenAccountsFilter: TokenAccountsFilter = {
    programId: new PublicKey('TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA'),
  };
  const splTokenAccounts = await solanaConnection.getParsedTokenAccountsByOwner(
    walletPublicKey,
    tokenAccountsFilter,
  );
  const splTokens = splTokenAccounts.value
    .map((accountInfo) => {
      const accountData = accountInfo.account.data.parsed.info;
      const tokenAmount = accountData.tokenAmount;

      return {
        mintAddress: accountData.mint,
        uiAmountBalance: +tokenAmount.uiAmountString,
        fractionalBalance: +tokenAmount.amount,
        decimals: tokenAmount.decimals,
        isSOL: false,
      };
    })
    .filter((token) => {
      const isNFT = token.decimals === 0 && token.uiAmountBalance === 1;
      return !isNFT;
    })
    .filter((token) => token.uiAmountBalance > 0); // hide all zero balance coins
  return [solToken, ...splTokens];
}
