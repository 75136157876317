import useTokenMetadataCatalog from '@/hooks/useTokenMetadataCatalog.ts';
import useTokenMetadataFallback from '@/hooks/useTokenMetadataFallback.ts';
import useWalletTokenAlerts from '@/hooks/useWalletTokenAlerts.ts';
import useWalletTokenPrices from '@/hooks/useWalletTokenPrices.ts';
import useWalletTokensMarketData from '@/hooks/useWalletTokensMarketData.ts';
import { TokenBalanceCard } from '@/pages/WalletPage/ui/TokenBalanceCard.tsx';
import { WalletToken } from '@/shared/api/solana/solana-api.ts';
import PulseLoader from '@/shared/components/PulseLoader.tsx';
import { shortenAddress } from '@/shared/utils/wallet.ts';
import { useMemo } from 'react';

export const TokensList = ({
  tokens,
  isLoading: isTokensLoading,
}: {
  tokens: WalletToken[];
  isLoading?: boolean;
}) => {
  const { tokenMetadata } = useTokenMetadataCatalog();
  const { tokenPrices, isTokenPricesLoading } = useWalletTokenPrices();
  const { walletTokensAlerts } = useWalletTokenAlerts();
  const { tokensMarketData } = useWalletTokensMarketData();

  const { tokenMetadata: fallbackTokenMetadata } = useTokenMetadataFallback(
    tokens
      .filter((token) => !tokenMetadata[token.mintAddress])
      .map((token) => token.mintAddress),
  );

  const isLoading = isTokensLoading || isTokenPricesLoading;

  const sortedTokensWithBalance = useMemo(
    () =>
      tokens
        .map((token) => {
          const tokenMarketData = tokensMarketData?.[token.mintAddress];
          const tokenPrice = tokenPrices?.[token.mintAddress];
          const tokenBalance = Number(token.uiAmountBalance);
          const tokenDollarBalance = tokenPrice
            ? tokenBalance * tokenPrice.price
            : null;

          const tokenUsdBalance24hChange =
            tokenDollarBalance !== null
              ? ((tokenMarketData?.priceChangePercentage24h ?? 0) / 100) *
                tokenDollarBalance
              : null;

          const isTokenAlertEnabled = walletTokensAlerts?.[token.mintAddress]?.enabled ?? false;
          return {
            ...token,
            tokenDollarBalance,
            tokenUsdBalance24hChange,
            isTokenAlertEnabled,
          };
        })
        .sort((t1, t2) => {
          const t1Balance = t1.tokenDollarBalance ?? -1;
          const t2Balance = t2.tokenDollarBalance ?? -1;
          return t2Balance - t1Balance;
        }),
    [tokensMarketData, tokenPrices, tokens, walletTokensAlerts],
  );

  return (
    <div className="flex flex-col gap-y-0.5 rounded-xl overflow-hidden">
      {isLoading
        ? Array.from(
            [0, 1, 2].map((it) => (
              <PulseLoader key={it} className="h-[60px] w-full bg-secondary" />
            )),
          )
        : sortedTokensWithBalance.map((token) => {
            const metadata =
              tokenMetadata[token.mintAddress] ??
              fallbackTokenMetadata[token.mintAddress];

            return (
              <TokenBalanceCard
                key={token.mintAddress}
                mintAddress={token.mintAddress}
                name={metadata?.name ?? shortenAddress(token.mintAddress)}
                amount={
                  token.uiAmountBalance ? Number(token.uiAmountBalance) : 0
                }
                amountDollars={token.tokenDollarBalance}
                change24hUsd={token.tokenUsdBalance24hChange}
                symbol={metadata?.symbol ?? 'UNKNOWN'}
                isAlertsEnabled={token.isTokenAlertEnabled}
                iconUrl={metadata?.imageUrl}
              />
            );
          })}
    </div>
  );
};
