import { RaydiumQuote } from '@/shared/api/solana/raydium-api.ts';
import { QuoteResponse } from '@jup-ag/api';

export enum QuoteProvider {
  JUPITER = 'jupiter',
  RAYDIUM = 'raydium',
}

export interface NormalizedJupiterQuote {
  provider: QuoteProvider.JUPITER;
  inMint: string;
  inAmount: string;
  outMint: string;
  outAmount: string;
  priceImpactPct: string;
  originalQuote: QuoteResponse;
}

export interface NormalizedRaydiumQuote {
  provider: QuoteProvider.RAYDIUM;
  inMint: string;
  inAmount: string;
  outMint: string;
  outAmount: string;
  priceImpactPct: string;
  originalQuote: RaydiumQuote;
}

export type Quote = NormalizedJupiterQuote | NormalizedRaydiumQuote;
