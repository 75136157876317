import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PulseLoader from './PulseLoader.tsx';

interface Props {
  imageUrl?: string;
  isLoading?: boolean;
}

const TokenLogo = ({ imageUrl, isLoading }: Props) => {
  if (isLoading) {
    return <PulseLoader className="bg-secondary h-full w-full rounded-full" />;
  }

  return (
    <div className="w-full h-full rounded-full border border-primary overflow-hidden">
      {imageUrl ? (
        <img
          src={imageUrl}
          alt="Token Logo"
          className="rounded-full w-full h-full"
        />
      ) : (
        <FontAwesomeIcon
          className="text-grey-40 w-full h-full"
          icon={faCircleQuestion}
        />
      )}
    </div>
  );
};

export default TokenLogo;
