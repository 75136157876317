import { AppRoute } from '@/appRoute.ts';
import { Nft } from '@/hooks/nfts/types.ts';
import { useNftBuy } from '@/hooks/nfts/useNftBuy.ts';
import { getTotalPrice } from '@/hooks/nfts/utils.ts';
import useWalletSol from '@/hooks/useWalletSol.ts';
import { ImageWithBlurHash, RarityChip } from '@/shared/components';
import Button from '@/shared/components/Button.tsx';
import { formatTokenAmount } from '@/shared/utils/formatting.ts';
import { MouseEvent, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export const NftRowCard = ({ nft }: { nft: Nft }) => {
  const navigate = useNavigate();
  const [listing] = nft.prices;
  const { token, isWalletTokensLoading } = useWalletSol();
  const { buyNft, isNftBuying } = useNftBuy();
  const userCanPurchase =
    token && listing
      ? token.fractionalBalance >
        getTotalPrice(listing.value, nft?.metadata.royaltyBps ?? 0)
      : false;

  const goToNftDetails = useCallback(() => {
    if (!nft.metadata.collectionTensorId) {
      return;
    }

    navigate(
      AppRoute.CollectibleDetails.url({
        collectionId: nft.metadata.collectionTensorId,
        mintAddress: nft.address,
      }),
    );
  }, [navigate, nft.address, nft.metadata.collectionTensorId]);

  const buyOrNavigate = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      // since button is inside a clickable div, we need to stop propagation
      e.stopPropagation();

      if (userCanPurchase && listing) {
        buyNft(nft, listing);
        return;
      }

      goToNftDetails();
    },
    [userCanPurchase, listing, goToNftDetails, buyNft, nft],
  );

  const totalPrice = useMemo(() => {
    if (!listing) {
      return;
    }

    const total = getTotalPrice(listing.value, nft.metadata.royaltyBps ?? 0);

    return total / 10 ** listing.decimals;
  }, [listing, nft.metadata.royaltyBps]);

  return (
    <div
      className="bg-secondary rounded-xl h-14 flex"
      onClick={goToNftDetails}
      tabIndex={0}
      role="button"
    >
      <div className="p-1">
        <div className="h-12 w-12 rounded-lg overflow-hidden">
          <ImageWithBlurHash
            blurhash={nft.previews?.blurhash}
            url={nft.previews?.small || nft.img.url}
            alt={nft.name}
            imageClassName="object-cover w-full bg-white"
          />
        </div>
      </div>
      <div className="flex-1 ml-2">
        <div className="flex flex-col justify-center h-full">
          <div>
            <RarityChip
              rarity={nft.metadata.rarityRank}
              totalSupply={nft.metadata.collectionTotalSupply}
            />
          </div>
          <span className="text-caption font-semibold">{nft.name}</span>
        </div>
      </div>
      {listing && totalPrice && (
        <div className="flex items-center px-3">
          <Button
            stretch={false}
            size="small"
            disabled={isWalletTokensLoading || isNftBuying}
            variant={
              isWalletTokensLoading || isNftBuying ? 'disabled' : 'brand'
            }
            isLoading={isWalletTokensLoading || isNftBuying}
            onClick={buyOrNavigate}
          >
            Buy {formatTokenAmount(totalPrice)} SOL
          </Button>
        </div>
      )}
    </div>
  );
};
