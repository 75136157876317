import { SolanaTokenMetadata } from '@/hooks/useTokenMetadataCatalog.ts';
import heliusApi from '@/shared/api/helius/api.ts';
import { HeliusParsedTransaction } from '@/shared/api/helius/types.ts';
import useSWR from 'swr';

interface TokenSwapHistoryOptions {
  from?: number;
  to?: number;
  skipLoading?: boolean;
}

export enum TokenSwapAction {
  IN = 'IN', // buy
  OUT = 'OUT', // sell
}

const isTokenMintSwap = (mintAddress: string) => {
  if (mintAddress !== SolanaTokenMetadata.mintAddress) {
    return (tx: HeliusParsedTransaction) =>
      tx.type === 'SWAP' &&
      (tx.events.swap.tokenInputs.some((input) => input.mint === mintAddress) ||
        tx.events.swap.tokenOutputs.some(
          (input) => input.mint === mintAddress,
        ));
  }

  return (tx: HeliusParsedTransaction) =>
    tx.type === 'SWAP' &&
    (tx.events.swap.nativeInput !== null ||
      tx.events.swap.nativeOutput !== null);
};

const getTokenInput = (tx: HeliusParsedTransaction, mintAddress: string) => {
  if (mintAddress !== SolanaTokenMetadata.mintAddress) {
    const tokenInput = tx.events.swap.tokenInputs.find(
      (input) => input.mint === mintAddress,
    );

    return tokenInput
      ? {
          mintAddress,
          tokenAmount: tokenInput.rawTokenAmount.tokenAmount,
          decimals: tokenInput.rawTokenAmount.decimals,
        }
      : null;
  }

  return tx.events.swap.nativeInput
    ? {
        mintAddress,
        tokenAmount: tx.events.swap.nativeInput.amount,
        decimals: SolanaTokenMetadata.decimals,
      }
    : null;
};

const getTokenOutput = (tx: HeliusParsedTransaction, mintAddress: string) => {
  if (mintAddress !== SolanaTokenMetadata.mintAddress) {
    const output = tx.events.swap.tokenOutputs.find(
      (output) => output.mint === mintAddress,
    );

    return output
      ? {
          mintAddress,
          tokenAmount: output.rawTokenAmount.tokenAmount,
          decimals: output.rawTokenAmount.decimals,
        }
      : null;
  }

  return tx.events.swap.nativeOutput
    ? {
        mintAddress,
        tokenAmount: tx.events.swap.nativeOutput.amount,
        decimals: SolanaTokenMetadata.decimals,
      }
    : null;
};

export const useWalletTokenSwapHistory = (
  walletAddress: string | null,
  mintAddress: string,
  options: TokenSwapHistoryOptions = {},
) => {
  const {
    from = 0,
    to = Number.MAX_SAFE_INTEGER,
    skipLoading = false,
  } = options;

  const { data, isLoading } = useSWR(
    walletAddress && !skipLoading
      ? ['WALLET_TOKEN_SWAP_HISTORY', walletAddress, mintAddress]
      : null,
    async () => {
      const resp = await heliusApi.getParsedTransactions(walletAddress!);

      const filtered = resp
        .filter(isTokenMintSwap(mintAddress))
        .filter((tx) => tx.timestamp >= from && tx.timestamp <= to);

      return filtered
        .map((tx) => {
          const tokenInput = getTokenInput(tx, mintAddress);
          const tokenOutput = getTokenOutput(tx, mintAddress);

          const action = tokenInput ? TokenSwapAction.OUT : TokenSwapAction.IN;
          const change =
            action === TokenSwapAction.OUT ? tokenInput : tokenOutput;

          return {
            timestamp: tx.timestamp,
            action,
            amount: change?.tokenAmount,
            decimals: change?.decimals,
            mintAddress,
          };
        })
        .sort((a, b) => a.timestamp - b.timestamp);
    },
  );

  return {
    tokenSwapHistory: data ?? [],
    isLoading,
  };
};
