import useConnectWallet, {
  UseConnectWalletArgs,
} from '@/hooks/useConnectWallet.ts';
import SpinnerIcon from '@/icons/SpinnerIcon.tsx';
import { Button } from '@/shared/components';
import { cn } from '@/shared/utils/cn.ts';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsx } from 'clsx';
import { FC, useState } from 'react';

interface Props {
  tryAgainAction?: () => void;
  expanded?: boolean;
  className?: string;
  connectWalletArgs?: UseConnectWalletArgs;
}

const TransactionSignTroubleshooting: FC<Props> = ({
  expanded = false,
  connectWalletArgs,
  tryAgainAction,
  className,
}) => {
  const { isConnectingWallet, connectWallet } =
    useConnectWallet(connectWalletArgs);

  const [isExpanded, setExpanded] = useState(expanded);
  const stillHavingTroublesSigning = (
    <section
      className={cn(
        'rounded-lg border-primary border-[0.5px] w-full p-3 transition-all overflow-hidden duration-200',
        className,
      )}
    >
      <div
        className="flex flex-row items-center my-1"
        onClick={() => setExpanded(!isExpanded)}
      >
        <span className="text-subtext font-semibold text-center flex-1">
          Having trouble signing?
        </span>
        <FontAwesomeIcon
          className={clsx('items-center h-4 w-4', {
            'rotate-90': isExpanded,
          })}
          icon={faChevronRight}
        />
      </div>
      {isExpanded && (
        <div className="flex flex-col gap-2 mt-4">
          <TroubleshootSigningOption
            title="Check for Wallet Mismatch"
            description="Verify wallets match in Phantom"
            // onClick={() => {}}
          />
          <TroubleshootSigningOption
            title="Wallet May Be Disconnected"
            description="Reconnect your wallet in Phantom"
            onClick={connectWallet}
            onClickLoading={isConnectingWallet}
          />
          {tryAgainAction && (
            <Button size="small" variant="secondary" onClick={tryAgainAction}>
              Try Again
            </Button>
          )}
        </div>
      )}
    </section>
  );

  return stillHavingTroublesSigning;
};

const TroubleshootSigningOption: FC<{
  title: string;
  description: string;
  onClick?: () => void;
  onClickLoading?: boolean;
}> = ({ title, description, onClick, onClickLoading }) => {
  return (
    <div className="flex flex-col rounded-lg p-3 w-full bg-warning/10 gap-0.5">
      <div className="flex flex-row justify-between items-center gap-2">
        <div className="flex flex-row gap-2">
          <FontAwesomeIcon
            className="text-warning w-3.5 h-3.5 mt-0.5"
            icon={faCircleExclamation}
          />
          <div className="flex flex-col">
            <span className="text-caption font-semibold">{title}</span>
            <span className="text-caption font-medium text-primary/50">
              {description}
            </span>
          </div>
        </div>
        <button
          className="flex flex-1 flex-row justify-end items-center text-primary/50"
          onClick={onClick}
        >
          {onClick && !onClickLoading && (
            <div className="flex flex-row gap-1">
              <FontAwesomeIcon className="h-3.5 w-3.5" icon={faChevronRight} />
            </div>
          )}
          {onClickLoading && <SpinnerIcon className="h-3.5 w-3.5" />}
        </button>
      </div>
    </div>
  );
};

export default TransactionSignTroubleshooting;
