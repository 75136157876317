import { useTokenDefaults } from '@/hooks/useTokenDefaults.ts';
import SwapPage from '@/pages/SwapPage.tsx';
import { FC } from 'react';

const BuyPage: FC = () => {
  const defaults = useTokenDefaults();

  return (
    <SwapPage
      action="Buy"
      actionSubjectProvider={({
        receivingToken,
        receivingTokenOptions,
        setReceivingToken,
      }) => {
        return {
          subjectToken: receivingToken,
          setSubjectToken: setReceivingToken,
          subjectTokenOptions: receivingTokenOptions,
        };
      }}
      defaults={defaults}
    />
  );
};

export default BuyPage;
