import useWallet from '@/hooks/useWallet.ts';
import {
  listWalletFungibleTokens,
  WalletToken,
} from '@/shared/api/solana/solana-api.ts';
import useSWR from 'swr';

interface UseWalletTokensValue {
  walletTokens: WalletToken[];
  isWalletTokensLoading: boolean;
}

const useWalletTokensRaw = (): UseWalletTokensValue => {
  const { walletAddress } = useWallet();

  const { data, isLoading } = useSWR(
    walletAddress ? ['WALLET_TOKENS', walletAddress] : null,
    async () => {
      if (!walletAddress) {
        return;
      }
      return listWalletFungibleTokens(walletAddress);
    },
    {
      refreshInterval: 10000,
      dedupingInterval: 10000,
      revalidateOnMount: true,
    },
  );

  return {
    walletTokens: data ?? [],
    isWalletTokensLoading: isLoading,
  };
};

export default useWalletTokensRaw;
