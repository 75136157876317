import useTelegramWebAppInitData from '@/hooks/telegram-web-app/useTelegramWebAppInitData.ts';
import useTokenMetadataCatalog, {
  TokenMetadata,
} from '@/hooks/useTokenMetadataCatalog.ts';
import useWalletTokenAlerts from '@/hooks/useWalletTokenAlerts.ts';
import miniAppApi from '@/shared/api/dialect/mini-app-api.ts';
import { Button } from '@/shared/components';
import { faBellSlash } from '@fortawesome/pro-regular-svg-icons';
import { faBell } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useCallback, useMemo, useState } from 'react';

interface Props {
  action: 'Buy' | 'Sell';
  payingToken: TokenMetadata;
  paidAmount: string;
  receivingToken: TokenMetadata;
  receivedAmount: string;
}

const TokenSwapConfirmationDetails: FC<Props> = ({
  action,
  payingToken,
  paidAmount,
  receivingToken,
  receivedAmount,
}) => {
  const [, initData] = useTelegramWebAppInitData();
  const { tokenMetadata, isTokensLoading } = useTokenMetadataCatalog({
    strict: true,
  });

  const {
    walletTokensAlerts = {},
    isWalletTokensAlertsLoading,
    refreshWalletTokensAlerts,
  } = useWalletTokenAlerts();

  const tokenAlert = useMemo(
    () => walletTokensAlerts[receivingToken.mintAddress],
    [walletTokensAlerts, receivingToken.mintAddress],
  );

  const isTokenAlertInitiallyDisabled = useMemo(
    () => !tokenAlert ?? true,
    [receivingToken.mintAddress, isWalletTokensAlertsLoading],
  );

  const isJupiterToken = useMemo(
    () => !!tokenMetadata[receivingToken.mintAddress],
    [tokenMetadata, receivingToken.mintAddress],
  );

  const [isUpdating, setIsUpdating] = useState(false);

  const onAlertButtonPress = useCallback(async () => {
    if (!initData) {
      console.warn(`Alert not available, initData not found`);
      return;
    }
    const newEnabledState = !tokenAlert?.enabled;
    setIsUpdating(true);
    await miniAppApi.alerts.updateWalletTokenAlert(
      initData,
      receivingToken.mintAddress,
      newEnabledState,
    );
    setIsUpdating(false);
    refreshWalletTokensAlerts();
  }, [
    initData,
    tokenAlert?.enabled,
    receivingToken.mintAddress,
    refreshWalletTokensAlerts,
  ]);

  const isShowAlertCta =
    isTokenAlertInitiallyDisabled &&
    isJupiterToken &&
    !isWalletTokensAlertsLoading &&
    !isTokensLoading;

  return (
    <div className="flex w-full flex-col gap-2">
      <div className="flex w-full rounded-xl border-primary border">
        <div className="flex w-full flex-row items-center justify-between p-2 pr-4 relative">
          <div className="flex items-center gap-3 shrink-0">
            <div className="relative flex h-10 w-10 m-1">
              <img
                className="absolute left-0 top-0 h-6 w-6 rounded-full"
                src={payingToken.imageUrl}
                alt={payingToken.symbol}
              />
              <div className="absolute -bottom-0.5 -right-0.5 border-white border-2 rounded-full">
                <img
                  className="h-6 w-6 rounded-full"
                  src={receivingToken.imageUrl}
                  alt={receivingToken.symbol}
                />
              </div>
            </div>
            <div className="flex flex-col">
              <span className="text-text font-semibold">{`${payingToken.symbol} → ${receivingToken.symbol}`}</span>
              <span className="text-caption text-secondary">
                {action} via Jupiter
              </span>
            </div>
          </div>
          <div className="absolute right-4">
            <div className="flex flex-col shrink-0 items-end justify-center">
              <div className="flex">
                <span className="w-5 bg-white-fade-left" />
                <span className="text-text font-semibold text-success bg-white">{`+${receivedAmount} ${receivingToken.symbol}`}</span>
              </div>
              <div className="flex">
                <span className="w-5 bg-white-fade-left" />
                <span className="text-caption text-secondary bg-white">{`-${paidAmount} ${payingToken.symbol}`}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isShowAlertCta ? (
        <div className="flex flex-row items-center w-full justify-between gap-8">
          <div className="flex flex-col">
            <span className="text-subtext font-semibold">
              {receivingToken.symbol} alerts
            </span>
            <div className="flex w-full flex-row items-center">
              <span className="text-secondary text-caption">
                Receive alerts for the token price movement from your last trade
                price
              </span>
            </div>
          </div>
          <Button
            className="!rounded-full h-7"
            disabled={isUpdating}
            onClick={onAlertButtonPress}
            variant={'secondary'}
            stretch={false}
            size="small"
          >
            <div className="flex flex-row items-center w-9 gap-1 justify-center">
              <span className="text-caption font-semibold">
                {tokenAlert?.enabled ? 'On' : 'Off'}
              </span>
              <FontAwesomeIcon
                icon={tokenAlert?.enabled ? faBell : faBellSlash}
                size="1x"
              />
            </div>
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default TokenSwapConfirmationDetails;
