import useWalletTokenPrices from '@/hooks/useWalletTokenPrices.ts';
import useWalletTokensMarketData from '@/hooks/useWalletTokensMarketData.ts';
import useWalletTokensRaw from '@/hooks/useWalletTokensRaw.ts';
import { formatDollarAmount } from '@/shared/utils/formatting.ts';
import { clsx } from 'clsx';
import { useMemo } from 'react';
import PulseLoader from '../../../shared/components/PulseLoader.tsx';

export const TotalTokenBalance = () => {
  const { isWalletTokensLoading } = useWalletTokensRaw();
  const { tokenPrices, isTokenPricesLoading, totalUsdValue } =
    useWalletTokenPrices();
  const { tokensMarketData } = useWalletTokensMarketData();

  const isLoading = isWalletTokensLoading || isTokenPricesLoading;

  const [integer, decimal] = useMemo(
    () => formatDollarAmount(totalUsdValue).split('.'),
    [totalUsdValue],
  );

  const { totalChangeUsd } = Object.entries(tokenPrices ?? {}).reduce(
    (acc, [mintAddress, price]) => {
      const balanceInDollars = price.totalPrice ?? 0;
      const changeInPercent =
        (tokensMarketData?.[mintAddress]?.priceChangePercentage24h ?? 0) / 100;
      const changeInDollars = balanceInDollars * changeInPercent;

      acc.totalChangeUsd += changeInDollars;
      return acc;
    },
    { totalChangeUsd: 0 },
  );

  const totalChangePercentage =
    (totalChangeUsd / (totalUsdValue - totalChangeUsd)) * 100;

  return (
    <div className="flex justify-center">
      {isLoading ? (
        <PulseLoader className="h-[60px] w-60 rounded-2xl bg-secondary" />
      ) : (
        <div>
          <span className="text-6xl font-semibold">
            {integer}
            <span className="text-secondary">.{decimal}</span>
          </span>
          <div
            className={clsx(
              'flex flex-row gap-2 justify-center py-2',
              { 'text-secondary': totalChangeUsd === 0 },
              { 'text-success': totalChangeUsd > 0 },
              { 'text-error': totalChangeUsd < 0 },
            )}
          >
            <span>
              {totalChangeUsd > 0 ? '+' : totalChangeUsd < 0 ? '-' : ''}
              {formatDollarAmount(Math.abs(totalChangeUsd))}
            </span>
            <span
              className={clsx(
                'px-1 rounded-lg',
                { 'bg-secondary/10': totalChangePercentage === 0 },
                { 'bg-success/10': totalChangePercentage > 0 },
                { 'bg-error/10': totalChangePercentage < 0 },
              )}
            >
              {totalChangePercentage > 0 ? '+' : ''}
              {Number.isNaN(totalChangePercentage)
                ? '0.00'
                : totalChangePercentage.toFixed(2)}
              %
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
