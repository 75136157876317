import { logEvent } from '@/analytics.ts';
import { ViewportFlex } from '@/components/ViewportFlex.tsx';
import useTelegramWebAppShowPopup from '@/hooks/telegram-web-app/useTelegramWebAppShowPopup.ts';
import useConnectWallet from '@/hooks/useConnectWallet.ts';
import ArrowRightIcon from '@/icons/ArrowRightIcon.tsx';
import PhantomIcon from '@/icons/PhantomIcon.tsx';
import Button from '@/shared/components/Button.tsx';
import { isDeviceSupported } from '@/shared/utils/device-support.ts';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useCallback, useState } from 'react';

const header = (
  <div className="flex flex-col gap-1 py-3 items-center justify-center">
    <span className="text-h2 font-bold">Connect your wallet</span>
    <span className="text-subtext text-secondary">
      Securely connect your wallet to get started
    </span>
  </div>
);

const ConnectWalletPage: FC = () => {
  const [agreed, setAgreed] = useState(false);
  const onChange = useCallback(() => setAgreed(!agreed), [agreed]);

  const agreementCheckbox = (
    <label className="relative">
      <input
        type="checkbox"
        id="id"
        name="name"
        className="absolute top-0 w-0 h-0 peer opacity-0"
        onChange={onChange}
      />
      <div className="w-5 h-5 border-grey-40 text-inverse-primary flex justify-center items-center border rounded-full cursor-pointer peer-checked:border-brand peer-checked:bg-brand">
        <FontAwesomeIcon icon={faCheck} size="sm" />
      </div>
    </label>
  );

  const { connectWallet, isConnectingWallet } = useConnectWallet();
  const showPopup = useTelegramWebAppShowPopup();

  const extendedConnectWallet = useCallback(() => {
    if (!isDeviceSupported) {
      showPopup({
        title: 'Desktop coming soon',
        message: 'Connect your wallet on mobile.',
        buttons: [{ id: 'ok', type: 'ok', text: 'Ok' }],
      });
      return;
    }

    if (isConnectingWallet) {
      return;
    }

    logEvent('connect_wallet', {
      status: 'init',
      wallet_provider: 'phantom', // TODO: extend if we add more wallets
    });

    connectWallet();
  }, [showPopup, isConnectingWallet, connectWallet]);

  const buttonDisabled = !agreed || isConnectingWallet;

  return (
    <ViewportFlex>
      <div className="flex flex-col px-4 py-3 w-full">
        <div className="flex flex-1 flex-col items-center justify-center gap-3">
          {header}
          <span className="py-2 text-h1">
            $0<span className="text-secondary">.00</span>
          </span>
        </div>
        <div className="flex flex-0 flex-col items-center">
          <Button
            isLoading={isConnectingWallet}
            disabled={buttonDisabled}
            variant="secondary"
            className={buttonDisabled ? 'opacity-50' : ''}
            onClick={extendedConnectWallet}
          >
            <PhantomIcon />
            <span>Connect with Phantom</span>
            <ArrowRightIcon />
          </Button>
          <div className="flex flex-row items-center gap-2.5 py-2.5">
            {agreementCheckbox}
            <span className="text-caption text-secondary">
              I agree to the{' '}
              <a
                className="text-brand underline"
                href="https://www.dialect.to/tos" //TODO don't forget to update
                target="_blank"
                rel="noreferrer"
              >
                Terms of Service
              </a>{' '}
              &{' '}
              <a
                className="text-brand underline"
                href="https://www.dialect.to/privacy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </span>
          </div>
        </div>
      </div>
    </ViewportFlex>
  );
};

export default ConnectWalletPage;
