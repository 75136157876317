import { SolanaTokenMetadata } from '@/hooks/useTokenMetadataCatalog.ts';

export function calculateMaxPossibleWithdrawal(
  balance: number,
  tokenMintAddress: string,
): number {
  const isSOL = tokenMintAddress === SolanaTokenMetadata.mintAddress;
  if (isSOL) {
    const txFee = 0.000005;
    const approxSplAccountRent = 0.003;
    return balance - 3 * txFee - approxSplAccountRent;
  }
  return balance;
}
