import { useTokenDefaults } from '@/hooks/useTokenDefaults.ts';
import SwapPage from '@/pages/SwapPage.tsx';
import { FC } from 'react';

const SellPage: FC = () => {
  const defaults = useTokenDefaults();

  return (
    <SwapPage
      action="Sell"
      actionSubjectProvider={({
        payingToken,
        payingTokenOptions,
        setPayingToken,
      }) => ({
        subjectToken: payingToken,
        setSubjectToken: setPayingToken,
        subjectTokenOptions: payingTokenOptions,
      })}
      defaults={defaults}
    />
  );
};

export default SellPage;
