import { solanaConnection } from '@/shared/api/solana/solana-api.ts';
import { Market as OpenBookMarket } from '@openbook-dex/openbook';
import {
  CurrencyAmount,
  LIQUIDITY_STATE_LAYOUT_V4,
  Liquidity,
  MAINNET_PROGRAM_ID,
  MARKET_STATE_LAYOUT_V3,
  Percent,
  SPL_ACCOUNT_LAYOUT,
  TOKEN_PROGRAM_ID,
  Token,
  TokenAmount,
  TxVersion,
  parseBigNumberish,
  type BigNumberish,
  type LiquidityPoolInfo,
  type LiquidityPoolKeys,
  type TokenAccount,
} from '@raydium-io/raydium-sdk';
import { Connection, PublicKey } from '@solana/web3.js';

type SwapMode = 'ExactIn' | 'ExactOut';

export interface RaydiumQuoteOut {
  quote: ReturnType<typeof Liquidity.computeAmountOut>;
  poolKeys: LiquidityPoolKeys;
  tokenAmountIn: TokenAmount | CurrencyAmount;
  tokenAmountOut: TokenAmount | CurrencyAmount;
  tokenInMintPk: string;
  tokenOutMintPk: string;
  swapMode: 'ExactIn';
}

export interface RaydiumQuoteIn {
  quote: ReturnType<typeof Liquidity.computeAmountIn>;
  poolKeys: LiquidityPoolKeys;
  tokenAmountIn: TokenAmount | CurrencyAmount;
  tokenAmountOut: TokenAmount | CurrencyAmount;
  tokenInMintPk: string;
  tokenOutMintPk: string;
  swapMode: 'ExactOut';
}

export type RaydiumQuote = RaydiumQuoteOut | RaydiumQuoteIn;

class RaydiumApi {
  private raydiumPoolCache = new Map<
    string,
    { poolId: PublicKey; marketId: PublicKey; inverted: boolean }
  >();
  private poolKeysCache = new Map<string, LiquidityPoolKeys>();

  constructor(private connection: Connection) {}

  private async findRaydiumPool(baseToken: PublicKey, quoteToken: PublicKey) {
    const cacheKey = `${baseToken.toBase58()}_${quoteToken.toBase58()}`;
    const cacheRaydiumPool = this.raydiumPoolCache.get(cacheKey);

    if (cacheRaydiumPool) {
      return cacheRaydiumPool;
    }

    const market = await this.findMarketId(baseToken, quoteToken);
    const poolId = Liquidity.getAssociatedId({
      programId: MAINNET_PROGRAM_ID.AmmV4,
      marketId: market.marketId,
    });

    const result = {
      poolId,
      marketId: market.marketId,
      inverted: market.inverted,
    };

    this.raydiumPoolCache.set(cacheKey, result);

    return result;
  }

  private async fetchRaydiumPoolsLiquidity({
    marketId,
    poolId,
  }: {
    marketId: PublicKey;
    poolId: PublicKey;
  }) {
    const baseLp = Liquidity.getAssociatedBaseVault({
      programId: MAINNET_PROGRAM_ID.AmmV4,
      marketId: marketId,
    });

    const quoteLp = Liquidity.getAssociatedQuoteVault({
      programId: MAINNET_PROGRAM_ID.AmmV4,
      marketId: marketId,
    });

    const [base, quote] = await Promise.all([
      this.connection.getTokenAccountBalance(baseLp),
      this.connection.getTokenAccountBalance(quoteLp),
    ]);

    return {
      poolId: poolId,
      marketId: marketId,
      baseLpId: baseLp,
      quoteLpId: quoteLp,
      baseAmount: base.value,
      quoteAmount: quote.value,
    };
  }

  private async composeAmountIn(
    tokenInMint: PublicKey,
    tokenOutMint: PublicKey,
    amountOut: BigNumberish,
    slippage: Percent,
  ): Promise<
    Omit<RaydiumQuoteIn, 'swapMode' | 'tokenOutMintPk' | 'tokenInMintPk'>
  > {
    const { poolId, inverted } = await this.findRaydiumPool(
      tokenInMint,
      tokenOutMint,
    );

    const poolKeys = await this.fetchPoolKeys(poolId);

    const [baseTokenAmount, quoteTokenAmount] = await Promise.all([
      this.connection.getTokenAccountBalance(poolKeys.baseVault),
      this.connection.getTokenAccountBalance(poolKeys.quoteVault),
    ]);

    const tokenAmountOut = new TokenAmount(
      new Token(
        TOKEN_PROGRAM_ID,
        tokenOutMint,
        inverted
          ? baseTokenAmount.value.decimals
          : quoteTokenAmount.value.decimals,
      ),
      amountOut,
    );
    const currencyIn = new Token(
      TOKEN_PROGRAM_ID,
      tokenInMint,
      inverted
        ? quoteTokenAmount.value.decimals
        : baseTokenAmount.value.decimals,
    );

    const quote = Liquidity.computeAmountIn({
      amountOut: tokenAmountOut,
      currencyIn: currencyIn,
      poolKeys,
      poolInfo: {
        baseReserve: parseBigNumberish(baseTokenAmount.value.amount),
        quoteReserve: parseBigNumberish(quoteTokenAmount.value.amount),
        // other fields are unused, so I omit and cast it to full type
      } as LiquidityPoolInfo,
      slippage,
    });

    return {
      quote,
      poolKeys,
      tokenAmountIn: quote.amountIn,
      tokenAmountOut,
    };
  }

  private async composeAmountOut(
    tokenInMint: PublicKey,
    tokenOutMint: PublicKey,
    amountIn: BigNumberish,
    slippage: Percent,
  ): Promise<
    Omit<RaydiumQuoteOut, 'swapMode' | 'tokenOutMintPk' | 'tokenInMintPk'>
  > {
    const { poolId, inverted } = await this.findRaydiumPool(
      tokenInMint,
      tokenOutMint,
    );

    const poolKeys = await this.fetchPoolKeys(poolId);

    const [baseTokenAmount, quoteTokenAmount] = await Promise.all([
      this.connection.getTokenAccountBalance(poolKeys.baseVault),
      this.connection.getTokenAccountBalance(poolKeys.quoteVault),
    ]);

    const tokenAmountIn = new TokenAmount(
      new Token(
        TOKEN_PROGRAM_ID,
        tokenInMint,
        inverted
          ? quoteTokenAmount.value.decimals
          : baseTokenAmount.value.decimals,
      ),
      amountIn,
    );

    const quote = Liquidity.computeAmountOut({
      amountIn: tokenAmountIn,
      currencyOut: new Token(
        TOKEN_PROGRAM_ID,
        tokenOutMint,
        inverted
          ? baseTokenAmount.value.decimals
          : quoteTokenAmount.value.decimals,
      ),
      poolKeys,
      poolInfo: {
        baseReserve: parseBigNumberish(baseTokenAmount.value.amount),
        quoteReserve: parseBigNumberish(quoteTokenAmount.value.amount),
        // other fields are unused, so I omit and cast it to full type
      } as LiquidityPoolInfo,
      slippage,
    });

    return {
      quote,
      poolKeys,
      tokenAmountIn,
      tokenAmountOut: quote.amountOut,
    };
  }

  async composeQuote(
    tokenInMintPk: string,
    tokenOutMintPk: string,
    amount: BigNumberish,
    slippageBps: number,
    swapMode: SwapMode = 'ExactIn',
  ): Promise<RaydiumQuote> {
    const tokenInMint = new PublicKey(tokenInMintPk);
    const tokenOutMint = new PublicKey(tokenOutMintPk);
    const slippage = new Percent(slippageBps, 100 * 100); // basis point (bps) is 1/100 of a percent

    const composeFn =
      swapMode === 'ExactIn'
        ? this.composeAmountOut.bind(this)
        : this.composeAmountIn.bind(this);

    const result = await composeFn(tokenInMint, tokenOutMint, amount, slippage);

    return {
      ...result,
      tokenInMintPk,
      tokenOutMintPk,
      swapMode,
    } as RaydiumQuote; // since we're returning a union, we need to explicitly cast here, due to swapMode being unknown at build time.
  }

  async composeSwapIx(
    ownerPk: string,
    amountIn: TokenAmount | CurrencyAmount,
    amountOut: TokenAmount | CurrencyAmount,
    poolKeys: LiquidityPoolKeys,
    swapMode: SwapMode,
  ) {
    const owner = new PublicKey(ownerPk);
    const tokenAccounts = await fetchWalletTokenAccounts(
      this.connection,
      owner,
    );

    const swapTx = await Liquidity.makeSwapInstructionSimple({
      amountIn,
      amountOut,
      poolKeys,
      userKeys: {
        owner,
        tokenAccounts,
      },
      fixedSide: swapMode === 'ExactIn' ? 'in' : 'out',
      connection: this.connection,
      makeTxVersion: TxVersion.V0,
    });

    if (swapTx.innerTransactions.length !== 1) {
      throw new Error('should never happen...');
    }

    return swapTx.innerTransactions[0]!;
  }

  private async findMarketId(
    baseToken: PublicKey,
    quoteToken: PublicKey,
    retry = false,
  ): Promise<{ marketId: PublicKey; inverted: boolean }> {
    const accounts = await OpenBookMarket.findAccountsByMints(
      this.connection,
      baseToken,
      quoteToken,
      MAINNET_PROGRAM_ID.OPENBOOK_MARKET,
    );

    if (!accounts.length) {
      if (!retry) {
        return this.findMarketId(quoteToken, baseToken, true);
      }

      throw new Error(
        `Market not found for ${baseToken.toString()} and ${quoteToken.toString()}`,
      );
    }
    return { marketId: accounts[0]!.publicKey, inverted: retry };
  }

  private async fetchPoolKeys(poolId: PublicKey): Promise<LiquidityPoolKeys> {
    const cacheKey = poolId.toBase58();
    const cachedPoolKeys = this.poolKeysCache.get(cacheKey);

    if (cachedPoolKeys) {
      return cachedPoolKeys;
    }

    const account = await this.connection.getAccountInfo(poolId);

    if (!account) {
      throw new Error('Failed to fetch pool account info');
    }

    const fields = LIQUIDITY_STATE_LAYOUT_V4.decode(account.data);
    const {
      status,
      baseMint,
      quoteMint,
      lpMint,
      openOrders,
      targetOrders,
      baseVault,
      quoteVault,
      marketId,
      baseDecimal,
      quoteDecimal,
    } = fields;

    let withdrawQueue, lpVault;
    if (Liquidity.isV4(fields)) {
      withdrawQueue = fields.withdrawQueue;
      lpVault = fields.lpVault;
    } else {
      withdrawQueue = PublicKey.default;
      lpVault = PublicKey.default;
    }

    // uninitialized
    // if (status.isZero()) {
    //   return ;
    // }

    const associatedPoolKeys = Liquidity.getAssociatedPoolKeys({
      version: 4,
      marketId,
      baseMint: baseMint,
      quoteMint: quoteMint,
      baseDecimals: baseDecimal.toNumber(),
      quoteDecimals: quoteDecimal.toNumber(),
      programId: MAINNET_PROGRAM_ID.AmmV4,
      marketProgramId: MAINNET_PROGRAM_ID.OPENBOOK_MARKET,
      marketVersion: 3,
    });

    const poolKeys = {
      id: poolId,
      baseMint,
      quoteMint,
      lpMint,
      version: 4 as const,
      programId: MAINNET_PROGRAM_ID.AmmV4,

      authority: associatedPoolKeys.authority,
      openOrders,
      targetOrders,
      baseVault,
      quoteVault,
      withdrawQueue,
      lpVault,
      marketVersion: 3 as const,
      marketProgramId: MAINNET_PROGRAM_ID.OPENBOOK_MARKET,
      marketId,
      marketAuthority: associatedPoolKeys.marketAuthority,
    };

    const marketInfo = await this.connection.getAccountInfo(marketId);
    if (!marketInfo) {
      throw new Error('Failed to fetch market account info');
    }

    const market = MARKET_STATE_LAYOUT_V3.decode(marketInfo.data);

    const {
      baseVault: marketBaseVault,
      quoteVault: marketQuoteVault,
      bids: marketBids,
      asks: marketAsks,
      eventQueue: marketEventQueue,
    } = market;

    const result = {
      ...poolKeys,
      ...{
        marketBaseVault,
        marketQuoteVault,
        marketBids,
        marketAsks,
        marketEventQueue,
      },
      baseDecimals: baseDecimal.toNumber(),
      quoteDecimals: quoteDecimal.toNumber(),
      lookupTableAccount: associatedPoolKeys.lookupTableAccount,
      lpDecimals: associatedPoolKeys.lpDecimals,
    };

    this.poolKeysCache.set(cacheKey, result);

    return result;
  }
}

export default new RaydiumApi(solanaConnection);

async function fetchWalletTokenAccounts(
  connection: Connection,
  wallet: PublicKey,
): Promise<TokenAccount[]> {
  const walletTokenAccount = await connection.getTokenAccountsByOwner(wallet, {
    programId: TOKEN_PROGRAM_ID,
  });
  return walletTokenAccount.value.map((i) => ({
    pubkey: i.pubkey,
    programId: i.account.owner,
    accountInfo: SPL_ACCOUNT_LAYOUT.decode(i.account.data),
  }));
}
