import { mapSimplehashNftToNft } from '@/hooks/nfts/utils.ts';
import simpleHashApi from '@/shared/api/simplehash/api.ts';
import useSWR from 'swr';

// TODO: extend to support pagination
export const useNftsForWallet = (walletAddress?: string | null) => {
  const { data, isLoading } = useSWR(
    walletAddress ? ['WALLET_NFTS', walletAddress] : null,
    async () => {
      if (!walletAddress) {
        return undefined;
      }

      const dto = await simpleHashApi.getNftsForWallet(walletAddress);

      const normalizedNfts = dto.nfts
        .map(mapSimplehashNftToNft)
        .sort((a, b) => {
          const fpA = a.metadata.isCollectionVerified
            ? a.prices[0]?.value ?? 0
            : -1;
          const fpB = b.metadata.isCollectionVerified
            ? b.prices[0]?.value ?? 0
            : -1;

          return fpB - fpA;
        });

      return {
        next: dto.next,
        previous: dto.previous,
        nextCursor: dto.next_cursor,
        nfts: normalizedNfts,
      };
    },
    { shouldRetryOnError: true },
  );

  return {
    nfts: data?.nfts ?? [],
    next: data?.next ?? null,
    previous: data?.previous ?? null,
    isNftsLoading: isLoading,
  };
};
