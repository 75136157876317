import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={42}
    height={42}
    viewBox="0 0 42 42"
    fill="none"
    {...props}
  >
    <rect width={40} height={40} x={0.5} fill="#F62D2D" rx={20} />
    <path
      fill="#fff"
      d="M24.406 13.406 22 19.031h3.5c.406 0 .781.25.938.625.125.407.03.844-.282 1.125l-8 7c-.375.282-.875.313-1.25.032a.97.97 0 0 1-.343-1.188L18.968 21H15.5c-.438 0-.813-.25-.938-.625-.156-.406-.062-.844.25-1.125l8-7c.375-.281.875-.313 1.25-.031a.97.97 0 0 1 .344 1.187Z"
    />
  </svg>
);
export default SvgComponent;
