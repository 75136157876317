import {
  NormalizedJupiterQuote,
  NormalizedRaydiumQuote,
  Quote,
} from '@/hooks/token-swap/types.ts';
import jupiterApi from '@/shared/api/jupiter-api.ts';
import raydiumApi from '@/shared/api/solana/raydium-api.ts';
import { solanaConnection } from '@/shared/api/solana/solana-api.ts';
import { toBuffer } from '@/shared/utils/transaction-utils.ts';
import {
  PublicKey,
  TransactionMessage,
  VersionedTransaction,
} from '@solana/web3.js';

export type SwapProviderFn<Q extends Quote> = (
  walletAddress: string,
  quote: Q,
  priorityFeeLamports?: number,
) => Promise<{ tx: string }>;

export const jupiterSwapProvider: SwapProviderFn<
  NormalizedJupiterQuote
> = async (walletAddress, quote, priorityFeeLamports) => {
  const swapResponse = await jupiterApi.swapPost({
    swapRequest: {
      quoteResponse: quote.originalQuote,
      userPublicKey: walletAddress,
      prioritizationFeeLamports: priorityFeeLamports,
    },
  });

  return { tx: swapResponse.swapTransaction };
};

export const raydiumSwapProvider: SwapProviderFn<
  NormalizedRaydiumQuote
> = async (walletAddress, quote) => {
  const swapIxs = await raydiumApi.composeSwapIx(
    walletAddress,
    quote.originalQuote.swapMode === 'ExactIn'
      ? quote.originalQuote.tokenAmountIn
      : quote.originalQuote.quote.maxAmountIn,
    quote.originalQuote.swapMode === 'ExactIn'
      ? quote.originalQuote.quote.minAmountOut
      : quote.originalQuote.tokenAmountOut,
    quote.originalQuote.poolKeys,
    quote.originalQuote.swapMode,
  );
  const blockHash = await solanaConnection.getLatestBlockhash();
  const msg = new TransactionMessage({
    instructions: swapIxs.instructions,
    payerKey: new PublicKey(walletAddress),
    recentBlockhash: blockHash.blockhash,
  }).compileToV0Message();

  const tx = new VersionedTransaction(msg);

  return { tx: toBuffer(tx.serialize()).toString('base64') };
};
