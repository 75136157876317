import { faDiamond } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsx } from 'clsx';
import { useMemo } from 'react';

enum RarityTier {
  COMMON = 'common', // most common
  UNCOMMON = 'uncommon', // top 60%
  RARE = 'rare', // top 35%
  EPIC = 'epic', // top 15%
  LEGENDARY = 'legendary', // top 5%
  MYTHIC = 'mythic', // top 1%
}

const rarityTiers = [
  { tier: RarityTier.MYTHIC, threshold: 0.01 },
  { tier: RarityTier.LEGENDARY, threshold: 0.05 },
  { tier: RarityTier.EPIC, threshold: 0.15 },
  { tier: RarityTier.RARE, threshold: 0.35 },
  { tier: RarityTier.UNCOMMON, threshold: 0.6 },
  { tier: RarityTier.COMMON, threshold: 1 },
];

const sizeClasses = {
  small: {
    container: 'rounded-full px-1 py-0.5 gap-0.5',
    font: 'text-caption font-semibold',
  },
  large: {
    container: 'rounded-lg py-1 px-1.5 gap-1',
    font: 'text-subtext font-semibold',
  },
};

const rarityColors: Record<RarityTier, string> = {
  [RarityTier.COMMON]: 'bg-grey-20',
  [RarityTier.UNCOMMON]: 'bg-[#A4F5B6]',
  [RarityTier.RARE]: 'bg-[#C2DEFF]',
  [RarityTier.EPIC]: 'bg-[#E9D3FF]',
  [RarityTier.LEGENDARY]: 'bg-[#FAE163]',
  [RarityTier.MYTHIC]: 'bg-[#FFC0A6]',
};

const calculateTier = (rarity: number, totalSupply: number) => {
  const ratio = rarity / totalSupply;

  for (const threshold of rarityTiers) {
    if (ratio <= threshold.threshold) {
      return threshold.tier;
    }
  }

  return RarityTier.COMMON;
};

const RarityChip = ({
  rarity,
  size = 'small',
  totalSupply,
}: {
  rarity?: number;
  size?: 'small' | 'large';
  totalSupply?: number;
}) => {
  const tier = useMemo(() => {
    if (!rarity || !totalSupply) {
      return RarityTier.COMMON;
    }

    return calculateTier(rarity, totalSupply);
  }, [rarity, totalSupply]);

  return (
    <div
      className={clsx(
        'inline-flex items-center',
        sizeClasses[size].container,
        rarityColors[tier],
      )}
    >
      <FontAwesomeIcon icon={faDiamond} className="w-3 h-3" />
      <span className={sizeClasses[size].font}>{rarity ?? '?'}</span>
    </div>
  );
};

export default RarityChip;
