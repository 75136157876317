import { AppRoute } from '@/appRoute.ts';
import useTelegramWebAppShowPopup from '@/hooks/telegram-web-app/useTelegramWebAppShowPopup.ts';
import useWallet from '@/hooks/useWallet.ts';
import PhantomIcon from '@/icons/PhantomIcon.tsx';
import SettingsIcon from '@/icons/SettingsIcon.tsx';
import { Button } from '@/shared/components';
import PulseLoader from '@/shared/components/PulseLoader.tsx';
import { shortenAddress } from '@/shared/utils/wallet.ts';
import { faBells } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  showAlertsHint?: boolean;
  showSettings?: boolean;
}

const Header: React.FC<Props> = ({
  showAlertsHint = true,
  showSettings = true,
}: Props) => {
  const showPopup = useTelegramWebAppShowPopup();
  const { walletAddress, isWalletLoading } = useWallet();

  const walletChip =
    isWalletLoading || !walletAddress ? (
      <PulseLoader className="h-8 w-[120px] rounded-2xl bg-secondary" />
    ) : (
      <button
        className="flex flex-row items-center p-2 rounded-2xl bg-secondary gap-2"
        onClick={async () => {
          if (!walletAddress) return;
          await navigator.clipboard.writeText(walletAddress);
          await showPopup({
            message: 'Wallet address copied to clipboard',
          });
        }}
      >
        <PhantomIcon />
        <span className="text-primary text-subtext">
          {shortenAddress(walletAddress)}
        </span>
      </button>
    );
  return (
    <header className="flex w-full flex-row items-center justify-between py-2">
      {showAlertsHint && (
        <div className="flex items-center justify-center w-8 h-8 bg-secondary p-2 rounded-full cursor-pointer">
          <Button
            onClick={() => {
              showPopup({
                title: 'Token Price Alerts',
                message:
                  'You can enable price alerts for a token by visiting its Token View below.',
              });
            }}
            variant="secondary"
            size="small"
          >
            <FontAwesomeIcon icon={faBells} size="lg" />
          </Button>
        </div>
        // <img
        //   className="h-8 w-8 rounded-full"
        //   src={DialectBotAvatar}
        //   alt="Dialect"
        // />
      )}
      <div className="flex flex-1 items-center justify-center">
        {walletChip}
      </div>
      {showSettings && (
        <Link to={AppRoute.Settings.path}>
          <div className="flex items-center justify-center w-8 h-8 bg-secondary p-2 rounded-full cursor-pointer">
            <SettingsIcon />
          </div>
        </Link>
      )}
    </header>
  );
};

export default Header;
