import App from '@/App.tsx';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { IS_ANALYTICS_ENABLED } from '@/analytics.ts';
import { init as amplitudeInit } from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/react';

const environment = import.meta.env.VITE_ENVIRONMENT;

Sentry.init({
  enabled: environment !== 'local-development',
  environment: environment,
  dsn: 'https://da56cc88792e09df3a656b0fe5e8d152@o4504163772858368.ingest.sentry.io/4506587839397888',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', 'dialectapi.to'],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

if (IS_ANALYTICS_ENABLED) {
  amplitudeInit(import.meta.env.VITE_AMPLITUDE_API_KEY, {
    defaultTracking: true,
    minIdLength: 1,
  });
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
