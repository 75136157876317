import useTelegramWebApp from '@/hooks/telegram-web-app/useTelegramWebApp.ts';
import useTelegramWebAppInitData from '@/hooks/telegram-web-app/useTelegramWebAppInitData.ts';
import useTelegramWebAppShowPopup from '@/hooks/telegram-web-app/useTelegramWebAppShowPopup.ts';
import useWallet from '@/hooks/useWallet.ts';
import miniAppApi from '@/shared/api/dialect/mini-app-api.ts';
import { useEffect, useState } from 'react';

export interface UseConnectWalletArgs {
  initData?: string | null;
  openLink?: (url: string) => void;
}

interface UseConnectWalletValue {
  connectWallet: () => Promise<void>;
  disconnectWallet: () => Promise<void>;
  isConnectingWallet: boolean;
  isDisconnectingWallet: boolean;
  isSwitchingWallet: boolean;
}

const useConnectWallet: (
  args?: UseConnectWalletArgs,
) => UseConnectWalletValue = (args?: UseConnectWalletArgs) => {
  const [, initDataRaw] = useTelegramWebAppInitData();
  const { openLink: openLinkTelegramWebApp, close } = useTelegramWebApp();
  const showPopup = useTelegramWebAppShowPopup();

  const [isConnectingWallet, setIsConnectingWallet] = useState(false);
  const [isDisconnectingWallet, setIsDisconnectingWallet] = useState(false);
  const { refreshWalletSession, walletConnectedAt, setRefreshInterval } =
    useWallet();
  const [lastWalletConnectedAt, setLastWalletConnectedAt] =
    useState<Date | null>();

  useEffect(() => {
    if (!walletConnectedAt || !lastWalletConnectedAt || !isConnectingWallet) {
      return;
    }
    if (walletConnectedAt > lastWalletConnectedAt) {
      setIsConnectingWallet(false);
    }
  }, [walletConnectedAt]);

  const initData = args?.initData ?? initDataRaw;
  const openLink = args?.openLink ?? openLinkTelegramWebApp;

  const connectWallet = async () => {
    // if (!isDeviceSupported) {
    //   showPopup({
    //     title: 'Desktop not yet supported',
    //     message: 'Please try on mobile.',
    //     buttons: [{ id: 'ok', type: 'ok', text: 'Ok' }],
    //   });
    //   return;
    // }
    if (!initData) {
      return;
    }
    try {
      setIsConnectingWallet(true);
      setLastWalletConnectedAt(walletConnectedAt);
      const { deeplink } =
        await miniAppApi.phantom.prepareConnectWallet(initData);
      setRefreshInterval(2000);
      openLink(deeplink);
      // setTimeout(() => {
      //   close();
      // }, 1000);
    } catch (e) {
      setIsConnectingWallet(false);
    }
  };

  const disconnectWallet = async () => {
    // if (!isMobileOrTablet) {
    //   showPopup({
    //     title: 'Desktop not yet supported',
    //     message: 'Please try on mobile.',
    //     buttons: [{ id: 'ok', type: 'ok', text: 'Ok' }],
    //   });
    //   return;
    // }
    if (!initData) {
      return;
    }
    setIsDisconnectingWallet(true);
    try {
      await miniAppApi.phantom.disconnectWallet(initData);
      await refreshWalletSession();
    } finally {
      setIsDisconnectingWallet(false);
    }
  };

  return {
    connectWallet,
    disconnectWallet,
    isConnectingWallet,
    isDisconnectingWallet,

    isSwitchingWallet: isConnectingWallet || isDisconnectingWallet,
  };
};

export default useConnectWallet;
