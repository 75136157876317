import { TokenMetadata } from '@/hooks/useTokenMetadataCatalog.ts';
import birdeyeApi from '@/shared/api/birdseye/api.ts';
import { useCallback } from 'react';

async function findInBirdeye(address: string) {
  return await birdeyeApi
    .getTokenMarketData(address)
    .then((it) => {
      if (it.address && it.decimals) {
        return {
          name: it.name,
          symbol: it.symbol ?? 'UNKNOWN',
          decimals: it.decimals,
          imageUrl: it.logoURI ?? '',
          mintAddress: it.address,
          showPriority: 0,
          infoLinks: {
            solscan: `https://solscan.io/token/${it.address}`,
            birdeye: `https://birdeye.so/token/${it.address}?chain=solana`,
          },
        };
      }
      return null;
    })
    .catch(() => null);
}

export const useSearchTokens = (options: TokenMetadata[]) => {
  const searchByMintAddress = useCallback(
    async (inputAddress: string) => {
      const tokens = options.filter((it) => it.mintAddress === inputAddress);
      if (tokens.length === 0) {
        const token = await findInBirdeye(inputAddress);
        token && tokens.push({ ...token, isUnknown: true });
      }
      return tokens;
    },
    [options],
  );

  const findBySymbol = useCallback(
    (inputSymbol: string) => {
      return options.find(
        (token) => token.symbol.toLowerCase() === inputSymbol.toLowerCase(),
      );
    },
    [options],
  );

  return { searchByMintAddress, searchBySymbol: findBySymbol };
};
