export function countLeadingDecimalZeros(num: number): number {
  // Convert the number to a string
  const numStr = num.toString();

  // Check if the number is in exponential notation
  if (numStr.includes('e')) {
    // Extract the exponent
    const exponent = parseInt(numStr.split('e-')[1]!, 10);
    if (exponent) {
      // For a number in exponential notation, the number of leading zeros
      // is equal to the exponent minus 1 (e.g., 1.5e-5 has 4 leading zeros)
      return exponent - 1;
    }
  } else {
    // Handle non-exponential notation
    const parts = numStr.split('.');
    if (parts.length > 1) {
      const decimalPart = parts[1]!;
      // Match leading zeros in the decimal part
      const match = decimalPart.match(/^0+/);
      if (match) {
        return match[0].length; // Number of leading zeros
      }
    }
  }

  return 0; // If no decimal part or no leading zeros
}
