import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

interface Token {
  symbol: string | null;
  mintAddress: string | null;
}
export const defaultToken: Token = {
  symbol: 'SOL',
  mintAddress: 'So11111111111111111111111111111111111111112',
};

export const defaultTokenAdditional: Token = {
  symbol: 'USDC',
  mintAddress: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
};

export const useTokenDefaults = () => {
  const [queryParams] = useSearchParams();

  const payingTokenSymbol = queryParams.get('payingTokenSymbol');
  const payingTokenMintAddress = queryParams.get('payingTokenMintAddress');
  const receivingTokenSymbol = queryParams.get('receivingTokenSymbol');
  const receivingTokenMintAddress = queryParams.get(
    'receivingTokenMintAddress',
  );
  const payingAmount = queryParams.get('payingAmount');
  const receivingAmount = queryParams.get('receivingAmount');

  const filled = useMemo(() => {
    if (payingTokenMintAddress || receivingTokenMintAddress) {
      const payingToken = payingTokenMintAddress
        ? { mintAddress: payingTokenMintAddress, symbol: null }
        : receivingTokenMintAddress !== defaultToken.mintAddress
          ? defaultToken
          : defaultTokenAdditional;

      const receivingToken = receivingTokenMintAddress
        ? { mintAddress: receivingTokenMintAddress, symbol: null }
        : payingToken.mintAddress !== defaultToken.mintAddress
          ? defaultToken
          : defaultTokenAdditional;

      return { payingToken, receivingToken };
    }

    if (payingTokenSymbol || receivingTokenSymbol) {
      const payingToken = payingTokenSymbol
        ? { symbol: payingTokenSymbol, mintAddress: null }
        : receivingTokenSymbol !== defaultToken.symbol
          ? defaultToken
          : defaultTokenAdditional;

      const receivingToken = receivingTokenSymbol
        ? { symbol: receivingTokenSymbol, mintAddress: null }
        : payingToken.symbol !== defaultToken.symbol
          ? defaultToken
          : defaultTokenAdditional;
      return { payingToken, receivingToken };
    }

    return {
      payingToken: defaultToken,
      receivingToken: defaultTokenAdditional,
    };
  }, [
    payingTokenMintAddress,
    payingTokenSymbol,
    receivingTokenMintAddress,
    receivingTokenSymbol,
  ]);

  return {
    payingTokenSymbol: filled.payingToken.symbol,
    payingTokenMintAddress: filled.payingToken.mintAddress,
    receivingTokenSymbol: filled.receivingToken.symbol,
    receivingTokenMintAddress: filled.receivingToken.mintAddress,
    payingAmount,
    receivingAmount,
  };
};
