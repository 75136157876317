import { useTokenSwapOptions } from '@/hooks/token-swap/index.ts';
import { useSearchTokens } from '@/hooks/useSearchTokens.ts';
import { TokenMetadata } from '@/hooks/useTokenMetadataCatalog.ts';
import { useCallback, useEffect, useState } from 'react';

interface SearchTokenArgs {
  symbol: string | null;
  address: string | null;
}

interface UseLoadTokenSwapDefaultOptionArgs {
  defaultSymbol: string | null;
  defaultAddress: string | null;
  currentToken: TokenMetadata | null;
  setToken: (token: TokenMetadata | null) => void;
}

export const useLoadTokenSwapDefaultOption = ({
  defaultSymbol,
  defaultAddress,
  currentToken,
  setToken,
}: UseLoadTokenSwapDefaultOptionArgs) => {
  const { allTokenOptions, isTokensLoading } = useTokenSwapOptions();
  const { searchByMintAddress, searchBySymbol } =
    useSearchTokens(allTokenOptions);

  const [isSearchingToken, setIsSearchingToken] = useState<boolean | null>(
    (defaultAddress && defaultAddress === currentToken?.mintAddress) ||
      (defaultSymbol && defaultSymbol === currentToken?.symbol)
      ? false
      : null,
  );

  const tokenNotBeenLoading = isSearchingToken === null;
  const isTokenLoading = isSearchingToken || isTokensLoading;

  const searchToken = useCallback(
    async ({ symbol, address }: SearchTokenArgs) => {
      if (!symbol && !address) {
        return null;
      }
      if (address) {
        return (await searchByMintAddress(address))[0] ?? null;
      }
      if (symbol) {
        return searchBySymbol(symbol) ?? null;
      }

      return null;
    },
    [searchByMintAddress, searchBySymbol],
  );

  useEffect(() => {
    // no defaults provided, ignore
    if (!defaultSymbol && !defaultAddress) {
      return;
    }

    // if current token matches the mint address or symbol, ignore
    if (
      (defaultAddress && defaultAddress === currentToken?.mintAddress) ||
      (defaultSymbol &&
        defaultSymbol.toLowerCase() === currentToken?.symbol.toLowerCase())
    ) {
      return;
    }

    if (isTokenLoading || !tokenNotBeenLoading) {
      return;
    }

    setIsSearchingToken(true);
    searchToken({ symbol: defaultSymbol, address: defaultAddress })
      .then((token) => {
        if (token) {
          setToken(token);
        }
      })
      .finally(() => setIsSearchingToken(false));
  }, [
    defaultAddress,
    defaultSymbol,
    currentToken?.mintAddress,
    currentToken?.symbol,
    searchToken,
    setToken,
    isTokenLoading,
    tokenNotBeenLoading,
  ]);

  return {
    isSearchingToken: isSearchingToken || tokenNotBeenLoading,
  };
};
