import useTelegramWebAppStartAppParam from '@/hooks/telegram-web-app/useTelegramWebAppStartAppParam.ts';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Used to navigate to the location specified in the start param when mini app is opened via deeplink
// Telegram web app deeplinks do not support path parameters
const TelegramWebAppDeeplinkNavigation: React.FC = () => {
  const { startAppParam } = useTelegramWebAppStartAppParam();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!startAppParam?.location) {
      return;
    }
    if (location.pathname === startAppParam.location) {
      return;
    }
    if (sessionStorage.getItem(`hasOpenedBefore`) === 'true') {
      return;
    }
    sessionStorage.setItem(`hasOpenedBefore`, 'true');
    navigate(startAppParam.location, { replace: true });
  }, [location.pathname, navigate, startAppParam]);

  return null;
};

export default TelegramWebAppDeeplinkNavigation;
