import useTelegramWebApp from '@/hooks/telegram-web-app/useTelegramWebApp.ts';
import { useSearchTokens } from '@/hooks/useSearchTokens.ts';
import { TokenMetadata } from '@/hooks/useTokenMetadataCatalog.ts';
import MagnifyingGlassIcon from '@/icons/MagnifyingGlassIcon.tsx';
import WarningIcon from '@/icons/WarningIcon.tsx';
import { formatTokenAmountPrecise } from '@/shared/utils/formatting.ts';
import { shortenAddress } from '@/shared/utils/wallet.ts';
import {
  faArrowUpRightFromSquare,
  faChevronDown,
  faMagnifyingGlass,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCircleQuestion,
  faCircleXmark,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PublicKey } from '@solana/web3.js';
import { clsx } from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Sheet from 'react-modal-sheet';
import { Virtuoso } from 'react-virtuoso';

const isSolanaAddress = (token: string) => {
  try {
    new PublicKey(token);
    return true;
  } catch (e) {
    return false;
  }
};

const getTokenAddressFromUrl = (inputUrl: URL) => {
  //extract token assuming it is the second path param
  const match = inputUrl.pathname.match('\\/[^\\/]*\\/(\\w+)\\/?.*');
  return match?.[match?.length - 1] ?? '';
};

export const TokenSelectModal = ({
  options,
  selectorValue,
  onSelectToken,
}: {
  options: TokenMetadata[];
  selectorValue?: TokenMetadata | null;
  onSelectToken: (t: TokenMetadata) => void;
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [inputFocused, setFocused] = useState(false);

  const { openLink } = useTelegramWebApp();
  const { searchByMintAddress } = useSearchTokens(options);

  const inputUrl = useMemo(() => {
    try {
      const url = new URL(searchInput);
      return { url, isBirdeye: url.hostname === 'birdeye.so' };
    } catch (e) {
      /* empty */
    }
  }, [searchInput]);

  const inputAddress = useMemo(() => {
    const input =
      inputUrl && inputUrl.isBirdeye
        ? getTokenAddressFromUrl(inputUrl.url)
        : searchInput.trim();

    if (isSolanaAddress(input)) {
      return input;
    }
    return null;
  }, [inputUrl, searchInput]);

  const closeModal = () => {
    setModalOpen(false);
    setSearchInput('');
  };

  const [shownOptions, setShownOptions] = useState(options);
  //TODO debounce?
  const getShownOptions = useCallback(async () => {
    if (!searchInput) return options;
    if (inputAddress) {
      return searchByMintAddress(inputAddress);
    }
    const lowerInput = searchInput.toLowerCase().trim();
    return options.filter(
      (it) =>
        it.name?.toLowerCase().includes(lowerInput) ||
        it.symbol.toLowerCase().includes(lowerInput),
    );
  }, [inputAddress, options, searchInput]);

  useEffect(() => {
    getShownOptions().then((it) => setShownOptions(it));
  }, [getShownOptions]);

  const getSelectorImage = (selectorValue: TokenMetadata) =>
    selectorValue.imageUrl ? (
      <img
        className="h-6 w-6 rounded-full"
        src={selectorValue.imageUrl}
        alt={selectorValue.symbol}
      />
    ) : (
      <FontAwesomeIcon
        size="xl"
        className="text-grey-40"
        icon={faCircleQuestion}
      />
    );

  return (
    <>
      <button
        className="bg-secondary text-semibold rounded-lg p-2 flex flex-row items-center min-w-[102px] gap-2 h-10"
        onClick={() => setModalOpen(true)}
      >
        {selectorValue && getSelectorImage(selectorValue)}
        <span className="text-text whitespace-nowrap truncate min-w-8">
          {selectorValue?.symbol ?? 'Select Token'}
        </span>
        <FontAwesomeIcon
          icon={faChevronDown}
          size="sm"
          className={clsx(
            'pointer-events-none transform text-secondary/50 transition-transform',
            {
              'rotate-180': isModalOpen,
            },
          )}
        />
      </button>
      <Sheet
        isOpen={isModalOpen}
        onClose={closeModal}
        snapPoints={[0.8]}
        initialSnap={0}
        disableDrag={true}
      >
        <Sheet.Container
          style={{
            boxShadow: undefined,
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
          }}
        >
          <Sheet.Header className="border-b border-b-primary">
            <div className="relative flex flex-row justify-center text-bold text-h2">
              <span className="py-4">Choose Token</span>
              <button
                className="absolute right-2 top-2 h-8 w-8 flex justify-center items-center bg-secondary rounded-3xl p-2  text-grey-60"
                onClick={closeModal}
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
            </div>
            <div className="flex flex-row items-center px-3 py-3 gap-2">
              {!searchInput && !inputFocused && (
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  className="text-grey-50"
                />
              )}
              <input
                className="flex flex-1 truncate text-text focus:outline-none placeholder-grey-60"
                placeholder={inputFocused ? '' : 'Symbol, address or URL'}
                type="text"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    e.currentTarget.blur();
                  }
                }}
              />
              {searchInput && (
                <FontAwesomeIcon
                  onClick={() => setSearchInput('')}
                  icon={faCircleXmark}
                  className="text-grey-20"
                />
              )}
            </div>
          </Sheet.Header>
          <Sheet.Content className="flex flex-col items-center overflow-scroll px-3">
            {shownOptions.length > 0 ? (
              <Virtuoso
                className="w-full"
                components={{
                  Footer: () => <div className="pb-3" />,
                  Header: () => <div className="pt-3" />,
                }}
                totalCount={shownOptions.length}
                itemContent={(index) => {
                  const option = shownOptions[index];
                  if (!option) {
                    return null;
                  }
                  return (
                    <div
                      className={clsx(
                        'w-full flex flex-row items-center justify-between py-2',
                        { 'gap-1.5': option.isUnknown },
                        { 'gap-3': !option.isUnknown },
                      )}
                      key={option.mintAddress}
                    >
                      <div
                        className="flex flex-1 flex-row items-center justify-between gap-2 min-w-0"
                        onClick={() => {
                          closeModal();
                          onSelectToken(option);
                        }}
                      >
                        <div className="flex flex-1 flex-row items-center gap-3 min-w-0">
                          <div className="h-8 aspect-square">
                            {option.imageUrl ? (
                              <img
                                className="w-full h-full rounded-full"
                                src={option.imageUrl}
                                alt={option.symbol}
                              />
                            ) : (
                              <FontAwesomeIcon
                                size="2xl"
                                className="text-grey-40"
                                icon={faCircleQuestion}
                              />
                            )}
                          </div>
                          <div className="flex flex-col overflow-hidden">
                            <span className="truncate text-text">
                              {option.name}
                            </span>
                            <span className="truncate text-secondary text-caption">
                              {formatTokenAmountPrecise(option.balance ?? 0)}{' '}
                              {option.symbol}
                            </span>
                          </div>
                        </div>
                        {option.isUnknown && (
                          <div className="bg-secondary text-secondary text-caption px-1 py-0.5 rounded-lg">
                            Unknown
                          </div>
                        )}
                      </div>
                      <div
                        className="text-caption text-secondary"
                        onClick={() =>
                          option.infoLinks && openLink(option.infoLinks.solscan)
                        }
                      >
                        {shortenAddress(option.mintAddress)}
                        <FontAwesomeIcon
                          icon={faArrowUpRightFromSquare}
                          size="sm"
                          className="ml-1.5"
                        />
                      </div>
                    </div>
                  );
                }}
              />
            ) : inputUrl ? (
              <div className="flex flex-col justify-center items-center h-full">
                <WarningIcon />
                <div className="flex flex-col items-center gap-1 py-3 px-4">
                  <span className="text-text text-semibold ">Invalid URL</span>
                  <div className="text-subtext text-secondary flex flex-col items-center">
                    <span>
                      {inputUrl.isBirdeye
                        ? 'Failed to find token by given URL'
                        : 'You can only search by Birdeye URLs'}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center h-full">
                <MagnifyingGlassIcon />
                <div className="flex flex-col items-center gap-1 py-3 px-4">
                  <span className="text-text text-semibold">
                    No tokens found
                  </span>
                  <div className="text-subtext text-secondary flex flex-col items-center">
                    <div className="flex w-screen px-6 items-center justify-center">
                      <span className="flex shrink-0 mr-1">
                        There were no results for
                      </span>
                      <span className="truncate">{`"${searchInput}`}</span>
                      <span>{`".`}</span>
                    </div>
                    <span>Try a new search term.</span>
                  </div>
                </div>
              </div>
            )}
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          style={{ backgroundColor: '#00000080' }}
          onTap={closeModal}
        />
      </Sheet>
    </>
  );
};
