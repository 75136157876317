import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import { isAndroid } from 'react-device-detect';

const windowWithTelegramData = window as typeof window & {
  Telegram?: {
    WebApp?: {
      initData?: string;
    };
  };
};
export const isRunningInTelegramWebview = Boolean(
  windowWithTelegramData?.Telegram?.WebApp?.initData,
);

export interface UseTelegramWebAppValue {
  close: () => void;
  openLink: (url: string) => void;
  setHeaderColor: (color: string) => void;
}

const useTelegramWebApp: () => UseTelegramWebAppValue = () => {
  const webApp = useWebApp();

  if (!isRunningInTelegramWebview) {
    return {
      close: () => console.log('[MOCK] closing mini app'),
      openLink: (url: string) => {
        console.log('[MOCK] opening link', url);
        window.open(url, '_blank');
      },
      setHeaderColor: (color: string) =>
        console.log('[MOCK] setting header color', color),
    };
  }
  return {
    close: webApp.close,
    openLink: (url: string) => {
      webApp.openLink && webApp.openLink(url);
      (isAndroid || !webApp.openLink) && window.open(url, '_blank');
    },
    setHeaderColor: webApp.setHeaderColor,
  };
};

export default useTelegramWebApp;
