import { Nft } from '@/hooks/nfts/types.ts';
import {
  ImageWithBlurHash,
  PulseLoader,
  RarityChip,
} from '@/shared/components';

interface Props {
  nft?: Nft | null;
  isLoading: boolean;
}

export const CollectibleImage = ({ nft, isLoading }: Props) => {
  return !nft || isLoading ? (
    <PulseCollectibleImage />
  ) : (
    <div className="w-full aspect-square rounded-xl overflow-hidden relative">
      <div className="absolute top-3 left-3">
        <RarityChip
          rarity={nft.metadata.rarityRank}
          size="large"
          totalSupply={nft.metadata.collectionTotalSupply}
        />
      </div>
      <ImageWithBlurHash
        blurhash={nft.previews?.blurhash}
        url={nft.img.url}
        alt={nft.name}
        imageClassName="object-cover w-full"
      />
    </div>
  );
};

export const PulseCollectibleImage = () => {
  return (
    <div className="w-full aspect-square rounded-lg overflow-hidden">
      <PulseLoader className="w-full aspect-square rounded-lg overflow-hidden bg-secondary" />
    </div>
  );
};
