import SpinnerIcon from '@/icons/SpinnerIcon.tsx';
import { clsx } from 'clsx';
import { ReactNode } from 'react';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: 'brand' | 'secondary' | 'disabled';
  size?: 'default' | 'small';
  stretch?: boolean;
  isLoading?: boolean;
  children: ReactNode;
};

const Button = ({
  variant = 'brand',
  size = 'default',
  isLoading = false,
  stretch = true,
  children,
  className,
  disabled,
  onClick,
  ...rest
}: Props) => {
  return (
    <button
      className={clsx(
        'flex flex-row items-center justify-center rounded-lg',
        {
          ['bg-brand text-surface-primary']: variant === 'brand',
          ['bg-secondary text-primary']: variant === 'secondary',
          ['bg-disabled text-tertiary']: variant === 'disabled',
          ['pointer-events-none']: disabled || isLoading,
          ['w-full']: stretch,
          ['p-[15px] gap-2 text-text']: size === 'default',
          ['p-2 gap-1 text-subtext']: size === 'small',
        },
        className,
      )}
      onClick={disabled ? undefined : onClick}
      {...rest}
    >
      {isLoading ? <SpinnerIcon className="h-[18px] w-[18px]" /> : children}
    </button>
  );
};

export default Button;
