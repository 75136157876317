import { logEvent } from '@/analytics.ts';
import TokenSwapConfirmationDetails from '@/components/TokenSwapConfirmationDetails.tsx';
import { TokenMetadata } from '@/hooks/useTokenMetadataCatalog.ts';
import { SpinnerDots } from '@/icons/SpinnerDots';
import TransactionConfirmationPage from '@/pages/TransactionConfirmationPage.tsx';
import { dialectApiTrpcClient } from '@/shared/api/dialect/dialect-api-trpc-client.ts';
import { formatTokenAmountPrecise } from '@/shared/utils/formatting';
import { useInitData } from '@vkruglikov/react-telegram-web-app';
import { FC, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

export interface TokenSwapTransactionConfirmationPageNavigationState {
  action: 'Buy' | 'Sell';
  payingToken: TokenMetadata;
  receivingToken: TokenMetadata;
  referenceAccount: string;
}

const TokenSwapTransactionConfirmationPage: FC = () => {
  const [, initData] = useInitData();
  const location = useLocation();

  const [realSwapResults, setRealSwapResults] = useState<{
    paidAmount: number;
    receivedAmount: number;
  }>();

  const { action, payingToken, receivingToken, referenceAccount } =
    location.state as TokenSwapTransactionConfirmationPageNavigationState;

  const onSuccess = useCallback(
    async (txId: string) => {
      if (!initData) {
        console.warn(`Sharing not available, initData not found`);
        return;
      }

      const shareSwapTxRes =
        await dialectApiTrpcClient.share.getTxResult.mutate({
          txId,
          initData,
          tokenSwap: {
            payingToken: {
              symbol: payingToken.symbol,
              mint: payingToken.mintAddress,
            },
            receivingToken: {
              symbol: receivingToken.symbol,
              mint: receivingToken.mintAddress,
            },
          },
        });
      setRealSwapResults(shareSwapTxRes);

      logEvent('swap_tokens', {
        status: 'confirm_success',
        reference_account: referenceAccount,
        tx_id: txId,
        paid_amount: shareSwapTxRes.paidAmount,
        received_amount: shareSwapTxRes.receivedAmount,
      });
    },
    [initData, payingToken, receivingToken, referenceAccount],
  );

  const onFail = useCallback(() => {
    logEvent('swap_tokens', {
      status: 'confirm_fail',
      reference_account: referenceAccount,
    });
  }, [referenceAccount]);

  const onRetry = useCallback(() => {
    logEvent('swap_tokens', {
      status: 'confirm_retry',
      reference_account: referenceAccount,
    });
  }, [referenceAccount]);

  return (
    <TransactionConfirmationPage
      referenceAccount={referenceAccount}
      onFail={onFail}
      onRetry={onRetry}
      onSuccess={onSuccess}
    >
      {realSwapResults ? (
        <TokenSwapConfirmationDetails
          action={action}
          payingToken={payingToken}
          paidAmount={formatTokenAmountPrecise(realSwapResults.paidAmount)}
          receivingToken={receivingToken}
          receivedAmount={formatTokenAmountPrecise(
            realSwapResults.receivedAmount,
          )}
        />
      ) : (
        <div className="flex flex-col items-center justify-center text-primary">
          <SpinnerDots />
        </div>
      )}
    </TransactionConfirmationPage>
  );
};

export default TokenSwapTransactionConfirmationPage;
