import { AppRoute } from '@/appRoute.ts';
import Header from '@/components/Header.tsx';
import TransactionSignTroubleshooting from '@/components/TransactionSignTroubleshooting.tsx';
import { ViewportFlex } from '@/components/ViewportFlex.tsx';
import useTelegramWebAppShowPopup from '@/hooks/telegram-web-app/useTelegramWebAppShowPopup.ts';
import useTelegramWebAppStartAppParam from '@/hooks/telegram-web-app/useTelegramWebAppStartAppParam.ts';
import useSolanaTxSignatureLookup from '@/hooks/useSolanaTxSignatureLookup.ts';
import useSolanaTxStatusLookup from '@/hooks/useSolanaTxStatusLookup.ts';
import ErrorIcon from '@/icons/ErrorIcon.tsx';
import { SpinnerDots } from '@/icons/SpinnerDots.tsx';
import SuccessIcon from '@/icons/SuccessIcon.tsx';
import { Button } from '@/shared/components';
import { FC, PropsWithChildren, ReactNode, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export interface TransactionConfirmationPageProps extends PropsWithChildren {
  referenceAccount: string;
  onSuccess?: (txId: string) => void;
  onFail?: () => void;
  onRetry?: () => void;
  loadingIndicator?: ReactNode;
}

const TransactionConfirmationPage: FC<TransactionConfirmationPageProps> = ({
  children,
  referenceAccount,
  onFail,
  onRetry,
  onSuccess,
  loadingIndicator,
}) => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const showPopup = useTelegramWebAppShowPopup();

  const { txSignature, isFindingTxSignature, isTxSignatureFound } =
    useSolanaTxSignatureLookup({
      referenceAccount,
    });

  const { isConfirmingTx, isTxSucceed } = useSolanaTxStatusLookup({
    txSignature,
  });

  if (!referenceAccount) {
    showPopup({
      message: `Transaction reference not found`,
    });
    navigate(AppRoute.Home.url(), { replace: true });
  }

  const location = useLocation();
  const { startAppParam } = useTelegramWebAppStartAppParam();

  const isProgress = isFindingTxSignature || isConfirmingTx;
  const isFailed = !isTxSucceed && !isProgress;

  const debug = useMemo(
    () => (
      <div className="flex flex-col text-xs items-center justify-center gap-0.5 py-2">
        <span className="text-xs">{searchParams.toString()}</span>
        <span>{`isFindingTxSignature: ${isFindingTxSignature}`}</span>
        <span>{`isTxSignatureFound: ${isTxSignatureFound}`}</span>
        <span>{`isConfirmingTx: ${isConfirmingTx}`}</span>
        <span>{`isTxSucceed: ${isTxSucceed}`}</span>
        <span>{`startApp.Loc: ${startAppParam?.location}`}</span>
        <span>{`location: ${location}`}</span>
        <span>{`locationPath: ${location.pathname}`}</span>
        <span>{`locationSearch: ${location.search}`}</span>
      </div>
    ),
    [
      isFindingTxSignature,
      isTxSignatureFound,
      isConfirmingTx,
      isTxSucceed,
      searchParams,
      startAppParam?.location,
      location,
    ],
  );

  const waitingOnPhantom = (
    <section className="flex flex-col items-center justify-center w-full">
      {loadingIndicator ?? <SpinnerDots size={26} />}
      <div className="flex flex-col py-3 gap-1">
        <span className="text-h2 font-bold text-center">
          Waiting on Phantom...
        </span>
        <span className="text-subtext text-secondary text-center whitespace-pre-line">
          {`Phantom's self-custody feature is in Beta.
           You might experience a slight delay.`}
        </span>
      </div>
    </section>
  );

  const waitingOnPhantomState = (
    <>
      <Header showAlertsHint={false} showSettings={false} />
      <div className="flex flex-1 flex-col w-full justify-between">
        <div className="flex flex-1">{waitingOnPhantom}</div>
        <div className="flex flex-col justify-end">
          <div className="flex w-full mb-2">
            <TransactionSignTroubleshooting
              tryAgainAction={() => {
                onRetry?.();
                navigate(-1);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );

  const { icon, title, text, button } = useMemo(() => {
    if (isTxSucceed) {
      return {
        icon: <SuccessIcon />,
        title: 'Success',
        text: 'Your transaction was successful!',
      };
    }
    if (isConfirmingTx) {
      return {
        icon: <SpinnerDots size={42} />,
        title: 'Confirming Transaction',
        text: 'Your transaction is being confirmed',
      };
    }
    return {
      icon: <ErrorIcon />,
      title: 'Error',
      text: 'Transaction Failed',
      button: (
        <Button
          variant="secondary"
          onClick={() => {
            onRetry?.();
            navigate(-1);
          }}
        >
          Try Again
        </Button>
      ),
    };
  }, [isTxSucceed, isConfirmingTx, navigate, onRetry]);

  useEffect(() => {
    if (isTxSucceed && txSignature) {
      onSuccess?.(txSignature);
      return;
    }

    if (isFailed) {
      onFail?.();
    }
  }, [isFailed, isTxSucceed, onFail, onSuccess, txSignature]);

  const confirmingTransactionState = (
    <>
      <div className="flex flex-col justify-center flex-1 w-full items-center relative">
        {icon}
        <div className="flex w-full flex-col gap-1 items-center justify-center py-3">
          <span className="text-h2">{title}</span>
          <span className="text-secondary text-subtext text-center whitespace-pre-line">
            {text}
          </span>
          {isTxSucceed && children}
        </div>
        <div className="flex w-full py-2 absolute bottom-0">{button}</div>
      </div>
    </>
  );

  return (
    <ViewportFlex>
      <div className="flex w-full flex-col items-center p-3 pt-0">
        <div className="flex flex-col flex-1 w-full">
          {!isTxSignatureFound
            ? waitingOnPhantomState
            : confirmingTransactionState}
        </div>
        <Button
          variant="brand"
          onClick={() => {
            navigate(AppRoute.Home.url(), { replace: true });
          }}
        >
          <span>Done</span>
        </Button>
      </div>
    </ViewportFlex>
  );
};

export default TransactionConfirmationPage;
