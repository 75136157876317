import { cn } from '@/shared/utils/cn.ts';
import { FC, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Tab: FC<{ selected: boolean; to: string; children: string }> = ({
  to,
  children,
  selected,
}) => {
  return (
    <Link
      to={to}
      relative="path"
      className={cn(
        'flex flex-1 text-sm font-semibold items-center justify-center rounded-lg relative z-10 transition-colors duration-300 py-2',
        {
          ['text-tertiary']: !selected,
        },
      )}
      replace={true}
    >
      {children}
    </Link>
  );
};

export enum TabType {
  Tokens,
  Collectibles,
}

const getTabType = (path: string): TabType => {
  if (path === '/collectibles') {
    return TabType.Collectibles;
  }

  return TabType.Tokens;
};

export const TabSelector = () => {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(getTabType(location.pathname));

  useEffect(() => {
    setSelectedTab(getTabType(location.pathname));
  }, [location.pathname]);

  return (
    <section className="flex p-1 gap-2 rounded-xl border border-primary bg-secondary relative">
      <Tab to="" selected={selectedTab === TabType.Tokens}>
        Tokens
      </Tab>
      <Tab to="collectibles" selected={selectedTab === TabType.Collectibles}>
        Collectibles
      </Tab>
      <div
        className={cn(
          'bg-primary absolute rounded-lg w-[calc(50%-8px)] top-1 bottom-1 transition-transform duration-300', // 4px is the gap between tabs, +4 for x padding
          {
            ['translate-x-0']: selectedTab === TabType.Tokens,
            ['translate-x-[calc(100%+8px)]']:
              selectedTab === TabType.Collectibles,
          },
        )}
      />
    </section>
  );
};
