import {
  ActiveListingsByCollectionResponseDto,
  NftsResponseDto,
  SimpleHashNftDto,
  TrendingCollectionsResponseDto,
} from '@/shared/api/simplehash/types.ts';

export enum SimplehashOrderBy {
  ListingTimestampAsc = 'listing_timestamp_asc',
  ListingTimestampDesc = 'listing_timestamp_desc',
  PriceAsc = 'price_asc',
  PriceDesc = 'price_desc',
}

export enum SimplehashTimePeriod {
  TwentyFourHours = '24h',
  Day = '1d',
  Week = '7d',
  Month = '30d',
}

const createSimplehashApi = (apiKey: string) => {
  const getNft = async (
    mintAddress: string,
    chain = 'solana',
    tokenId = '0',
  ): Promise<SimpleHashNftDto> => {
    const response = await fetch(
      `https://api.simplehash.com/api/v0/nfts/${chain}/${mintAddress}/${tokenId}`,
      {
        headers: {
          accept: 'application/json',
          'X-API-KEY': apiKey,
        },
      },
    );

    if (!response.ok) {
      throw new Error('Failed to fetch NFT');
    }

    return await response.json();
  };
  const getNftsForWallet = async (
    address: string,
  ): Promise<NftsResponseDto> => {
    const params = new URLSearchParams({
      wallet_addresses: address,
      chains: 'solana',
    });

    const response = await fetch(
      'https://api.simplehash.com/api/v0/nfts/owners?' + params,
      {
        headers: {
          accept: 'application/json',
          'X-API-KEY': apiKey,
        },
      },
    );

    if (!response.ok) {
      throw new Error('Failed to fetch NFTs for wallet');
    }

    return await response.json();
  };

  const getTrendingCollections = async (
    options: { timePeriod: SimplehashTimePeriod } = {
      timePeriod: SimplehashTimePeriod.Week,
    },
  ): Promise<TrendingCollectionsResponseDto> => {
    const params = new URLSearchParams({
      chains: 'solana',
      limit: '100',
      time_period: options.timePeriod,
    });

    const response = await fetch(
      'https://api.simplehash.com/api/v0/nfts/collections/trending?' + params,
      {
        headers: {
          accept: 'application/json',
          'X-API-KEY': apiKey,
        },
      },
    );

    if (!response.ok) {
      throw new Error('Failed to fetch trending collections');
    }

    return await response.json();
  };

  const getActiveListingsByCollection = async (
    collectionId: string,
    options: { orderBy: SimplehashOrderBy } = {
      orderBy: SimplehashOrderBy.PriceAsc,
    },
  ): Promise<ActiveListingsByCollectionResponseDto> => {
    const params = new URLSearchParams({
      include_nft_details: '1',
      limit: '100',
      order_by: options.orderBy,
    });

    const response = await fetch(
      `https://api.simplehash.com/api/v0/nfts/listings/collection/${collectionId}?${params}`,
      {
        headers: {
          accept: 'application/json',
          'X-API-KEY': apiKey,
        },
      },
    );

    if (!response.ok) {
      throw new Error('Failed to fetch trending collections');
    }

    return await response.json();
  };

  const getNftsByTokenList = async (
    ids: string[],
  ): Promise<NftsResponseDto> => {
    const response = await fetch(
      `https://api.simplehash.com/api/v0/nfts/assets?nft_ids=${ids.join(',')}`,
      {
        headers: {
          accept: 'application/json',
          'X-API-KEY': apiKey,
        },
      },
    );

    if (!response.ok) {
      throw new Error('Failed to fetch NFTs');
    }

    return await response.json();
  };

  return {
    getNft,
    getNftsForWallet,
    getTrendingCollections,
    getActiveListingsByCollection,
    getNftsByTokenList,
  };
};

const simpleHashApi = createSimplehashApi(
  import.meta.env.VITE_SIMPLEHASH_API_KEY as string,
);

export default simpleHashApi;
