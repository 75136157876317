import useTokensMarketData, {
  UseTokensMarketDataValue,
} from '@/hooks/useTokensMarketData.ts';
import useWalletTokensRaw from '@/hooks/useWalletTokensRaw.ts';
import { useMemo } from 'react';

const useWalletTokensMarketData = (): UseTokensMarketDataValue => {
  const { walletTokens } = useWalletTokensRaw();
  const mintAddresses = useMemo(
    () => walletTokens.map((token) => token.mintAddress),
    [walletTokens],
  );

  const { tokensMarketData, isTokensMarketDataLoading } =
    useTokensMarketData(mintAddresses);

  return {
    tokensMarketData,
    isTokensMarketDataLoading,
  };
};

export default useWalletTokensMarketData;
