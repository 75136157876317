import { Collection, MarketplacePrice, Nft } from '@/hooks/nfts/types.ts';
import miniAppApi from '@/shared/api/dialect/mini-app-api.ts';
import {
  SimpleHashNftCollectionDto,
  SimpleHashNftDto,
} from '@/shared/api/simplehash/types.ts';
import { ArrayElement } from '@/shared/utils/typing.ts';
import { SOL } from '@raydium-io/raydium-sdk';

export const TENSOR_FEE_BPS = 150; // both for NFT and cNFT

export const getTotalPrice = (price: number, royaltyBps: number): number => {
  const royalty = (price * royaltyBps) / 10000;
  const marketPlaceFee = (price * TENSOR_FEE_BPS) / 10000;

  return price + royalty + marketPlaceFee;
};

export const getMarketplacesLowestPrice = (prices: MarketplacePrice[]) => {
  let min = Number.MAX_SAFE_INTEGER;
  let index = -1;
  prices.forEach((marketplace, i) => {
    if (marketplace.value < min) {
      min = marketplace.value;
      index = i;
    }
  });

  const lowest = prices[index];
  if (!lowest) {
    return undefined;
  }

  const rawValue = lowest.value / Math.pow(10, lowest.decimals);

  return `${rawValue}${lowest.token.symbol}`;
};

type DialectCollectible = ArrayElement<
  Awaited<ReturnType<typeof miniAppApi.nfts.getTrendingNfts>>['nfts']
>;

export const mapDialectCollectibleToNft = (
  collectible: DialectCollectible,
): Nft => ({
  address: collectible.mint,
  collectionAddress: collectible.verifiedCollection,
  name: collectible.name,
  img: {
    url: collectible.imageUri,
  },
  prices:
    collectible.listing.price !== null
      ? [
          {
            isActionable: true,
            seller: collectible.listing.seller,
            marketplaceId: 'tensor', // TODO: adjust based on listing sources
            marketplaceName: 'Tensor',
            value: parseInt(collectible.listing.price, 10),
            decimals: SOL.decimals,
            token: {
              name: SOL.name,
              symbol: SOL.symbol,
            },
          },
        ]
      : [],
  metadata: {
    collectionTensorId: collectible.collId,
    isCollectionVerified: true, // assumption here, since we return trending collections from tensor
    attributes: collectible.attributes.map((a) => ({
      traitType: a.traitType,
      value: a.value,
      totalCount: a.n,
    })),
    rarityRank: collectible.rarityRank,
    royaltyBps: collectible.royaltyBps,
    collectionTotalSupply: collectible.totalMintsNum,
  },
});

export const mapSimplehashNftToNft = (nft: SimpleHashNftDto): Nft => ({
  address: nft.contract_address,
  collectionAddress: nft.collection.collection_id,
  name: nft.name,
  previews: {
    small: nft.previews.image_small_url,
    medium: nft.previews.image_medium_url,
    large: nft.previews.image_large_url,
    blurhash: nft.previews.blurhash,
    predominantColor: nft.previews.predominant_color,
  },
  img: {
    url: nft.image_url,
    width: nft.image_properties.width,
    height: nft.image_properties.height,
  },
  ...(nft.description ? { description: nft.description } : {}),
  prices: nft.collection.floor_prices.map((fp) => ({
    marketplaceId: fp.marketplace_id,
    marketplaceName: fp.marketplace_name,
    valueInUsd: fp.value_usd_cents,
    value: fp.value,
    decimals: fp.payment_token.decimals,
    token: {
      name: fp.payment_token.name,
      symbol: fp.payment_token.symbol,
    },
    isActionable: false,
  })),
  metadata: {
    isCollectionVerified: nft.collection.marketplace_pages.some(
      (page) => page.verified,
    ),
    attributes: nft.extra_metadata.attributes?.map(
      (attr: { trait_type: string; value: string; display_type: any }) => ({
        traitType: attr.trait_type,
        value: attr.value,
      }),
    ),
    rarityRank: nft.rarity.rank,
    royaltyBps:
      nft.extra_metadata.seller_fee_basis_points ??
      nft.royalty[0]?.total_creator_fee_basis_points,
    collectionTotalSupply: nft.collection.total_quantity, // this can be inaccurate, better to use tensor data
  },
});

export const mapSimplehashCollectionToCollection = (
  collection: SimpleHashNftCollectionDto,
): Collection => ({
  address: collection.collection_id,
  name: collection.name,
  ...(collection.description ? { description: collection.description } : {}),
  ...(collection.image_url ? { imageUrl: collection.image_url } : {}),
  ...(collection.banner_image_url
    ? { bannerUrl: collection.banner_image_url }
    : {}),
  floorPrices: collection.floor_prices.map((fp) => ({
    marketplaceId: fp.marketplace_id,
    marketplaceName: fp.marketplace_name,
    valueInUsd: fp.value_usd_cents,
    value: fp.value,
    decimals: fp.payment_token.decimals,
    token: {
      name: fp.payment_token.name,
      symbol: fp.payment_token.symbol,
    },
    isActionable: false,
  })),
});
