import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#AB9FF2"
      d="M1.89 14.635c2.039 0 3.57-1.773 4.485-3.173-.111.31-.173.62-.173.917 0 .818.47 1.4 1.396 1.4 1.272 0 2.631-1.115 3.335-2.317a1.77 1.77 0 0 0-.074.483c0 .57.321.93.976.93 2.063 0 4.139-3.657 4.139-6.856 0-2.491-1.26-4.686-4.423-4.686C5.991 1.333 0 8.127 0 12.515c0 1.723.927 2.12 1.89 2.12Zm7.746-8.888c0-.62.346-1.054.853-1.054.494 0 .84.434.84 1.054 0 .62-.346 1.066-.84 1.066-.507 0-.853-.447-.853-1.066Zm2.644 0c0-.62.346-1.054.852-1.054.495 0 .84.434.84 1.054 0 .62-.345 1.066-.84 1.066-.506 0-.852-.447-.852-1.066Z"
    />
  </svg>
);

export default SvgComponent;
