import { AppRoute } from '@/appRoute.ts';
import useTelegramWebApp from '@/hooks/telegram-web-app/useTelegramWebApp.ts';
import useTelegramWebAppInitData from '@/hooks/telegram-web-app/useTelegramWebAppInitData.ts';
import useTelegramWebAppShowPopup from '@/hooks/telegram-web-app/useTelegramWebAppShowPopup.ts';
import {
  jupiterSwapProvider,
  raydiumSwapProvider,
} from '@/hooks/token-swap/swap-providers.ts';
import { Quote, QuoteProvider } from '@/hooks/token-swap/types.ts';
import { TokenMetadata } from '@/hooks/useTokenMetadataCatalog.ts';
import useWallet from '@/hooks/useWallet.ts';

import { logEvent } from '@/analytics.ts';
import useTokenPrices from '@/hooks/useTokenPrices.ts';
import { TokenSwapTransactionConfirmationPageNavigationState } from '@/pages/TokenSwapTransactionConfirmationPage.tsx';
import { dialectApiTrpcClient } from '@/shared/api/dialect/dialect-api-trpc-client.ts';
import { isDeviceSupported } from '@/shared/utils/device-support.ts';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export interface UseTokenSwapArgs {
  action: 'Buy' | 'Sell';
  quote?: Quote | null;
  payingToken?: TokenMetadata | null;
  payingAmount: string;
  receivingToken?: TokenMetadata | null;
  receivingAmount: string;
  priorityFeeLamports: number;
}

export interface UseTokenSwapValue {
  swapTokens: () => Promise<void>;
  isTokenSwapping: boolean;
  isTokenSwapSucceeded: boolean;
  error: Error | null;
}

const useTokenSwap = (args: UseTokenSwapArgs): UseTokenSwapValue => {
  const {
    action,
    quote,
    payingToken,
    payingAmount,
    receivingToken,
    receivingAmount,
    priorityFeeLamports,
  } = args;

  const [initDataUnsafe, initData] = useTelegramWebAppInitData();

  const navigate = useNavigate();
  const { openLink, close } = useTelegramWebApp();
  const webAppShowPopup = useTelegramWebAppShowPopup();

  const { walletAddress, isWalletLoading } = useWallet();
  const [isTokenSwapping, setIsTokenSwapping] = useState(false);
  const [isTokenSwapSucceeded, setIsTokenSwapSucceeded] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const showPopup = useTelegramWebAppShowPopup();
  const { tokenPrices: payingTokenPrices } = useTokenPrices(
    payingToken?.mintAddress ? [payingToken.mintAddress] : [],
    { includeUnknown: true },
  );
  const { tokenPrices: receivingTokenPrices } = useTokenPrices(
    receivingToken?.mintAddress ? [receivingToken.mintAddress] : [],
    { includeUnknown: true },
  );

  const executeSwap = async () => {
    if (!isDeviceSupported) {
      showPopup({
        title: 'Desktop coming soon',
        message: 'Please try again on mobile.',
        buttons: [{ id: 'ok', type: 'ok', text: 'Ok' }],
      });
      return;
    }
    if (
      !walletAddress ||
      !initData ||
      !initDataUnsafe ||
      !quote ||
      !payingToken ||
      !receivingToken
    ) {
      return;
    }
    setIsTokenSwapping(true);
    try {
      const { tx } =
        quote.provider === QuoteProvider.JUPITER
          ? await jupiterSwapProvider(walletAddress, quote, priorityFeeLamports)
          : await raydiumSwapProvider(walletAddress, quote);
      const { deeplink, referenceAccount } =
        await dialectApiTrpcClient.phantom.prepareSignAndSendTx.mutate({
          serializedTx: tx,
          initData,
          metadata: {
            type: 'token_swap',
            tokenSwap: {
              payingToken: {
                symbol: payingToken.symbol,
                mint: payingToken.mintAddress,
              },
              receivingToken: {
                symbol: receivingToken.symbol,
                mint: receivingToken.mintAddress,
              },
            },
          },
        });

      const payingTokenUsdPrice = payingTokenPrices?.[payingToken.mintAddress];
      const receivingTokenUsdPrice =
        receivingTokenPrices?.[receivingToken.mintAddress];

      const payingTokenUsdAmount =
        payingTokenUsdPrice?.price !== undefined
          ? (payingTokenUsdPrice.price * Number(quote.inAmount)) /
            10 ** payingToken.decimals
          : undefined;
      const receivingTokenUsdAmount =
        receivingTokenUsdPrice?.price !== undefined
          ? (receivingTokenUsdPrice.price * Number(quote.outAmount)) /
            10 ** receivingToken.decimals
          : undefined;

      logEvent('swap_tokens', {
        status: 'initiated',
        action: action.toLowerCase(),
        paying_token: payingToken.symbol,
        paying_token_mint_address: payingToken.mintAddress,
        paying_token_usd_price: payingTokenUsdPrice?.price,
        paying_usd_amount: payingTokenUsdAmount,
        paying_amount_ui: Number(quote.inAmount) / 10 ** payingToken.decimals,
        paying_amount_raw: Number(quote.inAmount),
        paying_token_decimals: payingToken.decimals,
        receiving_token: receivingToken.symbol,
        receiving_token_mint_address: receivingToken.mintAddress,
        receiving_token_usd_price: receivingTokenUsdPrice?.price,
        receiving_amount_ui:
          Number(quote.outAmount) / 10 ** receivingToken.decimals,
        receiving_amount_raw: Number(quote.outAmount),
        receiving_token_decimals: receivingToken.decimals,
        receiving_usd_amount: receivingTokenUsdAmount,
        swap_mode: quote.originalQuote.swapMode,
        swap_provider: quote.provider,
        reference_account: referenceAccount,
      });
      openLink(deeplink);
      const navState: TokenSwapTransactionConfirmationPageNavigationState = {
        action,
        referenceAccount,
        payingToken,
        receivingToken,
      };
      setTimeout(() => {
        navigate(AppRoute.TokenSwapTransactionConfirmation.url(), {
          state: navState,
        });
      }, 1000);
    } catch (e) {
      const err = e as Error;
      await webAppShowPopup({
        message: err.message || 'Something went wrong',
      });
    } finally {
      setIsTokenSwapping(false);
    }
  };

  const swapTokens = useCallback(async () => {
    if (!walletAddress || !initData) {
      return;
    }
    try {
      setIsTokenSwapping(true);
      await executeSwap();
      setError(null);
      setIsTokenSwapSucceeded(true);
    } catch (e) {
      const err = e as Error;
      console.error(`Transaction error: ${err.message}`);
      setError(err);
    } finally {
      setIsTokenSwapping(false);
    }
  }, [
    quote,
    walletAddress,
    initData,
    payingToken,
    receivingToken,
    payingAmount,
    receivingAmount,
  ]);

  return {
    isTokenSwapping,
    isTokenSwapSucceeded,
    swapTokens,
    error,
  };
};

export default useTokenSwap;
