import { AppRoute } from '@/appRoute.ts';
import useTelegramWebAppInitData from '@/hooks/telegram-web-app/useTelegramWebAppInitData.ts';
import useTelegramWebAppShowPopup from '@/hooks/telegram-web-app/useTelegramWebAppShowPopup.ts';
import { useTokenDetails } from '@/hooks/token-swap/useTokenDetails.ts';
import {
  defaultToken,
  defaultTokenAdditional,
} from '@/hooks/useTokenDefaults.ts';
import useTokenMetadataCatalog from '@/hooks/useTokenMetadataCatalog.ts';
import useWalletTokenAlerts from '@/hooks/useWalletTokenAlerts.ts';
import SpinnerIcon from '@/icons/SpinnerIcon.tsx';
import miniAppApi from '@/shared/api/dialect/mini-app-api.ts';
import { Button, PulseLoader, TokenLogo } from '@/shared/components';
import { shortenAddress } from '@/shared/utils/wallet.ts';
import {
  faArrowDown,
  faArrowUp,
  faBell,
  faBellSlash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { TokenDetailsContext } from '../model.ts';

export const TokenDetailsHeader = () => {
  const { mintAddress } = useContext(TokenDetailsContext);
  const [, initData] = useTelegramWebAppInitData();
  const { tokenMetadata, isTokensLoading } = useTokenMetadataCatalog({
    strict: true,
  });
  const { token, isTokenLoading } = useTokenDetails(mintAddress);
  const {
    walletTokensAlerts = {},
    isWalletTokensAlertsLoading,
    refreshWalletTokensAlerts,
  } = useWalletTokenAlerts();

  const tokenAlert = useMemo(
    () => walletTokensAlerts[mintAddress],
    [walletTokensAlerts, mintAddress],
  );

  const isJupiterToken = useMemo(
    () => tokenMetadata[mintAddress],
    [tokenMetadata, mintAddress],
  );

  const navigate = useNavigate();

  const showPopup = useTelegramWebAppShowPopup();

  const goToBuy = useCallback(() => {
    navigate(
      AppRoute.Buy.url({
        payingTokenMintAddress:
          mintAddress === defaultTokenAdditional.mintAddress
            ? defaultToken.mintAddress!
            : defaultTokenAdditional.mintAddress!,
        receivingTokenMintAddress: mintAddress,
      }),
    );
  }, [navigate, mintAddress]);

  const goToSell = useCallback(() => {
    navigate(
      AppRoute.Sell.url({
        payingTokenMintAddress: mintAddress,
        receivingTokenMintAddress:
          mintAddress === defaultTokenAdditional.mintAddress
            ? defaultToken.mintAddress!
            : defaultTokenAdditional.mintAddress!,
      }),
    );
  }, [navigate, mintAddress]);

  const onAlertButtonPress = useCallback(async () => {
    if (!initData) {
      console.warn(`Alert not available, initData not found`);
      return;
    }
    const newEnabledState = !tokenAlert?.enabled;
    await miniAppApi.alerts.updateWalletTokenAlert(
      initData,
      mintAddress,
      newEnabledState,
    );
    refreshWalletTokensAlerts();
    showPopup({
      title: `${token?.symbol} alerts are now ${newEnabledState ? 'on' : 'off'}`,
      message: `${newEnabledState ? `Operator will send you alerts about changes in ${token?.symbol} based on your trading history.` : `You will no longer receive notifications about ${token?.symbol}.`}`,
      buttons: [{ id: 'ok', type: 'ok', text: 'Ok' }],
    });
  }, [initData, mintAddress, tokenAlert?.enabled, token?.symbol]);

  const alertButtonIcon = useCallback(() => {
    if (isWalletTokensAlertsLoading) {
      return <SpinnerIcon className="h-[16px] w-[16px]" />;
    }
    if (tokenAlert?.enabled) {
      return <FontAwesomeIcon icon={faBell} className="w-4 h-4" size="sm" />;
    }
    return <FontAwesomeIcon icon={faBellSlash} className="w-4 h-4" size="sm" />;
  }, [isWalletTokensAlertsLoading, tokenAlert?.enabled]);

  return (
    <header className="flex flex-row gap-3 w-full items-center">
      <div className="h-8 w-8">
        <TokenLogo imageUrl={token?.imageUrl} isLoading={isTokenLoading} />
      </div>
      <div className="flex flex-col flex-1">
        <span className="text-text font-semibold">
          {token?.name ?? shortenAddress(mintAddress)}
        </span>
        <span className="text-caption font-medium text-secondary">
          {isTokenLoading ? (
            <PulseLoader className="bg-secondary h-[0.6875rem] w-10 rounded-md mt-[0.1875rem]" /> // mt = line-height - font-size
          ) : (
            token?.symbol ?? 'UNKNOWN'
          )}
        </span>
      </div>
      <div className="flex flex-row gap-2 items-center">
        <Button
          variant={isTokenLoading ? 'disabled' : 'secondary'}
          size="small"
          stretch={false}
          disabled={isTokenLoading}
          onClick={goToBuy}
        >
          Buy
          <FontAwesomeIcon
            icon={faArrowDown}
            className="w-3.5 h-3.5"
            size="sm"
          />
        </Button>
        <Button
          variant={isTokenLoading ? 'disabled' : 'secondary'}
          size="small"
          stretch={false}
          disabled={isTokenLoading}
          onClick={goToSell}
        >
          Sell
          <FontAwesomeIcon icon={faArrowUp} className="w-3.5 h-3.5" size="sm" />
        </Button>
        {isJupiterToken ? (
          <Button
            disabled={isWalletTokensAlertsLoading}
            className="!rounded-full"
            onClick={onAlertButtonPress}
            variant={isWalletTokensAlertsLoading ? 'disabled' : 'secondary'}
            size="small"
          >
            {alertButtonIcon()}
          </Button>
        ) : null}
      </div>
    </header>
  );
};
