import birdeyeApi from '@/shared/api/birdseye/api.ts';
import { BirdeyePriceHistoryType } from '@/shared/api/birdseye/types.ts';
import useSWR from 'swr';

const DAY_IN_SECONDS = 24 * 60 * 60;

export const useTokenPriceHistory = (
  mintAddress: string,
  {
    type,
    timeTo,
    timeFrom,
    refreshInterval = 10000,
  }: {
    timeFrom: number | 'day-before'; // seconds
    timeTo: number | 'now'; // seconds
    type: BirdeyePriceHistoryType;
    refreshInterval?: number;
  },
) => {
  const { data, isLoading } = useSWR(
    ['TOKEN_PRICE_HISTORY', mintAddress, type, refreshInterval],
    async () => {
      const now = Math.floor(new Date().getTime() / 1000);

      const from = timeFrom === 'day-before' ? now - DAY_IN_SECONDS : timeFrom;
      const to = timeTo === 'now' ? now : timeTo;

      const resp = await birdeyeApi.getTokenPriceHistory(mintAddress, {
        type,
        timeFrom: from,
        timeTo: to,
      });

      return { items: resp.data.items, from, to };
    },
    { refreshInterval },
  );

  return {
    priceHistory: data?.items ?? [],
    from: data?.from,
    to: data?.to,
    isLoading,
  };
};
