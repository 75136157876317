import { Collection, Nft } from '@/hooks/nfts/types.ts';
import {
  mapDialectCollectibleToNft,
  mapSimplehashCollectionToCollection,
  mapSimplehashNftToNft,
} from '@/hooks/nfts/utils.ts';
import useTelegramWebAppInitData from '@/hooks/telegram-web-app/useTelegramWebAppInitData.ts';
import miniAppApi from '@/shared/api/dialect/mini-app-api.ts';
import simpleHashApi from '@/shared/api/simplehash/api.ts';
import { useMemo } from 'react';
import useSWR from 'swr';

interface UseCollectibleDetailsOptions {
  collectionId?: string;
  mintAddress?: string;
}

interface UseCollectibleDetailsValue {
  collectible: Nft | null;
  collection: Collection | null;
  isCollectibleLoading: boolean;
}

export const useCollectibleDetails = ({
  collectionId,
  mintAddress,
}: UseCollectibleDetailsOptions): UseCollectibleDetailsValue => {
  const [, initData] = useTelegramWebAppInitData();
  const { data, isLoading } = useSWR(
    collectionId && mintAddress
      ? ['COLLECTIBLE_DETAILS_TENSOR', collectionId, mintAddress]
      : null,
    async () => {
      if (!initData || !collectionId || !mintAddress) {
        return null;
      }
      const tensorNft = await miniAppApi.nfts.getNftDetails(
        initData,
        collectionId,
        mintAddress,
      );

      return mapDialectCollectibleToNft(tensorNft);
    },
    {
      refreshInterval: 10000,
    },
  );

  const { data: staticData, isLoading: isStaticLoading } = useSWR(
    mintAddress ? ['COLLECTIBLE_DETAILS_SIMPLEHASH', mintAddress] : null,
    async () => {
      if (!mintAddress) {
        return null;
      }

      const simplehashNft = await simpleHashApi.getNft(mintAddress);

      return {
        nft: mapSimplehashNftToNft(simplehashNft),
        collection: mapSimplehashCollectionToCollection(
          simplehashNft.collection,
        ),
      };
    },
  );

  const collectible = useMemo(
    () =>
      staticData
        ? {
            ...staticData.nft,
            prices: data?.prices ?? [], // interested in actionable listing
            metadata: {
              ...staticData?.nft.metadata,
              attributes:
                data?.metadata.attributes ?? staticData.nft.metadata.attributes,
              rarityRank: data?.metadata.rarityRank,
              collectionSlug: data?.metadata.collectionTensorId,
              collectionTotalSupply: data?.metadata.collectionTotalSupply,
            },
          }
        : null,
    [data, staticData],
  );

  return {
    collectible,
    collection: staticData?.collection ?? null,
    isCollectibleLoading: isLoading || isStaticLoading,
  };
};
