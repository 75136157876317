import { solanaConnection } from '@/shared/api/solana/solana-api.ts';
import { useState } from 'react';
import useSWR from 'swr';

const refreshInterval = 2000;
const errorRetryCount = 10;

export interface UseSolanaTxStatusLookupArgs {
  txSignature: string | null;
}

export interface UseSolanaTxStatusLookupValue {
  isTxSucceed: boolean;
  isConfirmingTx: boolean;
}

const useSolanaTxStatusLookup = ({
  txSignature,
}: UseSolanaTxStatusLookupArgs): UseSolanaTxStatusLookupValue => {
  const [isRetryAttemptsExceeded, setIsRetryAttemptsExceeded] = useState(false);

  const { data, error, isLoading } = useSWR(
    txSignature && !isRetryAttemptsExceeded
      ? ['SOLANA_TX_STATUS_LOOKUP', txSignature]
      : null,
    async ([, signature]) => {
      console.log(`Looking up status for ${signature}`);
      const status = await solanaConnection.getSignatureStatus(signature, {
        searchTransactionHistory: true,
      });
      if (!status) {
        throw new Error('Transaction not confirmed yet');
      }
      if (status.value && status.value.err) {
        return 'failed';
      }
      if (
        status.value &&
        !status.value.err &&
        (status.value.confirmationStatus === 'finalized' ||
          status.value.confirmationStatus === 'confirmed')
      ) {
        return 'succeed';
      }
      throw new Error('Transaction not confirmed yet');
    },
    {
      refreshInterval: 0,
      dedupingInterval: refreshInterval,
      errorRetryInterval: refreshInterval,
      errorRetryCount: errorRetryCount,
      onErrorRetry: (_, key, config, revalidate, { retryCount }) => {
        if (retryCount >= errorRetryCount) {
          setIsRetryAttemptsExceeded(true);
        } else {
          setTimeout(
            () => revalidate({ retryCount }),
            config.errorRetryInterval,
          );
        }
      },
    },
  );

  return {
    isTxSucceed: data === 'succeed',
    isConfirmingTx:
      Boolean(txSignature) &&
      (isLoading || (!data && !isRetryAttemptsExceeded)),
  };
};

export default useSolanaTxStatusLookup;
