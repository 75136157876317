import { TokenMetadata } from '@/hooks/useTokenMetadataCatalog.ts';
import birdeyeApi from '@/shared/api/birdseye/api.ts';
import useSWR from 'swr';

interface UseTokenMetadataFallbackValue {
  tokenMetadata: Record<string, TokenMetadata>;
  isTokensLoading: boolean;
}

const useTokenMetadataFallback = (
  mintAddresses: string[],
): UseTokenMetadataFallbackValue => {
  const { data, isLoading } = useSWR(
    ['GET_TOKEN_FALLBACK_LIST', ...mintAddresses.sort()],
    async () => {
      const lookups = await Promise.all(
        mintAddresses.map((mintAddresses) =>
          birdeyeApi.getTokenMarketData(mintAddresses),
        ),
      );

      return Object.fromEntries(
        lookups
          .map((token) => {
            return {
              name: token.name,
              symbol: token.symbol,
              decimals: token.decimals,
              imageUrl: token.logoURI,
              mintAddress: token.address,
              showPriority: -1,
              infoLinks: {
                solscan: `https://solscan.io/token/${token.address}`,
                birdeye: `https://birdeye.so/token/${token.address}?chain=solana`,
              },
              isUnknown: true,
            };
          })
          .map((token) => [token.mintAddress, token]),
      );
    },
  );

  return {
    tokenMetadata: data ?? {},
    isTokensLoading: isLoading,
  };
};

export default useTokenMetadataFallback;
