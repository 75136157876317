export type Must<T> = {
  [P in keyof T]-?: NonNullable<T[P]>;
};

export type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export enum InputType {
  PAYING = 'paying',
  RECEIVING = 'receiving',
}
