import TokenSwapConfirmationDetails from '@/components/TokenSwapConfirmationDetails.tsx';
import { ViewportFlex } from '@/components/ViewportFlex.tsx';
import { Quote, useTokenSwap } from '@/hooks/token-swap';
import { TokenMetadata } from '@/hooks/useTokenMetadataCatalog.ts';
import Button from '@/shared/components/Button.tsx';
import { formatTokenAmountPrecise } from '@/shared/utils/formatting.ts';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';

export interface SwapConfirmationPageNavigationState {
  action: 'Buy' | 'Sell';
  payingToken: TokenMetadata;
  payingAmount: string;
  receivingToken: TokenMetadata;
  receivingAmount: string;
  quote: Quote;
}

const SwapConfirmationPage: FC = () => {
  const location = useLocation();
  const {
    action,
    payingToken,
    payingAmount,
    receivingToken,
    receivingAmount,
    quote,
  } = location.state as SwapConfirmationPageNavigationState;

  const { swapTokens, isTokenSwapping, error, isTokenSwapSucceeded } =
    useTokenSwap({
      action,
      payingToken,
      payingAmount,
      receivingToken,
      receivingAmount,
      quote,
      priorityFeeLamports: 0, // added to fix type errors, since page is unused
    });

  const title = `${action}`;

  return (
    <ViewportFlex>
      <div className="flex w-full flex-col justify-center p-4">
        <div className="flex flex-1 w-full flex-col justify-center items-center gap-5 pt-4">
          <span className="text-h2 font-bold">{title}</span>
          <TokenSwapConfirmationDetails
            action={action}
            payingToken={payingToken}
            paidAmount={formatTokenAmountPrecise(Number(payingAmount))}
            receivingToken={receivingToken}
            receivedAmount={formatTokenAmountPrecise(Number(receivingAmount))}
          />
        </div>
        <div className="flex flex-0">
          <Button
            disabled={isTokenSwapping}
            isLoading={isTokenSwapping}
            onClick={swapTokens}
          >
            Confirm
          </Button>
        </div>
      </div>
    </ViewportFlex>
  );
};

export default SwapConfirmationPage;
