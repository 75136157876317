import { AppRoute } from '@/appRoute.ts';
import Header from '@/components/Header.tsx';
import { NftCard } from '@/components/NftCard.tsx';
import { useWalletNfts } from '@/hooks/nfts/useWalletNfts.ts';
import {
  defaultToken,
  defaultTokenAdditional,
} from '@/hooks/useTokenDefaults.ts';
import useWalletTokensRaw from '@/hooks/useWalletTokensRaw.ts';
import { TokenOperationButton } from '@/pages/WalletPage/ui/TokenOperationButton.tsx';
import { TotalTokenBalance } from '@/pages/WalletPage/ui/TotalTokenBalance.tsx';
import PulseLoader from '@/shared/components/PulseLoader.tsx';
import { faArrowDown, faArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DispatchWithoutAction, FC, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { TabSelector, TokensList } from './ui';

const WalletPage: FC<{
  onChangeTransition?: DispatchWithoutAction;
}> = () => {
  return (
    <div className="w-full bg-primary h-screen">
      <div className="fixed w-full px-4 pb-2 left-0 top-0 bg-primary z-10">
        <Header />
      </div>
      <div className="px-4 pt-12 pb-4 overflow-y-scroll relative z-0">
        <div className="my-4">
          <TabSelector />
        </div>
        <div className="flex flex-col bg-primary">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export const WalletCollectiblesPage: FC<{
  onChangeTransition?: DispatchWithoutAction;
}> = () => {
  const { nfts, isNftsLoading } = useWalletNfts();
  const navigate = useNavigate();

  const tokenOperations = (
    <div className="flex w-full flex-col items-center gap-3">
      <div className="flex flex-row gap-6">
        <TokenOperationButton
          icon={<FontAwesomeIcon icon={faArrowDown} />}
          onClick={() => {
            navigate(AppRoute.TrendingCollectibles.url());
          }}
        >
          Buy
        </TokenOperationButton>
        <TokenOperationButton
          disabled
          icon={<FontAwesomeIcon icon={faArrowUp} />}
          onClick={() => {}}
        >
          Sell
        </TokenOperationButton>
      </div>
    </div>
  );

  return (
    <div className="mt-4">
      <section className="mb-6">{tokenOperations}</section>
      <section className="grid grid-cols-2 gap-2">
        {isNftsLoading ? (
          <>
            {Array.from(
              [0, 1, 2, 4].map((it) => (
                <PulseLoader
                  key={it}
                  className="aspect-square rounded-lg w-full bg-secondary"
                />
              )),
            )}
          </>
        ) : (
          nfts.map((nft) => <NftCard key={nft.address} nft={nft} />)
        )}
      </section>
    </div>
  );
};

export const WalletTokensPage = () => {
  const navigate = useNavigate();

  const { walletTokens, isWalletTokensLoading } = useWalletTokensRaw();

  const tokenOperations = useMemo(
    () => (
      <div className="flex w-full flex-row flex-wrap justify-center gap-2">
        <div className="flex flex-row gap-6">
          <TokenOperationButton
            icon={<FontAwesomeIcon icon={faArrowDown} />}
            onClick={() => {
              navigate(
                AppRoute.Buy.url({
                  payingTokenMintAddress: defaultTokenAdditional.mintAddress!,
                  receivingTokenMintAddress: defaultToken.mintAddress!,
                }),
              );
            }}
          >
            Buy
          </TokenOperationButton>
          <TokenOperationButton
            icon={<FontAwesomeIcon icon={faArrowUp} />}
            onClick={() =>
              navigate(
                AppRoute.Sell.url({
                  payingTokenMintAddress: defaultToken.mintAddress!,
                  receivingTokenMintAddress:
                    defaultTokenAdditional.mintAddress!,
                }),
              )
            }
          >
            Sell
          </TokenOperationButton>
        </div>
      </div>
    ),
    [navigate],
  );

  return (
    <section className="flex flex-col w-full">
      <section className="mb-4">
        <TotalTokenBalance />
      </section>
      <section className="mb-6">{tokenOperations}</section>
      <TokensList
        tokens={walletTokens}
        isLoading={isWalletTokensLoading && walletTokens.length === 0}
      />
    </section>
  );
};

export default WalletPage;
