import { logEvent } from '@/analytics.ts';
import { Nft } from '@/hooks/nfts/types.ts';
import { SpinnerDots } from '@/icons/SpinnerDots';
import TransactionConfirmationPage from '@/pages/TransactionConfirmationPage.tsx';
import { ImageWithBlurHash } from '@/shared/components';
import { useInitData } from '@vkruglikov/react-telegram-web-app';
import { FC, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

export interface NftTransactionConfirmationPageNavigationState {
  referenceAccount: string;
  nft: Nft;
}

const NftTransactionConfirmationPage: FC = () => {
  const [, initData] = useInitData();
  const location = useLocation();

  const { referenceAccount, nft } =
    location.state as NftTransactionConfirmationPageNavigationState;

  const onSuccess = useCallback(
    async (txId: string) => {
      if (!initData) {
        console.warn(`Sharing not available, initData not found`);
        return;
      }

      logEvent('nft_buy', {
        status: 'confirm_success',
        reference_account: referenceAccount,
        tx_id: txId,
      });
    },
    [initData, referenceAccount],
  );

  const onFail = useCallback(() => {
    logEvent('nft_buy', {
      status: 'confirm_fail',
      reference_account: referenceAccount,
    });
  }, [referenceAccount]);

  const onRetry = useCallback(() => {
    logEvent('nft_buy', {
      status: 'confirm_retry',
      reference_account: referenceAccount,
    });
  }, [referenceAccount]);

  return (
    <TransactionConfirmationPage
      referenceAccount={referenceAccount}
      onFail={onFail}
      onRetry={onRetry}
      onSuccess={onSuccess}
      loadingIndicator={
        <div className="w-16">
          <CollectibleImage nft={nft} isLoading={true} />
        </div>
      }
    >
      <div className="w-full py-2 pl-2 pr-4 border border-primary rounded-xl flex gap-3 items-center mt-2">
        <div className="h-12">
          <CollectibleImage nft={nft} isLoading={false} />
        </div>
        <div className="flex flex-col">
          <span className="font-semibold">{nft.name}</span>
          <span className="text-caption text-secondary font-medium">
            Buy via Tensor
          </span>
        </div>
      </div>
    </TransactionConfirmationPage>
  );
};

const CollectibleImage = ({
  nft,
  isLoading,
}: {
  nft: Nft;
  isLoading: boolean;
}) => {
  return (
    <div className="w-full h-full aspect-square relative rounded-lg overflow-hidden">
      {isLoading && (
        <div className="absolute inset-0 bg-white/50 flex items-center justify-center">
          <SpinnerDots size={24} />
        </div>
      )}
      <ImageWithBlurHash
        blurhash={nft.previews?.blurhash}
        url={nft.img.url}
        alt={nft.name}
        imageClassName="object-cover w-full"
      />
    </div>
  );
};

export default NftTransactionConfirmationPage;
