import { AppRoute } from '@/appRoute.ts';
import { ViewportFlex } from '@/components/ViewportFlex.tsx';
import useTelegramWebApp from '@/hooks/telegram-web-app/useTelegramWebApp.ts';
import useSmartMessage from '@/hooks/useSmartMessage.ts';
import { SpinnerDots } from '@/icons/SpinnerDots.tsx';
import { ActionType } from '@/shared/api/smart-messages/types.ts';
import { Button, PulseLoader } from '@/shared/components';
import { isDeviceSupported } from '@/shared/utils/device-support.ts';
import { toSentenceCase } from '@/shared/utils/formatting.ts';
import { faCheck, faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsx } from 'clsx';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const SmartMessagePage = () => {
  const { openLink } = useTelegramWebApp();
  const { smartMessageId } = useParams<{ smartMessageId: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!smartMessageId) {
      navigate(AppRoute.Home.subRoutes.HomeTokens.url(), { replace: true });
    }
  }, [navigate, smartMessageId]);

  const {
    smartMessage,
    handleSmartMessageAction,
    isExecutingSmartMessageAction,
  } = useSmartMessage({
    id: smartMessageId,
  });

  const skeleton = (
    <>
      <div className="flex flex-1 flex-col gap-4">
        <div className="flex flex-1 flex-col items-center justify-center gap-4">
          {/* start header*/}
          <div className="flex w-full flex-row justify-between items-center gap-2">
            <PulseLoader className="h-6 w-[120px] rounded-xl bg-secondary basis-3/4" />
            <PulseLoader className="h-6 w-[120px] rounded-2xl bg-secondary basis-1/4" />
          </div>
          {/* end header*/}
          <PulseLoader className="w-full aspect-[4/3]  rounded-2xl bg-secondary" />
          <PulseLoader className="h-6 w-[200px] rounded-2xl bg-secondary" />
        </div>
        <PulseLoader className="h-12 w-full rounded-xl bg-secondary" />
      </div>
    </>
  );

  const theOnlyElement = smartMessage?.content.layout.elements.flat().pop();

  const getLayoutElement = () => {
    if (!isDeviceSupported) {
      return (
        <Button variant="disabled" className="cursor-default">
          Device Not Supported
        </Button>
      );
    }
    if (!theOnlyElement) {
      return null;
    }
    if (theOnlyElement.type === 'button') {
      const action = theOnlyElement.action;
      if (action.type === ActionType.SignTransaction) {
        return (
          <Button
            onClick={async () => {
              await handleSmartMessageAction(action.humanReadableId);
            }}
            isLoading={isExecutingSmartMessageAction}
          >
            {theOnlyElement.text}
          </Button>
        );
      }
      if (action.type === ActionType.OpenLink) {
        return (
          <Button onClick={() => openLink(action.link)}>
            {theOnlyElement.text}
          </Button>
        );
      }
    }
    if (theOnlyElement.type === 'label') {
      return (
        <Button variant="disabled" className="cursor-default">
          {theOnlyElement.text}
        </Button>
      );
    }
    return null;
  };

  const renderSmartMessage = () => {
    if (!smartMessage) {
      return skeleton;
    }

    const smartMessageState = smartMessage.content.state;
    return (
      <div className="flex flex-1 flex-col gap-4">
        <div className="flex flex-1 flex-col items-center justify-center gap-4">
          {/* start header*/}
          <div className="flex w-full flex-row justify-between items-center gap-2">
            <span className="font-semibold text-text">
              {smartMessage.content.layout.header}
            </span>
            <span
              className={clsx(
                'inline-flex flex-row py-0.5 px-1.5 rounded-2xl backdrop-blur-2xl text-caption font-semibold gap-1 items-center justify-center',
                {
                  'bg-grey-10 text-grey-60': !['SUCCEEDED', 'FAILED'].includes(
                    smartMessageState,
                  ),
                  'text-success bg-success/15':
                    smartMessageState === 'SUCCEEDED',
                  'text-error bg-error/15': smartMessageState === 'FAILED',
                },
              )}
            >
              {(smartMessageState === 'EXECUTING' ||
                smartMessageState === 'READY_FOR_EXECUTION') && (
                <SpinnerDots size={10} />
              )}
              {smartMessageState === 'SUCCEEDED' && (
                <FontAwesomeIcon icon={faCheck} />
              )}
              {smartMessageState === 'FAILED' && (
                <FontAwesomeIcon icon={faClose} />
              )}
              {smartMessageState === 'CREATED'
                ? 'Ready'
                : toSentenceCase(smartMessageState)}
            </span>
          </div>
          {/* end header*/}
          <div className="flex w-full aspect-[4/3] items-center justify-center rounded-2xl overflow-hidden border border-primary">
            <img
              // src="https://static.remove.bg/sample-gallery/graphics/bird-thumbnail.jpg"
              src={smartMessage.content.layout.icon}
              alt={smartMessage.content.layout.icon}
            />
          </div>
          <span className="text-subtext text-center">
            {smartMessage.content.layout.description}
          </span>
        </div>
        <div className="flex flex-col gap-2">{getLayoutElement()}</div>
      </div>
    );
  };

  return (
    <ViewportFlex>
      <main className="p-4 w-full flex flex-col gap-4">
        {renderSmartMessage()}
      </main>
    </ViewportFlex>
  );
};

export default SmartMessagePage;
