import miniAppApi from '@/shared/api/dialect/mini-app-api.ts';
import useSWR from 'swr';

export interface TokenMarketData {
  currentPrice: number;
  priceChangePercentage24h: number;
}

export interface UseTokensMarketDataValue {
  tokensMarketData?: Record<string, TokenMarketData>;
  isTokensMarketDataLoading: boolean;
}

export interface UseTokensMarketDataOptions {
  refreshInterval?: number;
}

const useTokensMarketData = (
  mintAddresses: string[],
  { refreshInterval = 10000 }: UseTokensMarketDataOptions = {},
): UseTokensMarketDataValue => {
  const { data, isLoading } = useSWR(
    ['TOKENS_MARKET_DATA', refreshInterval, ...mintAddresses.sort()],
    async () => {
      if (mintAddresses.length === 0) {
        return {};
      }

      const tokensMarketData =
        await miniAppApi.markets.getTokensMarketData(mintAddresses);

      const addressToTokenMarketData: Record<string, TokenMarketData> = {};
      for (const tokenMarketData of tokensMarketData.items) {
        addressToTokenMarketData[tokenMarketData.mintAddress] = {
          currentPrice: tokenMarketData.currentPrice,
          priceChangePercentage24h: tokenMarketData.priceChangePercentage24h,
        };
      }
      return addressToTokenMarketData;
    },
    { refreshInterval },
  );

  return {
    tokensMarketData: data,
    isTokensMarketDataLoading: isLoading,
  };
};

export default useTokensMarketData;
