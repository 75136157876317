import { SVGProps } from 'react';

function SvgComponent(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1513_1179)" fill="#737780">
        <path d="M16.002 18.125l5.939 5.934a1.502 1.502 0 002.123-2.123l-5.939-5.934a9.916 9.916 0 01-2.123 2.123z" />
        <path
          opacity={0.4}
          d="M10.25 3.5a6.75 6.75 0 110 13.5 6.75 6.75 0 010-13.5zm0 16.5a9.75 9.75 0 100-19.5 9.75 9.75 0 000 19.5z"
        />
      </g>
      <defs>
        <clipPath id="clip0_1513_1179">
          <path fill="#fff" transform="translate(.5 .5)" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
