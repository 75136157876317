import useTelegramWebAppInitData from '@/hooks/telegram-web-app/useTelegramWebAppInitData.ts';
import miniAppApi from '@/shared/api/dialect/mini-app-api.ts';
import { identify, Identify } from '@amplitude/analytics-browser';
import { useEffect, useState } from 'react';
import useSWR from 'swr';

interface UseWalletArgs {}

interface UseWalletValue {
  walletAddress: string | null;
  walletConnectedAt?: Date | null;
  refreshWalletSession: () => Promise<any>;
  isWalletLoading: boolean;
  setRefreshInterval: React.Dispatch<React.SetStateAction<number>>;
}

const useWallet = (args?: UseWalletArgs): UseWalletValue => {
  const [initDataUnsafe, initData] = useTelegramWebAppInitData();
  const [refreshInterval, setRefreshInterval] = useState(0);

  const { data, isLoading, mutate } = useSWR(
    initDataUnsafe?.user?.id ? ['USER', initDataUnsafe.user.id] : null,
    async () => {
      if (!initData) {
        return;
      }
      return miniAppApi.login(initData);
    },
    {
      refreshInterval,
    },
  );

  useEffect(() => {
    if (!data?.walletAddress) {
      return;
    }
    const walletAddressIdentify = new Identify();
    walletAddressIdentify.set('wallet_address', data.walletAddress);

    identify(walletAddressIdentify);
  }, [data?.walletAddress]);

  return {
    walletAddress: data?.walletAddress ?? null,
    walletConnectedAt: data?.walletConnectedAt,
    setRefreshInterval,
    refreshWalletSession: mutate,
    isWalletLoading: isLoading,
  };
};

export default useWallet;
