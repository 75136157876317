import { AppRoute } from '@/appRoute.ts';
import { TokenLogo } from '@/shared/components';
import {
  formatDollarAmount,
  formatTokenAmountPrecise,
} from '@/shared/utils/formatting.ts';
import { faBell } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsx } from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

export const TokenBalanceCard: React.FC<{
  mintAddress: string;
  name: string;
  amount: number;
  symbol: string;
  change24hUsd: number | null;
  amountDollars: number | null;
  isAlertsEnabled: boolean;
  iconUrl?: string;
}> = ({
  mintAddress,
  name,
  amount,
  amountDollars,
  change24hUsd,
  iconUrl,
  isAlertsEnabled,
  symbol,
}) => {
  return (
    <Link
      to={AppRoute.TokenDetails.url({
        mintAddress,
      })}
      className={clsx(
        'flex flex-row items-center justify-between p-3 bg-secondary',
      )}
    >
      <div className="flex flex-row items-center gap-3">
        <div className="flex h-8 w-8">
          <TokenLogo imageUrl={iconUrl} />
        </div>
        <div className="flex flex-col justify-center">
          <div className="flex flex-row gap-1 w-full items-center">
            <span className="text-text font-medium">{name}</span>
            {isAlertsEnabled ? (
              <FontAwesomeIcon
                icon={faBell}
                className="text-secondary"
                size="xs"
              />
            ) : null}
          </div>
          <span className="text-caption font-medium text-secondary">
            {formatTokenAmountPrecise(amount)} {symbol}
          </span>
        </div>
      </div>
      <div className="flex flex-col items-end">
        <span className="text-text font-medium">
          {amountDollars === null ? (
            <>&mdash;</>
          ) : (
            formatDollarAmount(amountDollars)
          )}
        </span>
        <span
          className={clsx(
            'text-caption font-medium',
            { 'text-error': change24hUsd !== null && change24hUsd < 0 },
            { 'text-success': change24hUsd !== null && change24hUsd > 0 },
            { 'text-secondary': change24hUsd === 0 || change24hUsd === null },
          )}
        >
          {change24hUsd === null ? (
            <>&mdash;</>
          ) : (
            <>
              {change24hUsd > 0 ? '+' : change24hUsd < 0 ? '-' : ''}
              {formatDollarAmount(Math.abs(change24hUsd))}
            </>
          )}
        </span>
      </div>
    </Link>
  );
};
