import { solanaConnection } from '@/shared/api/solana/solana-api.ts';
import { PublicKey } from '@solana/web3.js';
import { useState } from 'react';
import useSWR from 'swr';

const refreshInterval = 2000;
const errorRetryCount = 100_000;

export interface UseSolanaTxSignatureLookupArgs {
  referenceAccount: string | null;
}

export interface UseSolanaTxSignatureLookupValue {
  isTxSignatureFound: boolean;
  isFindingTxSignature: boolean;
  txSignature: string | null;
  retryFindTxSignature: () => void;
}

const useSolanaTxSignatureLookup = ({
  referenceAccount,
}: UseSolanaTxSignatureLookupArgs): UseSolanaTxSignatureLookupValue => {
  const [isRetryAttemptsExceeded, setIsRetryAttemptsExceeded] = useState(false);

  const { data, error, isLoading, isValidating } = useSWR(
    referenceAccount && !isRetryAttemptsExceeded
      ? ['SOLANA_TX_SIGNATURE_LOOKUP', referenceAccount]
      : null,
    async ([, referenceAccount]) => {
      console.log(`Looking up signature for ${referenceAccount}`);
      const signatures = await solanaConnection.getSignaturesForAddress(
        new PublicKey(referenceAccount),
        undefined,
        'confirmed',
      );
      const signature = signatures.find((it) => it.memo?.includes('memo'));
      if (!signature) {
        throw new Error('No signatures found');
      }
      return signature.signature;
    },
    {
      refreshInterval: 0,
      dedupingInterval: refreshInterval,
      errorRetryInterval: refreshInterval,
      errorRetryCount: errorRetryCount,
      onErrorRetry: (_, key, config, revalidate, { retryCount }) => {
        if (retryCount >= errorRetryCount) {
          setIsRetryAttemptsExceeded(true);
        } else {
          setTimeout(
            () => revalidate({ retryCount }),
            config.errorRetryInterval,
          );
        }
      },
    },
  );

  return {
    isTxSignatureFound: Boolean(data),
    isFindingTxSignature:
      Boolean(referenceAccount) &&
      (isLoading || (!data && !isRetryAttemptsExceeded)),
    txSignature: data ?? null,
    retryFindTxSignature: () => setIsRetryAttemptsExceeded(false),
  };
};

export default useSolanaTxSignatureLookup;
