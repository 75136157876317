import { logEvent } from '@/analytics.ts';
import TransactionSignTroubleshooting from '@/components/TransactionSignTroubleshooting.tsx';
import DialectLogo from '@/icons/DialectLogo.tsx';
import { SpinnerDots } from '@/icons/SpinnerDots.tsx';
import miniAppApi from '@/shared/api/dialect/mini-app-api.ts';
import DialectOperatorLogo from '@/shared/images/dialect-operator-logo.png';
import { setUserId } from '@amplitude/analytics-browser';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { captureException } from '@sentry/react';
import { FC, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const openTelegram = () => {
  window.location.replace(`tg://resolve`);
  setTimeout(() => {
    window.close();
  }, 2000);
};

const TelegramButton = () => {
  return (
    <button
      className="border px-4 py-3 flex flex-row items-center gap-1.5 text-primary rounded-xl"
      onClick={openTelegram}
    >
      <FontAwesomeIcon icon={faTelegram} />
      <span className="text-text/5">Take me back to Telegram</span>
      <FontAwesomeIcon icon={faArrowRight} />
    </button>
  );
};
const SignTxPhantomRedirectPage: FC = () => {
  const [queryParams] = useSearchParams();

  const nonce = queryParams.get('nonce');
  const data = queryParams.get('data');
  const smartMessageId = queryParams.get('smartMessageId');
  const type = queryParams.get('type');
  const actionHumanReadableId = queryParams.get('actionHumanReadableId');
  const rawInitData = queryParams.get('rawInitData');
  const errorCode = queryParams.get('errorCode');

  const [isTxSubmitted, setIsTxSubmitted] = useState(false);
  const [isSubmittingTx, setIsSubmittingTx] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const submitPhantomTx = async () => {
      if (!type) {
        return;
      }
      setIsSubmittingTx(true);
      try {
        // Handle an error response from Phantom
        if (errorCode) {
          const error = Object.fromEntries([...queryParams]);
          const message =
            error?.errorMessage ??
            JSON.stringify(Object.fromEntries([...queryParams]), null, 2);
          setErrorMessage(message);

          logEvent(type, {
            wallet_provider: 'phantom',
            status: 'sign_tx_fail',
            smart_message_id: smartMessageId,
            action_id: actionHumanReadableId,
            error_code: errorCode,
            error_message: message,
          });

          return;
        }
        logEvent(type, {
          wallet_provider: 'phantom',
          status: 'sign_tx_success',
          smart_message_id: smartMessageId,
          action_id: actionHumanReadableId,
        });

        if (
          !nonce ||
          !data ||
          !smartMessageId ||
          !actionHumanReadableId ||
          !rawInitData
        ) {
          return;
        }

        const response = await miniAppApi.smartMessage.submitTransaction.mutate(
          {
            smartMessageId,
            actionHumanReadableId,
            data,
            nonce,
            rawInitData,
          },
        );

        setUserId(response.userId);
        logEvent(type, {
          status: 'submit_tx_success',
          smart_message_id: smartMessageId,
          action_id: actionHumanReadableId,
        });

        setIsTxSubmitted(true);
      } catch (e) {
        logEvent(type, {
          status: 'submit_tx_fail',
          smart_message_id: smartMessageId,
          action_id: actionHumanReadableId,
        });
        captureException(e);
      } finally {
        setIsSubmittingTx(false);
      }
    };

    submitPhantomTx();

    // query params
    // should run only once, when page launched
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const header = (
    <div className="absolute top-8 w-full flex justify-center text-primary">
      <DialectLogo />
    </div>
  );

  const content = useCallback(() => {
    if (isSubmittingTx)
      return (
        <div className="flex flex-col items-center justify-center text-primary">
          <SpinnerDots />
          <span className="mt-2 text-text">Loading...</span>
        </div>
      );

    return (
      <div className="flex flex-col items-center justify-center gap-8">
        <div className="flex flex-col items-center">
          {isTxSubmitted ? (
            <>
              <div className="w-24 h-24 flex items-center justify-center mb-2">
                <img src={DialectOperatorLogo} alt="logo" />
              </div>
              <span className="text-[28px] font-bold text-primary">
                Transaction submitted!
              </span>
            </>
          ) : (
            <>
              <div className="w-24 h-24 flex items-center justify-center mb-2">
                <img src={DialectOperatorLogo} alt="logo" />
              </div>
              <span className="text-[28px] font-bold text-primary">
                Signing failed
              </span>
              {errorMessage && (
                <span className="text-subtext text-primary/60">
                  Error: {errorMessage}
                </span>
              )}
              <TransactionSignTroubleshooting
                className="border rounded-xl w-auto mt-8"
                expanded
                connectWalletArgs={{
                  initData: rawInitData,
                  openLink: (link) => {
                    window.location.replace(link);
                    setTimeout(() => {
                      window.close();
                    }, 2000);
                  },
                }}
              />
            </>
          )}
        </div>
        <TelegramButton />
      </div>
    );
  }, [errorMessage, isSubmittingTx, isTxSubmitted, rawInitData]);

  return (
    <div className="flex flex-col justify-center h-screen relative">
      {content()}
      {header}
    </div>
  );
};

export default SignTxPhantomRedirectPage;
