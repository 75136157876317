/**
 serialize on a versioned transaction gives Uint8Array, whereas on a legacy transaction you get a Buffer.
 Buffer supports base64 conversion, but Uint8Array doesn't.
 There's a utility function to convert the Uint8Array to Buffer:
 */
export const toBuffer = (arr: Buffer | Uint8Array | Array<number>): Buffer => {
  if (Buffer.isBuffer(arr)) {
    return arr;
  } else if (arr instanceof Uint8Array) {
    return Buffer.from(arr.buffer, arr.byteOffset, arr.byteLength);
  } else {
    return Buffer.from(arr);
  }
};
