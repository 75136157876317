import birdeyeApi from '@/shared/api/birdseye/api.ts';
import jupiterApi from '@/shared/api/jupiter-api.ts';
import useSWR from 'swr';

export interface TokenPrice {
  mintAddress: string;
  price: number;
}

interface UseTokenPricesValue {
  tokenPrices?: Record<string, TokenPrice>;
  isTokenPricesLoading: boolean;
}

export interface UseTokenPricesOptions {
  includeUnknown?: boolean;
  refreshInterval?: number;
}

const useTokenPrices = (
  mintAddresses: string[],
  {
    includeUnknown = false,
    refreshInterval = 10000,
  }: UseTokenPricesOptions = {},
): UseTokenPricesValue => {
  const { data, isLoading } = useSWR(
    [
      'TOKEN_PRICES',
      refreshInterval,
      includeUnknown ? 'INCLUDE_UNKNOWN' : '',
      ...mintAddresses.sort(),
    ],
    async () => {
      if (mintAddresses.length === 0) {
        return {};
      }

      const jupiterTokenPrices =
        await jupiterApi.getTokenPricesInUsdc(mintAddresses);

      const tokenPrices: Record<string, TokenPrice> = {};
      const missingTokenPrices: string[] = [];
      for (const mintAddress of mintAddresses) {
        const tokenPrice = jupiterTokenPrices[mintAddress];
        if (!tokenPrice) {
          missingTokenPrices.push(mintAddress);
          continue;
        }
        tokenPrices[mintAddress] = {
          mintAddress: mintAddress,
          price: tokenPrice.price,
        };
      }

      if (includeUnknown) {
        const missingPrices = await Promise.allSettled(
          missingTokenPrices.map((mintAddress) =>
            birdeyeApi.getTokenPrice(mintAddress),
          ),
        );

        for (let i = 0; i < missingPrices.length; i++) {
          const mintAddress = missingTokenPrices[i]!;
          const tokenPrice = missingPrices[i]!;
          if (tokenPrice.status === 'rejected') {
            continue;
          }
          tokenPrices[mintAddress] = {
            mintAddress: mintAddress,
            price: tokenPrice.value.value,
          };
        }
      }

      return tokenPrices;
    },
    { refreshInterval },
  );

  return {
    tokenPrices: data,
    isTokenPricesLoading: isLoading,
  };
};

export default useTokenPrices;
