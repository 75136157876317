import useTokenMetadataCatalog, {
  TokenMetadata,
} from '@/hooks/useTokenMetadataCatalog.ts';
import useTokenMetadataFallback from '@/hooks/useTokenMetadataFallback.ts';
import useWalletTokensRaw from '@/hooks/useWalletTokensRaw.ts';
import { WalletToken } from '@/shared/api/solana/solana-api.ts';

interface UseWalletTokensArgs {}

interface UseWalletTokensValue {
  walletTokens: Record<
    string,
    WalletToken & {
      metadata: TokenMetadata;
    }
  >;

  isWalletTokensLoading: boolean;
}

const useWalletTokens = (args?: UseWalletTokensArgs): UseWalletTokensValue => {
  const { tokenMetadata } = useTokenMetadataCatalog();

  const { walletTokens: data, isWalletTokensLoading: isLoading } =
    useWalletTokensRaw();

  const tokensWithoutMetadata = (data ?? []).filter(
    (token) => !tokenMetadata[token.mintAddress],
  );

  const { tokenMetadata: fallbackTokenMetadata } = useTokenMetadataFallback(
    tokensWithoutMetadata.map((token) => token.mintAddress),
  );

  const existingJoinedWithMetadata = (data ?? [])
    .filter((token) => tokenMetadata[token.mintAddress])
    .map((token) => ({
      ...token,
      metadata: tokenMetadata[token.mintAddress]!,
    }));

  const fallbacksJoinedWithMetadata = tokensWithoutMetadata
    .filter((token) => fallbackTokenMetadata[token.mintAddress])
    .map((token) => ({
      ...token,
      metadata: fallbackTokenMetadata[token.mintAddress]!,
    }));

  const walletTokens = Object.fromEntries(
    [...existingJoinedWithMetadata, ...fallbacksJoinedWithMetadata].map(
      (token) => [token.mintAddress, token],
    ),
  );
  return {
    walletTokens,
    isWalletTokensLoading: isLoading,
  };
};

export default useWalletTokens;
