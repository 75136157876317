import { SwapFormPersistenceContext } from '@/components/TokenSwapFormPersistenceContext.tsx';
import TokenSwapPairMember from '@/components/TokenSwapPairMember.tsx';
import { ViewportFlex } from '@/components/ViewportFlex.tsx';
import {
  DEFAULT_SLIPPAGE_BPS,
  QuoteProvider,
  useTokenSwap,
  useTokenSwapOptions,
  useTokenSwapQuote,
} from '@/hooks/token-swap';
import { useLoadTokenSwapDefaultOption } from '@/hooks/token-swap/useLoadTokenSwapDefaultOption.ts';
import { useTokenMarketData } from '@/hooks/useTokenMarketData.ts';
import { TokenMetadata } from '@/hooks/useTokenMetadataCatalog.ts';
import useWalletTokens from '@/hooks/useWalletTokens.ts';
import { calculateMaxPossibleWithdrawal } from '@/shared/api/solana/solana-utils.ts';
import Button from '@/shared/components/Button.tsx';
import { isDeviceSupported } from '@/shared/utils/device-support.ts';
import {
  formatDollarAmount,
  formatPercentage,
  formatTokenAmount,
} from '@/shared/utils/formatting.ts';
import { usePersistedState } from '@/shared/utils/persistance/usePersistedState.ts';
import { InputType } from '@/shared/utils/typing';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { faCircleExclamation, faGear } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { boolean, defaultValue, float, int, object, oneOf } from 'checkeasy';
import { clsx } from 'clsx';
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Sheet from 'react-modal-sheet';
import { useSearchParams } from 'react-router-dom';

interface Props {
  action: 'Buy' | 'Sell';
  actionSubjectProvider: (tokens: {
    payingToken: TokenMetadata | null;
    payingTokenOptions: TokenMetadata[];
    setPayingToken: React.Dispatch<TokenMetadata | null>;
    receivingToken: TokenMetadata | null;
    receivingTokenOptions: TokenMetadata[];
    setReceivingToken: React.Dispatch<TokenMetadata | null>;
  }) => {
    subjectToken: TokenMetadata | null;
    setSubjectToken: React.Dispatch<TokenMetadata | null>;
    subjectTokenOptions: TokenMetadata[];
  };
  defaults: {
    payingTokenSymbol: string | null;
    payingTokenMintAddress: string | null;
    payingAmount: string | null;
    receivingAmount: string | null;
    receivingTokenSymbol: string | null;
    receivingTokenMintAddress: string | null;
  };
}

const swapProviderMap: Record<QuoteProvider, string> = {
  [QuoteProvider.RAYDIUM]: 'Raydium',
  [QuoteProvider.JUPITER]: 'Jupiter',
};

enum PriorityFeeTier {
  Market = 'Market',
  High = 'High',
  Turbo = 'Turbo',
}

interface PriorityFeeOption {
  tier: PriorityFeeTier;
  amountLamports: number;
  amountUi: number;
}

const priorityFeeMarket = {
  tier: PriorityFeeTier.Market,
  amountLamports: 1000000,
  amountUi: 0.001,
};

const priorityFeeDefault = priorityFeeMarket;

interface SwapSettings {
  slippage: {
    value: number;
    isCustom: boolean;
  };
  priorityFee: PriorityFeeOption;
}

const swapSettingsModel = object({
  slippage: object({
    value: defaultValue(DEFAULT_SLIPPAGE_BPS, float()),
    isCustom: defaultValue(false, boolean()),
  }),
  priorityFee: object({
    tier: defaultValue(
      priorityFeeDefault.tier,
      oneOf(Object.keys(PriorityFeeTier) as PriorityFeeTier[]),
    ),
    amountUi: defaultValue(priorityFeeDefault.amountUi, float()),
    amountLamports: defaultValue(priorityFeeDefault.amountLamports, int()),
  }),
});

const SwapPage: FC<Props> = ({ action, actionSubjectProvider, defaults }) => {
  const { walletTokens } = useWalletTokens();

  const {
    inputType,
    setInputType,
    userChangedAmount,
    setUserChangedAmount,
    payingToken,
    setPayingToken,
    payingAmount,
    setPayingAmount,
    receivingToken,
    setReceivingToken,
    receivingAmount,
    setReceivingAmount,
  } = useContext(SwapFormPersistenceContext);

  const { ownedTokenOptions, allTokenOptions } = useTokenSwapOptions();

  const payingEnabled = inputType === InputType.PAYING;
  const receivingEnabled = inputType === InputType.RECEIVING;

  const { isSearchingToken: isPayingTokenLoading } =
    useLoadTokenSwapDefaultOption({
      defaultSymbol: defaults.payingTokenSymbol,
      defaultAddress: defaults.payingTokenMintAddress,
      currentToken: payingToken,
      setToken: setPayingToken,
    });

  const { isSearchingToken: isReceivingTokenLoading } =
    useLoadTokenSwapDefaultOption({
      defaultSymbol: defaults.receivingTokenSymbol,
      defaultAddress: defaults.receivingTokenMintAddress,
      currentToken: receivingToken,
      setToken: setReceivingToken,
    });

  const [swapSettings, setSwapSettings] = usePersistedState(
    {
      slippage: {
        value: DEFAULT_SLIPPAGE_BPS,
        isCustom: false,
      },
      priorityFee: priorityFeeDefault,
    },
    'swap-settings',
    swapSettingsModel,
  );

  useEffect(() => {
    const setPayingAmountFromDefaults = () => {
      if (
        !defaults.payingAmount ||
        defaults.payingAmount === payingAmount ||
        userChangedAmount
      ) {
        return;
      }
      setPayingAmount(defaults.payingAmount);
    };
    setPayingAmountFromDefaults();
  }, [
    inputType,
    defaults.payingAmount,
    payingAmount,
    userChangedAmount,
    setPayingAmount,
  ]);

  useEffect(() => {
    const setReceivingAmountFromDefaults = () => {
      if (
        defaults.payingAmount || // If paying and receiving amounts are both set, prioritize paying amount
        !defaults.receivingAmount ||
        defaults.receivingAmount === receivingAmount ||
        userChangedAmount
      ) {
        return;
      }

      setReceivingAmount(defaults.receivingAmount);
    };
    setReceivingAmountFromDefaults();
  }, [
    inputType,
    defaults.receivingAmount,
    receivingAmount,
    defaults.payingAmount,
    userChangedAmount,
    setReceivingAmount,
  ]);

  const [_, setParams] = useSearchParams();

  const onPayingTokenChange = useCallback(
    (token: TokenMetadata) => {
      setPayingToken(token);

      const isTokenEqualToReceiving =
        token && token.mintAddress === receivingToken?.mintAddress;

      if (isTokenEqualToReceiving) {
        setReceivingToken(null);
        setReceivingAmount('');
      }

      setParams(
        (prev) => ({
          ...(!isTokenEqualToReceiving
            ? {
                receivingTokenMintAddress:
                  receivingToken?.mintAddress ??
                  prev.get('receivingTokenMintAddress')!,
              }
            : {}),
          payingTokenMintAddress: token.mintAddress,
        }),
        { replace: true },
      );
    },
    [
      receivingToken?.mintAddress,
      setParams,
      setPayingToken,
      setReceivingAmount,
      setReceivingToken,
    ],
  );

  const onReceivingTokenChange = useCallback(
    (token: TokenMetadata) => {
      setReceivingToken(token);

      const isTokenEqualToPaying =
        token && token.mintAddress === payingToken?.mintAddress;

      if (isTokenEqualToPaying) {
        setPayingToken(null);
        setPayingAmount('');
      }

      setParams(
        (prev) => ({
          ...(!isTokenEqualToPaying
            ? {
                payingTokenMintAddress:
                  payingToken?.mintAddress ??
                  prev.get('payingTokenMintAddress')!,
              }
            : {}),
          receivingTokenMintAddress: token.mintAddress,
        }),
        { replace: true },
      );
    },
    [
      payingToken?.mintAddress,
      setParams,
      setPayingAmount,
      setPayingToken,
      setReceivingToken,
    ],
  );

  const { subjectToken } = actionSubjectProvider({
    payingToken,
    payingTokenOptions: ownedTokenOptions,
    setPayingToken,
    receivingToken,
    receivingTokenOptions: allTokenOptions,
    setReceivingToken,
  });

  useEffect(() => {
    if (inputType !== null) return;
    if (defaults.payingAmount) setInputType(InputType.PAYING);
    else if (defaults.receivingAmount) setInputType(InputType.RECEIVING);
    else setInputType(InputType.PAYING);
  }, [defaults.payingAmount, defaults.receivingAmount, inputType]);

  const { quote, quoteOutAmountUi, refreshQuote, isQuoteValidating, error } =
    useTokenSwapQuote({
      inputType: inputType || InputType.PAYING,
      payingToken,
      receivingToken,
      payingAmount,
      receivingAmount,
      slippageBps: swapSettings.slippage.value,
    });

  const isSufficientBalance = useMemo(() => {
    if (!payingToken || !payingAmount || !walletTokens) {
      return null;
    }
    const walletPayingToken = walletTokens[payingToken.mintAddress];
    if (!walletPayingToken) {
      return false;
    }
    const maxPossibleWithdrawal = calculateMaxPossibleWithdrawal(
      walletPayingToken.uiAmountBalance,
      payingToken.mintAddress,
    );
    return maxPossibleWithdrawal >= parseFloat(payingAmount);
  }, [payingToken, payingAmount, walletTokens]);

  const onSwapSettingsChange = useCallback(
    (data: SwapSettings) => {
      setSwapSettings(data);
      refreshQuote();
    },
    [setSwapSettings, refreshQuote],
  );

  useEffect(() => {
    if (inputType === InputType.PAYING) {
      refreshQuote();
    }
  }, [payingToken, payingAmount]);

  useEffect(() => {
    if (inputType === InputType.RECEIVING) {
      refreshQuote();
    }
  }, [receivingToken, receivingAmount]);

  useEffect(() => {
    if (!quoteOutAmountUi) {
      inputType === InputType.PAYING
        ? setReceivingAmount('')
        : setPayingAmount('');
      return;
    }
    const amount =
      inputType === InputType.PAYING ? receivingAmount : payingAmount;
    const setAmount =
      inputType === InputType.PAYING ? setReceivingAmount : setPayingAmount;
    if (quoteOutAmountUi.toString() !== amount) {
      setAmount(quoteOutAmountUi.toString());
    }
  }, [inputType, payingAmount, quote, quoteOutAmountUi, receivingAmount]);

  const header = (
    <div className="flex items-center justify-center">
      <span className="text-h2 font-bold">
        {action} {subjectToken?.symbol || 'Token'}
      </span>
    </div>
  );

  const swapPair = (
    <div className="flex flex-col rounded-2xl py-3">
      <TokenSwapPairMember
        title="Paying"
        type={InputType.PAYING}
        options={allTokenOptions}
        selectorValue={payingToken}
        isOptionsLoading={isPayingTokenLoading}
        onSelectToken={onPayingTokenChange}
        inputValue={payingAmount}
        inputEnabled={payingEnabled}
        onInputChange={
          payingEnabled
            ? (amount) => {
                if (!userChangedAmount) {
                  setUserChangedAmount(true);
                }
                setPayingAmount(amount || '');
              }
            : undefined
        }
        insufficientBalance={isSufficientBalance === false}
        isInputValueLoading={receivingEnabled && isQuoteValidating}
        onAmountClick={() => {
          setInputType(InputType.PAYING);
        }}
        className="mb-6"
      />
      <TokenSwapPairMember
        title="Receiving"
        type={InputType.RECEIVING}
        options={allTokenOptions}
        selectorValue={receivingToken}
        isOptionsLoading={isReceivingTokenLoading}
        onSelectToken={onReceivingTokenChange}
        inputValue={receivingAmount}
        inputEnabled={receivingEnabled}
        onInputChange={
          receivingEnabled
            ? (amount) => {
                if (!userChangedAmount) setUserChangedAmount(true);
                setReceivingAmount(amount);
              }
            : undefined
        }
        isInputValueLoading={payingEnabled && isQuoteValidating}
        onAmountClick={() => {
          setInputType(InputType.RECEIVING);
        }}
        className="mb-2"
      />
      {Number(quote?.priceImpactPct) > 0.005 && (
        <div className="bg-warning-light rounded-lg p-2 flex flex-row items-center gap-1">
          <FontAwesomeIcon
            icon={faCircleExclamation}
            className="text-warning"
            size="sm"
          />
          <span className="text-caption font-medium">
            Price impact more than{' '}
            <span className="text-error">
              {formatPercentage(quote?.priceImpactPct || '0')}
            </span>
          </span>
        </div>
      )}
    </div>
  );

  const { swapTokens, isTokenSwapping } = useTokenSwap({
    action,
    payingToken,
    payingAmount,
    receivingToken,
    receivingAmount,
    quote,
    priorityFeeLamports: swapSettings.priorityFee.amountLamports,
  });

  const buttonDisabled =
    !isDeviceSupported ||
    !payingToken ||
    !receivingToken ||
    !payingAmount ||
    !receivingAmount ||
    !quote ||
    isQuoteValidating ||
    !isSufficientBalance ||
    isTokenSwapping;

  const getButtonText = () => {
    if (!isDeviceSupported) {
      return 'Device Not Supported';
    }
    if (error) {
      return 'Pool not available';
    }

    if (isSufficientBalance === false) {
      return 'Insufficient balance';
    }
    return `${action}`;
  };

  const swapDetails = (
    <div className="flex flex-col gap-1.5">
      <div className="flex flex-row justify-between items-center">
        <span className="font-semibold text-subtext text-primary">Details</span>
        <SwapSettings settings={swapSettings} onChange={onSwapSettingsChange} />
      </div>
      <TokenMarketDataRow
        key={payingToken?.mintAddress}
        mintAddress={payingToken?.mintAddress}
      />
      <TokenMarketDataRow
        key={receivingToken?.mintAddress}
        mintAddress={receivingToken?.mintAddress}
      />
    </div>
  );
  return (
    <ViewportFlex>
      <div className="flex w-full flex-col justify-between gap-4 p-3 pt-2">
        <div className="flex flex-col">
          {header}
          {swapPair}
          {swapDetails}
        </div>
        <div className="flex flex-col gap-2.5">
          {!isDeviceSupported && (
            <div className="flex flex-row items-center gap-2 bg-warning/10 p-2.5 rounded-lg">
              <FontAwesomeIcon
                icon={faCircleExclamation}
                className="text-warning"
                size="sm"
              />
              <span className="text-caption">
                Your device is not yet supported. Token trading coming soon to
                Desktop.
              </span>
            </div>
          )}
          <div className="text-caption text-secondary text-center font-medium">
            {quote
              ? `Zero Dialect fees, Powered by ${
                  swapProviderMap[quote.provider]
                }.`
              : 'Zero Dialect fees.'}
          </div>
          <Button
            variant={buttonDisabled ? 'disabled' : 'brand'}
            disabled={buttonDisabled}
            isLoading={isQuoteValidating || isTokenSwapping}
            onClick={() => {
              if (!quote || !payingToken || !receivingToken) {
                return;
              }
              swapTokens();
            }}
          >
            {getButtonText()}
          </Button>
        </div>
      </div>
    </ViewportFlex>
  );
};

const TokenMarketDataRow = ({ mintAddress }: { mintAddress?: string }) => {
  const { marketData } = useTokenMarketData(mintAddress);
  const priceChange = marketData?.priceChange1hPercent
    ? marketData.priceChange1hPercent / 100
    : 0;

  const scrollRef = useRef<HTMLDivElement>(null);
  const [scrollOffset, setScrollOffset] = useState(0);

  useEffect(() => {
    if (
      !mintAddress ||
      !marketData ||
      !scrollRef.current ||
      scrollRef.current.scrollWidth <= scrollRef.current.clientWidth
    ) {
      setScrollOffset(0);
      return;
    }

    const diff = scrollRef.current.scrollWidth - scrollRef.current.clientWidth;
    const interval = setInterval(
      () => setScrollOffset((prev) => (prev < 0 ? 0 : -diff)),
      3000,
    );

    return () => {
      clearInterval(interval);
    };
  }, [mintAddress, marketData]);

  return marketData ? (
    <div className="flex flex-row justify-between items-center">
      <div className="overflow-x-hidden">
        <div
          className="flex flex-row gap-1 items-center transition-transform duration-[2s] ease-in-out"
          ref={scrollRef}
          style={{ transform: `translateX(${scrollOffset}px)` }}
        >
          <span className="text-caption font-semibold text-nowrap">
            {marketData.name}
          </span>
          <span className="text-caption text-secondary">
            {marketData.price ? (
              `$${formatTokenAmount(marketData.price)}`
            ) : (
              <>&ndash;</>
            )}
          </span>
          <span
            className={clsx('text-caption', {
              'text-error': priceChange < 0,
              'text-success': priceChange >= 0,
            })}
          >
            {formatPercentage(priceChange.toString())}
            <sup className="text-secondary">1h</sup>
          </span>
        </div>
      </div>
      <div className="flex flex-row items-center pl-2">
        <span className="text-caption text-secondary text-nowrap">
          FDMC:{' '}
          {typeof marketData.marketCap === 'number'
            ? `${formatDollarAmount(marketData.marketCap)}`
            : 'N/A'}
        </span>
      </div>
    </div>
  ) : null;
};

export const SwapSettings = ({
  settings,
  onChange,
}: {
  settings: SwapSettings;
  onChange: (data: SwapSettings) => void;
}) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const openModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const onSave = useCallback(
    (data: SwapSettings) => {
      onChange(data);
    },
    [onChange],
  );

  return (
    <>
      <button
        className="flex flex-row items-center gap-1 text-grey-40 py-1 px-1.5 rounded-lg bg-secondary"
        onClick={openModal}
      >
        <span className="text-caption">
          <span className="text-tertiary">Slippage </span>
          <span className="text-secondary font-semibold">
            {settings.slippage.value / 100}%
          </span>
        </span>
        <span className="text-caption">
          <span className="text-tertiary">Priority </span>
          <span className="text-secondary font-semibold">
            {settings.priorityFee.tier}
          </span>
        </span>
        <FontAwesomeIcon
          icon={faGear}
          className="text-grey-40 w-[11px] h-[11px]"
        />
      </button>
      <SwapConfigurationModal
        open={isModalOpen}
        closeModal={closeModal}
        initialSettings={settings}
        onSave={onSave}
      />
    </>
  );
};

const toPrecise2 = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
});

const precisionValues: Record<PriorityFeeTier, PriorityFeeOption> = {
  Market: priorityFeeMarket,
  High: {
    tier: PriorityFeeTier.High,
    amountLamports: 5000000,
    amountUi: 0.005,
  },
  Turbo: {
    tier: PriorityFeeTier.Turbo,
    amountLamports: 10000000,
    amountUi: 0.01,
  },
};

const SwapConfigurationModal = ({
  open,
  closeModal,
  initialSettings,
  onSave,
}: {
  open: boolean;
  closeModal: () => void;
  onSave: (data: SwapSettings) => void;
  initialSettings: SwapSettings;
}) => {
  const [slippage, setSlippage] = useState(initialSettings.slippage);
  const [customSlippageValue, setCustomSlippageValue] = useState(
    initialSettings.slippage.isCustom
      ? (initialSettings.slippage.value / 100).toString()
      : '',
  );
  const [slippageError, setSlippageError] = useState<{
    variant: 'error' | 'warning';
    message: string;
    disable: boolean;
  } | null>(null);

  const [priorityFee, setPriorityFee] = useState(initialSettings.priorityFee);

  const slippageItems: SwitchItem<number>[] = useMemo(
    () =>
      [10, DEFAULT_SLIPPAGE_BPS, 100].map((bps) => ({
        label: `${bps / 100}%`,
        value: bps,
      })),
    [],
  );

  const priorityItems: SwitchItem<PriorityFeeTier>[] = useMemo(
    () =>
      Object.entries(precisionValues).map(([key, it]) => ({
        label: it.tier,
        caption: `max ${it.amountUi} SOL`,
        value: key as PriorityFeeTier,
      })),
    [],
  );

  const onSwitchSlippageChange = useCallback((item: SwitchItem<number>) => {
    setCustomSlippageValue('');
    setSlippage({
      value: item.value,
      isCustom: false,
    });
  }, []);

  const onSwitchPriorityChange = useCallback(
    (item: SwitchItem<PriorityFeeTier>) => {
      setPriorityFee(precisionValues[item.value]);
    },
    [],
  );

  const onInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/[^0-9,.]/g, '').replace(/,/g, '.');

    // remove all decimal points except the first one
    const firstDecimalPointIndex = rawValue.indexOf('.');
    const value = rawValue.replace(/\./g, (v, index) =>
      index === firstDecimalPointIndex ? v : '',
    );

    // if the value ends with a decimal point or a zero (while the number is decimal), don't update the state
    if (value.endsWith('.') || (value.endsWith('0') && value.includes('.'))) {
      setCustomSlippageValue(value);
      return;
    }

    const preciseValue = value ? toPrecise2.format(Number(value)) : '';

    const bpsValue = value ? Number(preciseValue) * 100 : 0;

    setCustomSlippageValue(preciseValue);

    setSlippage({
      value: bpsValue,
      isCustom: true,
    });
  }, []);

  const onInputBlur = useCallback(() => {
    if (customSlippageValue === '') {
      setSlippage({
        value: DEFAULT_SLIPPAGE_BPS,
        isCustom: false,
      });
    }
  }, [customSlippageValue]);

  useEffect(() => {
    const { isCustom, value: bpsValue } = slippage;

    // bps = basis points = 1/100 of a percent
    // unlike to happen, but just in case
    if (bpsValue < 0) {
      setSlippageError({
        variant: 'error',
        message: 'Slippage tolerance must be more or equal than 0%',
        disable: true,
      });
      return;
    }

    if (bpsValue <= 10 && !(isCustom && customSlippageValue === '')) {
      setSlippageError({
        variant: 'warning',
        message: 'Your transaction may fail because of low slippage value',
        disable: false,
      });
      return;
    }

    if (bpsValue >= 300) {
      setSlippageError({
        variant: 'warning',
        message:
          'Your transaction may be frontrun and result in an unfavourable trade.',
        disable: false,
      });
      return;
    }

    setSlippageError(null);
  }, [customSlippageValue, slippage]);

  const onExtendedSave = useCallback(() => {
    onSave({ slippage, priorityFee });
    closeModal();
  }, [closeModal, onSave, priorityFee, slippage]);

  return (
    <Sheet
      isOpen={open}
      onClose={closeModal}
      initialSnap={0}
      disableDrag={true}
      snapPoints={[1]}
    >
      <Sheet.Container
        style={{
          boxShadow: undefined,
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        }}
      >
        <Sheet.Header>
          <div className="relative flex flex-row justify-center text-bold text-h2">
            <span className="py-4">Settings</span>
            <button
              className="absolute right-2 top-2 h-8 w-8 flex justify-center items-center bg-secondary rounded-3xl p-2 text-grey-60"
              onClick={closeModal}
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
        </Sheet.Header>
        <Sheet.Content className="px-3 pb-3 h-full">
          <div className="flex-1 pb-4">
            <section>
              <h2 className="text-subtext font-semibold mb-1">
                Slippage Limit
              </h2>
              <p className="text-caption text-secondary">
                Setting a high slippage tolerance can help transactions fill
                successfully, but can result in a higher price. Use with
                caution.
              </p>
              <div className="mt-3 flex flex-col gap-3">
                <SwitchSlider
                  items={slippageItems}
                  initial={slippage.value}
                  onChange={onSwitchSlippageChange}
                  isCustom={slippage.isCustom}
                />
                <div className="flex flex-col gap-2">
                  <div
                    className={clsx(
                      'border border-primary rounded-xl py-1 px-3',
                      {
                        'focus-within:border-error':
                          slippageError?.variant === 'error',
                        'focus-within:border-accent':
                          slippageError?.variant !== 'error',
                      },
                    )}
                  >
                    <div className="h-[40px] flex gap-2">
                      <div className="flex items-center">
                        <span className="text-subtext font-medium text-secondary">
                          Custom
                        </span>
                      </div>
                      <div
                        className={clsx(
                          "flex flex-1 items-center after:content-['%'] after:text-subtext",
                          {
                            'after:text-tertiary': customSlippageValue === '',
                            'after:text-primary': customSlippageValue !== '',
                          },
                        )}
                      >
                        <input
                          className="text-right text-subtext flex-1 placeholder:text-tertiary outline-none"
                          placeholder="0.00"
                          type="text"
                          inputMode="decimal"
                          value={customSlippageValue}
                          onChange={onInputChange}
                          onBlur={onInputBlur}
                        />
                      </div>
                    </div>
                  </div>
                  {slippageError && <Banner {...slippageError} />}
                </div>
              </div>
            </section>
            <section className="mt-4">
              <h2 className="text-subtext font-semibold mb-1">Priority Fee</h2>
              <p className="text-caption text-secondary">
                The priority fee is paid to the Solana network. This additional
                fee boosts your transaction&apos;s priority against others, and
                may result in faster execution times.
              </p>
              <div className="mt-3 flex flex-col gap-3">
                <SwitchSlider
                  items={priorityItems}
                  initial={priorityFee.tier}
                  onChange={onSwitchPriorityChange}
                  isCustom={false}
                />
              </div>
            </section>
          </div>
          <Button
            onClick={onExtendedSave}
            disabled={slippageError?.disable}
            variant={slippageError?.disable ? 'disabled' : 'brand'}
          >
            Done
          </Button>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop
        style={{ backgroundColor: '#00000080' }}
        onTap={closeModal}
      />
    </Sheet>
  );
};

const Banner = ({
  variant,
  message,
}: {
  variant: 'warning' | 'error';
  message: string;
}) => {
  const bgClassName = variant === 'warning' ? 'bg-warning/10' : 'bg-error/10';
  const iconColorClassName =
    variant === 'warning' ? 'text-warning' : 'text-error';

  return (
    <div
      className={clsx('flex rounded-lg p-2 items-center gap-2', bgClassName)}
    >
      <FontAwesomeIcon
        icon={faCircleExclamation}
        className={clsx('w-[11px] h-[11px]', iconColorClassName)}
      />
      <span className="text-caption text-primary">{message}</span>
    </div>
  );
};

interface SwitchItem<T = any> {
  label: string;
  caption?: string;
  value: T;
}

const SwitchSlider = <V = any,>({
  initial,
  isCustom,
  items,
  onChange,
}: {
  initial?: V; // match to items[].value
  items: SwitchItem<V>[];
  onChange?: (value: SwitchItem<V>) => void;
  isCustom: boolean;
}) => {
  const [selected, setSelected] = useState(
    initial !== undefined && !isCustom
      ? items.findIndex((i) => i.value === initial) ?? 0
      : -1,
  );

  useEffect(() => {
    setSelected(
      initial !== undefined && !isCustom
        ? items.findIndex((i) => i.value === initial) ?? 0
        : -1,
    );
  }, [initial, items, isCustom]);

  const onClick = useCallback(
    (index: number) => {
      setSelected(index);

      onChange?.(items[index]!);
    },
    [items, onChange],
  );

  return (
    <div className="border border-primary bg-secondary rounded-xl p-1">
      <div className="flex gap-2 items-center z-0 relative">
        {items.map((item, index) => (
          <button
            key={item.value as any}
            className="flex flex-col py-3 items-center justify-center flex-1 z-20 outline-none focus:outline-none h-full"
            onClick={() => onClick(index)}
          >
            <span
              className={clsx('text-subtext font-semibold transition-colors', {
                'text-primary': selected === index,
                'text-tertiary': selected !== index,
              })}
            >
              {item.label}
            </span>
            {item.caption && (
              <span
                className={clsx('text-caption transition-colors', {
                  'text-secondary': selected === index,
                  'text-tertiary': selected !== index,
                })}
              >
                {item.caption}
              </span>
            )}
          </button>
        ))}
        {selected > -1 && (
          <div
            className="absolute bg-primary h-full z-10 rounded-lg transition-transform"
            style={{
              width: `calc(${(1 / items.length) * 100}% - 0.25rem)`,
              transform: `translateX(calc(${100 * selected}% + ${0.375 * selected}rem))`,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SwapPage;
