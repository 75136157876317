import { CollectionResourceId, MintAddressResourceId } from '@/appRoute.ts';
import { ViewportFlex } from '@/components/ViewportFlex.tsx';
import { useCollectibleDetails } from '@/hooks/nfts/useCollectibleDetails.ts';
import {
  BuySection,
  CollectibleImage,
  Header,
  TraitsSection,
} from '@/pages/CollectibleDetailsPage/ui';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const CollectibleDetailsPage = () => {
  const { mintAddress, collectionId } =
    useParams<Partial<MintAddressResourceId & CollectionResourceId>>();
  const { collectible, collection, isCollectibleLoading } =
    useCollectibleDetails({
      mintAddress,
      collectionId,
    });
  const navigate = useNavigate();

  useEffect(() => {
    if (!isCollectibleLoading && !collectible) {
      navigate(-1); // go back
    }
  }, [isCollectibleLoading, collectible, navigate]);

  return (
    <ViewportFlex>
      <div className="w-full pb-3">
        <section className="px-3 py-2">
          <Header
            nft={collectible}
            collection={collection}
            isLoading={isCollectibleLoading}
          />
        </section>
        <section className="px-3">
          <CollectibleImage
            nft={collectible}
            isLoading={isCollectibleLoading}
          />
        </section>
        <section className="px-3 mt-3">
          <BuySection nft={collectible} isLoading={isCollectibleLoading} />
        </section>
        <section className="px-3 mt-3">
          <TraitsSection nft={collectible} isLoading={isCollectibleLoading} />
        </section>
      </div>
    </ViewportFlex>
  );
};

export default CollectibleDetailsPage;
