import { Nft } from '@/hooks/nfts/types.ts';
import {
  mapDialectCollectibleToNft,
  mapSimplehashNftToNft,
} from '@/hooks/nfts/utils.ts';
import useTelegramWebAppInitData from '@/hooks/telegram-web-app/useTelegramWebAppInitData.ts';
import miniAppApi from '@/shared/api/dialect/mini-app-api.ts';
import simpleHashApi from '@/shared/api/simplehash/api.ts';
import useSWR from 'swr';

export const useTrendingCollectibles = () => {
  const [, initData] = useTelegramWebAppInitData();
  const { data, isLoading } = useSWR(
    ['COMMERCE_TRENDING_COLLECTIBLES'],
    async () => {
      if (!initData) {
        return [];
      }

      const response = await miniAppApi.nfts.getTrendingNfts(initData);

      const dialectNfts = response.nfts.map(mapDialectCollectibleToNft);
      const addresses = dialectNfts.map((nft) => nft.address) ?? [];

      const { nfts } = await simpleHashApi.getNftsByTokenList(
        addresses.map((address) => `solana.${address}`),
      );
      const simplehashNfts = Object.fromEntries(
        addresses.map((address): [string, Nft | undefined] => {
          const nft = nfts.find((n) => n.contract_address === address);

          if (!nft) {
            return [address, undefined];
          }

          return [address, mapSimplehashNftToNft(nft)];
        }),
      );

      return dialectNfts.map((nft): Nft => {
        const staticNft = simplehashNfts?.[nft.address];

        if (!staticNft) {
          return nft;
        }

        return {
          ...staticNft,
          prices: nft.prices ?? [], // interested in actionable listing
          metadata: {
            ...staticNft.metadata,
            attributes:
              nft.metadata.attributes ?? staticNft.metadata.attributes,
            rarityRank: nft.metadata.rarityRank,
            collectionTensorId: nft.metadata.collectionTensorId,
            collectionTotalSupply: nft.metadata.collectionTotalSupply,
          },
        };
      });
    },
    {
      refreshInterval: 10000,
    },
  );

  return {
    collectibles: data ?? [],
    isCollectiblesLoading: isLoading,
  };
};
