import {
  BirdeyePriceHistoryType,
  BirdeyeTokenOverview,
  BirdeyeTokenPrice,
  GetBirdeyeTokenOverviewResponse,
  GetBirdeyeTokenPriceHistoryResponse,
  GetBirdeyeTokenPriceResponse,
} from './types.ts';

const birdeyeBaseUrl = 'https://public-api.birdeye.so/defi';

const createBirdeyeApi = (apiKey: string) => {
  const getTokenMarketData = async (
    mintAddress: string,
  ): Promise<BirdeyeTokenOverview> => {
    const response = await fetch(
      `${birdeyeBaseUrl}/token_overview?address=${mintAddress}`,
      {
        headers: {
          accept: 'application/json',
          'X-API-KEY': apiKey,
        },
      },
    );

    if (!response.ok) {
      throw new Error('[birdeye] Failed to fetch token market data');
    }

    return ((await response.json()) as GetBirdeyeTokenOverviewResponse).data;
  };

  const getTokenPrice = async (
    mintAddress: string,
  ): Promise<BirdeyeTokenPrice> => {
    const response = await fetch(
      `${birdeyeBaseUrl}/price?address=${mintAddress}`,
      {
        headers: {
          accept: 'application/json',
          'X-API-KEY': apiKey,
        },
      },
    );

    if (!response.ok) {
      throw new Error('[birdeye] Failed to fetch token price');
    }

    return ((await response.json()) as GetBirdeyeTokenPriceResponse).data;
  };

  const getTokenPriceHistory = async (
    mintAddress: string,
    options: {
      timeFrom: number;
      timeTo: number;
      type: BirdeyePriceHistoryType;
    },
  ): Promise<GetBirdeyeTokenPriceHistoryResponse> => {
    const params = new URLSearchParams({
      address: mintAddress,
      address_type: 'token',
      time_from: options.timeFrom.toString(),
      time_to: options.timeTo.toString(),
      type: options.type,
    });

    const response = await fetch(
      `${birdeyeBaseUrl}/history_price?${params.toString()}`,
      {
        headers: {
          accept: 'application/json',
          'X-API-KEY': apiKey,
        },
      },
    );

    if (!response.ok) {
      throw new Error('[birdeye] Failed to fetch token price history');
    }

    return await response.json();
  };

  return { getTokenMarketData, getTokenPrice, getTokenPriceHistory };
};

export default createBirdeyeApi(import.meta.env.VITE_BIRDEYE_API_KEY as string);
