import jupiterApi from '@/shared/api/jupiter-api.ts';
import useSWR from 'swr';

export interface TokenMetadata {
  mintAddress: string;
  decimals: number;
  name?: string;
  symbol: string;
  imageUrl?: string;
  showPriority: number;
  balance?: number;
  infoLinks: {
    solscan: string;
    birdeye: string;
  };
  isUnknown: boolean;
}

interface UseTokenMetadataCatalogArgs {
  strict?: boolean;
}

interface UseTokenMetadataCatalogValue {
  tokenMetadata: Record<string, TokenMetadata>;
  isTokensLoading: boolean;
}

const tokenPriorityMap: Record<string, number> = {
  So11111111111111111111111111111111111111112: 100, //SOL
  EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v: 50, //USDC

  //TODO do we need this ordering or better rely on what jupiter returns?
  DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263: 10, //Bonk
  Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB: 5, //USDT
  '7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs': 4, //ETH
};

const SOL_SYMBOL = 'SOL';

const useTokenMetadataCatalog = ({
  strict = false,
}: UseTokenMetadataCatalogArgs = {}): UseTokenMetadataCatalogValue => {
  const { data, isLoading } = useSWR(['GET_TOKEN_LIST', strict], async () => {
    const tokenList = strict
      ? await jupiterApi.getStrictList()
      : await jupiterApi.getTokenList();

    return Object.fromEntries(
      tokenList
        .map((token) => {
          const isUnknown = token.tags.includes('unknown');
          return {
            name: token.symbol === SOL_SYMBOL ? 'Solana' : token.name,
            symbol: token.symbol ?? 'UNKNOWN',
            decimals: token.decimals,
            imageUrl: token.logoURI,
            mintAddress: token.address,
            showPriority:
              tokenPriorityMap[token.address] ?? (isUnknown ? -1 : 0),
            infoLinks: {
              solscan: `https://solscan.io/token/${token.address}`,
              birdeye: `https://birdeye.so/token/${token.address}?chain=solana`,
            },
            isUnknown: isUnknown,
          };
        })
        .map((token) => [token.mintAddress, token]),
    );
  });

  return {
    tokenMetadata: data ?? {},
    isTokensLoading: isLoading,
  };
};

export const SolanaTokenMetadata: TokenMetadata = {
  name: 'Solana',
  symbol: SOL_SYMBOL,
  decimals: 9,
  imageUrl: '/Solana.png',
  mintAddress: 'So11111111111111111111111111111111111111112',
  showPriority: 100,
  isUnknown: false,
  infoLinks: {
    solscan:
      'https://solscan.io/token/So11111111111111111111111111111111111111112',
    birdeye:
      'https://birdeye.so/token/So11111111111111111111111111111111111111112?chain=solana',
  },
};

export default useTokenMetadataCatalog;
